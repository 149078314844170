
function byteFormatFilter(value) {
	if (typeof value !== 'number' || !isFinite(value)) {
		return '';
	}
	var bytes = Math.abs(value);
	// TODO: support option for KiB, MiB, and GiB byte formats
	if (bytes >= 1000000000000) {
		return +(bytes / 1000 / 1000 / 1000 / 1000).toFixed(2) + ' TB';
	}

	if (bytes >= 1000000000) {
		return +(bytes / 1000 / 1000 / 1000).toFixed(2) + ' GB';
	}

	if (bytes >= 1000000) {
		return +(bytes / 1000 / 1000).toFixed(2) + ' MB';
	}

	if (bytes >= 1000) {
		return +(bytes / 1000).toFixed(2) + ' kB';
	}

	return Math.floor(bytes) + ' b';
}

var shortDateTimeFormat = new Intl.DateTimeFormat(navigator.language, {
	timeStyle: 'short',
	dateStyle: 'short'
});
var shortTimeFormat = new Intl.DateTimeFormat(navigator.language, {
	timeStyle: 'short'
});
var shortDateFormat = new Intl.DateTimeFormat(navigator.language, {
	dateStyle: 'short'
});

function dateFormatFilter(value, format) {
	if (value == null) {
		return '';
	}
	if (typeof format !== 'string') {
		format = 'date';
	}

	// require date object
	var date = (typeof value === 'string' && isFinite(Date.parse(value))) ? new Date(value) :
		(value instanceof Date) ? value : null;

	if (date == null && typeof value === 'string') {
		// Non-date value are passed through
		return value;
	}

	if (format === '8601') {
		return date.toISOString();
	}

	if (format === 'time') {
		return shortTimeFormat.format(date);
	}

	if (format === 'datetime') {
		return shortDateTimeFormat.format(date);
	}

	// default is "date" format
	return shortDateFormat.format(date);
}

function percentFilter(value) {
	if (typeof value !== 'number' || !isFinite(value)) {
		return '';
	}
	return (value * 100).toFixed(0) + '%';
}


export {
	byteFormatFilter,
	percentFilter,
	dateFormatFilter,
};

