import { createApp } from 'vue';
import App from './App.vue';
import * as Sentry from "@sentry/vue";
import router from './router';
import { markRaw } from 'vue';
import { default as ENV_VARS, override as setEnvVars} from '@/modules/env-vars.js';
import { setRefreshUrl } from '@/modules/token-refresher';
import gtm from '@/modules/google-tag-manager';
import { useRootStore } from '@/store/rootStore';
import { useAuthStore } from '@/store/auth';

// Vue-easy-dnd requires css import in Vue 3
import 'vue-easy-dnd/dist/dnd.css';
import { confirmUnload } from './directives/confirm-unload';
import { autofocus } from './directives/autofocus';
import { autoheight } from './directives/autoheight';
import { renameInput } from './directives/rename-input';



import { createPinia } from 'pinia';

import { version } from '../version.json';

let newHash = window.location.pathname + window.location.search;
if (newHash && newHash !== '/' && newHash !== '#/') {
	if (window.history.replaceState) {
		window.history.replaceState({}, '', window.location.toString().replace(newHash, ''));
	}
	window.location.hash = newHash;
}

// Auth'd App Process
// Determine if auth is require for route
//   no -> continue route
//   yes -> verify (active; in memory) refresh token
//     valid -> continue route
//     none -> resolve refresh token
//       URL defined -> activate, verify, and continue route
//       localStorage -> activate, verify, and continue route
//       none -> redirect to auth app with return destination
//     invalid -> redirect to auth app with return destination
//
//  Alt: apply URL token to localStorage on initialization, restore session only from localStorage


function getEnvOverrides() {
	var host = location.hostname;
	var isDev = (host.indexOf('dev-') >= 0 || location.port >= 4000);
	if (isDev) {
		return {
			ENVIRONMENT: 'development',
			BRAND: 'tektronix',
			DWC_CLIENT_ID: '18ef7cccf5db38e30002500306828a5d6058f27892e1f918',
			WEBSOCKET_URL: 'wss://sentinel.dev-api.tekcloud.com',
			GTM_KEY: 'GTM-W3G3H78',
			THEME_URL: 'https://theme.tekcloud.com/dev/',
			AUTH_API: 'https://dev-api-init.tekcloud.com/dev-auth/',
			AUTH_URL: 'https://dev-auth.tekcloud.com/#/',
			ACCOUNT_API: 'https://dev-api-init.tekcloud.com/dev-account/',
			ACCOUNT_URL: 'https://dev-account.tekcloud.com',
			DRIVE_API: 'https://drive.dev-api.tekcloud.com/',
			WAVES_APP_URL: 'https://dev-waves.tekcloud.com/',
			LINES_APP_URL: 'https://dev-lines.tekcloud.com/'
		};
	}
	return {};
}

function initVue() {
	const app = createApp(App);
	const pinia = createPinia();
	pinia.use(({store}) => {
		store.router = markRaw(router);
	});
	app.use(pinia);
	app.use(router);
	app.directive('confirm-unload', confirmUnload);
	app.directive('autofocus', autofocus);
	app.directive('autoheight', autoheight);
	app.directive('rename-input', renameInput);
	return app;
}

function initGoogleTagManager() {
	if (ENV_VARS.GTM_KEY) {
		gtm.addGoogleTagManagerWithConsent(ENV_VARS.GTM_KEY);
	}
}

function initSentry(app) {
	const env = ENV_VARS.ENVIRONMENT === 'production' ? 'prod' : (location.hostname.indexOf('dev-') >= 0) ? 'dev' : 'local';

	Sentry.init({
		app,
		dsn: ENV_VARS.SENTRY_DNS,
		environment: env,
		release: `drive-web-client@${version}`,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		integrations: [new Sentry.Replay()],
		beforeSend: function (event, hint) {
			const error = hint.originalException;
			if (event.environment === 'local') {
				return null;
			}
			if (ignoreError(error)) {
				return null;
			}
			return event;
		}
	});

	function ignoreError(error) {
		if (error == null) {
			return false;
		}
		const message = (error.message || error).toLowerCase();
		// filter our auth related errors
		return (/unauthorized|forbidden|authorized|invalid|expired|failed/i).test(message);
	}
}

function getRefreshToken() {
	var refreshToken = localStorage.getItem('refreshToken');
	if (!refreshToken) {
		//Vue Router runs after main.js, so we need to check query params on init
		const queryParam = new URLSearchParams(location.hash.split('#/signin')[1]).get('token') || null;
		// Leaving unauthed entry in place for public routes
		return queryParam;
	}
	return refreshToken;
}

function resumeSession(app) {
	const refreshToken = getRefreshToken();
	if (!refreshToken) {
		//No refresh token, All good... Pass on without auth
		//Keeping this flow for potential public routes
		return Promise.resolve();
	}
	const authStore = useAuthStore();
	// restoring session...
	return authStore.SET_REFRESH_TOKEN(refreshToken)
		.then(() => {
			const store = useRootStore();
			const userInfo = store.UPDATE_USER_INFO();
			// kick off file watch service
			//note: I removed context from params in store declaration when migrating to pinia
			store.START_FILE_WATCHER()
				.catch(reason => {
					console.error(reason);
				});
			//return to to allow userInfo to be set in cache before mounting app
			return userInfo;
		})

		.catch((reason) => {
			// ...session invalid
			// Clear the invalid token from localStorage
			localStorage.removeItem('refreshToken');
			// This isn't an app failure; the token is just invalid. Resolve and resume initialization.
			return Promise.reject(reason);
		});
}

function initApp() {
	const app = initVue();

	setEnvVars(getEnvOverrides());
	setRefreshUrl((ENV_VARS.AUTH_API || '').replace(/\/$/, '') + '/tokens');

	initGoogleTagManager();

	initSentry(app);

	return resumeSession(app)
		.then(() => {
			app.mount('#app');
			return;
		})
		.catch((reason) => {
			// The app failed to initialize
			document.documentElement.classList.add('app-fatal-error');
			throw new Error(reason?.message || reason);
		});
}

// Launch app
initApp();

document.documentElement.classList.remove('navigating', 'initializing');
