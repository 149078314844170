import DriveFile from '@/modules/models/drive-file';
import DriveFolder from '@/modules/models/drive-folder';

function parseDate(value) {
	if (value == null) {
		return null;
	}
	// avoid invalid date objects
	var date = new Date(value);
	return isFinite(date) ? date : null;
}

function getFileTypeFromName(name) {
	if (typeof name !== 'string') {
		return '';
	}
	const idx = name.lastIndexOf('.');
	if (idx <= 0 && idx < name.length - 2) {
		// ignore names without periods or with leading/trailing periods
		return '';
	}
	// drive api uses uppercase types
	const ext = name.slice(idx + 1).toUpperCase();
	// TODO: map/normalize extension types
	return ext;
}

function hasFileTypeViewer(fileType) {
	return fileType === 'JPG' || fileType === 'PNG' || fileType === 'PDF' || fileType === 'CSV' || (fileType === 'TSS');
}

function driveFileFromDto(dto) {
	if (dto == null) {
		return null;
	}

	var item = new DriveFile();
	item.fileType = dto.fileType || getFileTypeFromName(dto.name) || '';
	item.bytes = +dto.bytes || 0;
	item.uploadState = dto.uploadState || 'PENDING';

	item.hasFileTypeViewer = hasFileTypeViewer(item.fileType);

	return item;
}

function driveFolderFromDto(dto) {
	if (dto == null) {
		return null;
	}

	var item = new DriveFolder();
	item.folderType = dto.folderType || 'STANDARD';

	if (Array.isArray(dto.children)) {
		// TODO: prevent recursion
		item.children = dto.children.map(driveItemFromDto);
	}

	return item;
}

function driveItemFromDto(dto) {
	if (dto == null) {
		return null;
	}
	var item =	(dto.type === 'FOLDER') ? driveFolderFromDto(dto) :
		(dto.type === 'FILE') ? driveFileFromDto(dto) : null;
	if (item == null) {
		return null;
	}

	item.hierarchy = dto.hierarchy;

	item.id = dto.id || '';
	item.name = dto.name || '';
	//item.type = dto.type; // already defined in model
	item.parentFolderId = dto.parentFolderId || '';
	item.owner = dto.owner || {
		id: '',
		username: ''
	};
	item.creator = dto.creator || item.owner;
	item.permissions = dto.permissions || {
		owner: false,
		creator: false,
		read: false,
		edit: false,
		public: false
	};
	item.createdAt = parseDate(dto.createdAt);
	item.updatedAt = parseDate(dto.updatedAt);
	item.trashedAt = parseDate(dto.trashedAt);
	item.sharedAt = parseDate(dto.sharedAt);
	item.members = dto.members || null;
	item.thumbnails = dto.thumbnails || {};
	;	return item;
}

export {
	driveItemFromDto
};
