<style scoped>
	.breadcrumbs {
		flex: 1 1 auto;
		text-overflow: ellipsis;
		padding: 2px 12px;
		font-size: 0.9em;
	}
	.breadcrumbs .breadcrumb:first-child > .slash {
		display: none;
	}
	.breadcrumbs > span > a,
	.breadcrumbs .breadcrumb a,
	.breadcrumb i {
		color: #777;
		color: var(--breadcrumbs-color);
	}
	.breadcrumbs > .row > a {
		display: inline-block;
		flex-shrink: 1;
	}
	.slash {
		padding: 0 .5em;
		color: #bbb;
	}
	.breadcrumb {
		flex: 0 1 auto;
		min-width: 0;
	}
	.dnd-drop.drop-allowed.breadcrumb-menu-drop {
		outline: none;
	}
	.breadcrumbs > span:first-child,
	.breadcrumb-menu-drop {
		flex-shrink: 0;
	}

	.invisible {
		visibility: hidden;
	}
	.menu-list span:after {
		content: '';
	}
	.breadcrumb-menu .link{
		padding-right: .4em;
	}
	.menu-list {
		font-size: 1rem;
	}
	.trailing-off {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.menu-wrapper.breadcrumb-menu {
		font-size: 1em;
		margin: 0;
	}
	.menu-wrapper.breadcrumb-menu > .menu-button {
		padding: 0;
	}

	.menu-list {
		list-style: none;
		padding: 4px 0;
		margin: 0;
		cursor: default;
		user-select: none;
	}
	.menu-list li {
		margin: 0;
		padding: 0;
	}
	.menu-action,
	.menu-parent {
		padding: 0.3em 0.4em;
		display: flex;
		flex-direction: row;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.menu-parent::after {
		content: '\25B6';
		display: inline-block;
		flex: 0 0 auto;
	}
	.menu-icon {
		flex: 0 0 auto;
		display: inline-block;
		color: #999;
	}
	.menu-label {
		transition: none;
		flex: 1 1 auto;
	}
	.menu-separator {
		opacity: 0.3;
		opacity: var(--menu-separator-opacity);
	}
	.menu-submenu {
		background: #fafafa;
		border: 1px solid #bdbdbd;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
					0 3px 1px -2px rgba(0, 0, 0, 0.2),
					0 1px 5px 0 rgba(0, 0, 0, 0.12);
		margin: 0;
		padding: 4px 0;
		width: 250px;
		z-index: 99999;

		display: none;
		position: absolute;
		top: 0;

		right: -100%;
	}
	.menu-right .menu-submenu {
		right: auto;
		left: -100%;
	}
	.menu-list.left {
		right: auto;
		left: -100%;
	}
	.menu-group {
		position: relative;
	}
	.menu-group:hover > .menu-submenu {
		display: block;
	}
	.menu-action:hover,
	.menu-group:hover .menu-parent {
		background: #1e88e5;
		color: #fafafa;
	}
	.menu-placeholder {
		opacity: 0.5;
		padding: 0.5em 0;
	}
	.menu-action {
		cursor: pointer;
		width: 100%;
		color: #555;
	}
	.menu-option > .menu-icon {
		visibility: hidden;
	}
	.menu-option.active > .menu-icon {
		visibility: visible;
	}

	.show-500 {
		display: none;
	}

	.hide-500 {
		display: flex;
	}

	@media only screen and (max-width : 800px) {
		.breadcrumb {
			min-width: 48px;
		}
	}

	@media only screen and (max-width : 500px) {
		.show-500 {
			display: flex;
		}
		.hide-500 {
			display: none;
		}
	}



</style>

<template>
	<div class="breadcrumbs flexible row" :class="{'invisible':loading}">
		<!-- top level folder -->
		<drop v-if="trancateBreadcrumbs"
			tag="span"
			class="row breadcrumb hide-500"
			:key="baseFolder.id"
			@drop="moveItems(selection, baseFolder.id)"
			mode="cut"
			accepts-type="ditems"
			:accepts-data="(selection) => canAcceptDrop(baseFolder.id, selection)">
			<router-link :title="baseFolder.name" :to="'/d/' + encodeURIComponent(baseFolder.id)"> {{baseFolder.name}}</router-link>
		</drop>
		<!-- truncated menu -->
		<drop tag="span" class="breadcrumb-menu-drop hide-500" @dragenter="onDragEnter" ref="menuDropElement" v-if="truncatedFolders.length">
			<span class="slash">/</span>
			<DropdownMenu :icon-class="'fa-ellipsis-h'" :force-show-menu="showMenu" :button-class="'no-padding'" class="rigid breadcrumb-menu auto-width">
				<drop tag="ul" class="menu-list">
					<drop
						tag="li"
						class="row"
						v-for="parentFolder in truncatedFolders"
						:key="parentFolder.id"
						@drop="moveItems(selection, parentFolder.id)"
						mode="cut"
						:title="parentFolder.name" :to="'/d/' + encodeURIComponent(parentFolder.id)"
						accepts-type="ditems"
						:accepts-data="(selection) => canAcceptDrop(parentFolder.id, selection)">
						<router-link class="menu-action row separated" :title="parentFolder.name" :to="'/d/' + encodeURIComponent(parentFolder.id)">
							<span class="menu-icon rigid"><i class="far fa-folder"></i></span>
							<span class="flexible menu-label trailing-off">{{parentFolder.name}}</span>
						</router-link>
						<!-- <router-link :title="parentFolder.name" class="rigid trailing-off" :to="'/d/' + encodeURIComponent(parentFolder.id)"> {{parentFolder.name}}</router-link> -->
					</drop>
				</drop>
			</DropdownMenu>
		</drop>
		<!-- additonal breadcrumbs -->
		<span class="breadcrumb row hide-500" v-for="parentFolder in visibleFolders" :key="parentFolder.id">
			<span class="slash rigid" v-if="visibleFolders.length">/</span>
			<drop tag="span"
				class="flexible trailing-off"
				@drop="moveItems(selection, parentFolder.id)"
				mode="cut"
				accepts-type="ditems"
				:accepts-data="(selection) => canAcceptDrop(parentFolder.id, selection)">
				<router-link :title="parentFolder.name" :to="'/d/' + encodeURIComponent(parentFolder.id)"> {{parentFolder.name}}</router-link>
			</drop>
		</span>
		<!-- show on mobile or screens 500 wide -->
		<span class="row breadcrumb centering show-500">
			<drop
				v-if="parentFolder"
				tag="span"
				class="flexible trailing-off"
				:key="parentFolder.id"
				@drop="moveItems(selection, parentFolder.id)"
				mode="cut"
				accepts-type="ditems"
				:accepts-data="(selection) => canAcceptDrop(parentFolder.id, selection)">
				<i class="rigid fas fa-long-arrow-alt-up"></i>
				<router-link class="rigid" :title="parentFolder.name" :to="'/d/' + encodeURIComponent(parentFolder.id)"> {{parentFolder.name}}</router-link>
			</drop>
		</span>

	</div>
</template>

<script>
import { Drop } from "vue-easy-dnd";
import DropdownMenu from '@/components/DropdownMenu.vue';
import { useRootStore } from '../store/rootStore';
import { mapActions, mapState } from 'pinia';

export default {
	name: 'bread-crumbs',

	components: {
		Drop,
		DropdownMenu
	},

	props: {
		item: Object,
		selection: Array
	},

	data () {
		return {
			loading: false,
			MAX_ITEMS: 4,
			TRUNCATE_MIN: 2,
			showMenu: false,
			hideTimer: null,
		};
	},

	computed: {
		...mapState(useRootStore, {
			folders: 'folders'
		}),
		parentFolders() {
			return this.item.hierarchy || [];
		},
		parentFolderId() {
			return this.item.parentFolderId;
		},
		trancateBreadcrumbs() {
			return this.parentFolders.length > (this.MAX_ITEMS);
		},
		baseFolder() {
			return this.parentFolders[0];
		},
		truncatedFolders() {
			return this.trancateBreadcrumbs ? this.parentFolders.slice(1, this.parentFolders.length - this.TRUNCATE_MIN) : [];
		},
		visibleFolders(){
			const foldersLength = this.parentFolders.length;
			const sliceIndex = this.trancateBreadcrumbs && (foldersLength - this.TRUNCATE_MIN);
			return sliceIndex ? this.parentFolders.slice(sliceIndex) : this.parentFolders;
		},
		parentFolder() {
			return this.parentFolders[this.parentFolders.length - 1];
		}
	},

	methods: {
		...mapActions(useRootStore, {
			MOVE_ITEMS: 'MOVE_ITEMS',
			GET_TREE_PATH: 'GET_TREE_PATH'

		}),
		onMouseLeave(event){
			if (this.showMenu) {
				this.showMenu = false;
			}
			this.$refs.menuDropElement.$el.removeEventListener('mouseleave', this.onMouseLeave);
		},

		onDragEnter(event) {
			this.showMenu = true;
			this.$refs.menuDropElement.$el.addEventListener('mouseleave', this.onMouseLeave);
		},

		canAcceptDrop (item, selection) {
			return (typeof item === 'string'
				|| item != null && item.type === 'FOLDER')
				&& Array.isArray(selection)
				&& selection.length > 0
				&& selection.every((selItem) => selItem != null && selItem !== item);
		},

		moveItems (items, folderId) {
			if (!Array.isArray(items)) {
				throw new Error('Expected Directory Items');
			}
			if (typeof folderId !== 'string') {
				throw new Error('Expected Folder ID');
			}

			this.MOVE_ITEMS({ items:items, parentFolderId:folderId });

			this.showMenu = false;

			this.$emit('move');
		}

	},
	watch:{
		parentFolderId: {
			immediate: true,
			handler(newVal, oldVal) {
				if (!newVal) {
					return;
				}
				this.loading = !this.item.hierarchy;

				this.GET_TREE_PATH(this.item)
					.then(()=>{
						this.loading = false;
					});
			}
		}
	}
};
</script>
