import { ws } from '@/modules/socket-connect';
const actionTable = Object.create(null);

function registerResponse(nonce) {
	let promiseValue,
		promiseState = 0;
	actionTable[nonce] = {
		resolve: (value) => {
			if (promiseState === 0) {
				promiseState += 1;
				promiseValue = value;
			}
		},
		reject: (value) => {
			if (promiseState === 0) {
				promiseState -= 1;
				promiseValue = value;
			}
		}
	};
	const promise = new Promise((res, rej) => {
		if (promiseState > 0) {
			res(promiseValue);
		}
		if (promiseState < 0) {
			rej(promiseValue);
		}
		actionTable[nonce].resolve = res;
		actionTable[nonce].reject = rej;
	});
	return promise;
}

function deregisterResponse(nonce) {
	if (actionTable[nonce] && typeof actionTable[nonce].reject === 'function') {
		actionTable[nonce].reject(new Error('Cancelled'));
	}
	delete actionTable[nonce];
}

export default {

	tryHandleResponse(response) {
		const nonce = response && response.nonce,
			topicId = response && response.data && response.data.topicId,
			actionId = nonce || topicId;
        
		if (actionId && actionTable[actionId]) {
			// create or delete a topic
			if (typeof actionTable[actionId].resolve === 'function') {
				actionTable[actionId].resolve(response.data);
				delete actionTable[actionId];
				return null;
			}
		} 
		return new Error('RESPONSE id not found');
	},

	send(action, responseId) {
		ws.send(JSON.stringify(action));
    
		// register a response for deletion
		return registerResponse(responseId)
			.then((value) => {
				deregisterResponse(responseId);
				return value;
			}, (reason) => {
				deregisterResponse(responseId);
				throw reason;
			});
	}

};
