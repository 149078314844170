function createModalMask(element, fn) {
	function onPress(event) {
		// console.log('eventtarget', event.target);
		// console.log('element', element);
		if (event.target === element) {
			fn();
		}
	}
	element.addEventListener('click', onPress, true);
}

function removeModalMask(element, fn) {
	element.removeEventListener('click', fn, true);
}

export { createModalMask, removeModalMask };
