function onPress(event, el, fn) {
	const isOutside = el !== event.target && el && event.target && !el.contains(event.target);
	if (isOutside) {
		//close popUpMenu
		fn(false);
	}
}

function createPopUpListener(el, fn) {
	window.addEventListener('touchstart', (event) => onPress(event, el, fn), { passive:true });
	window.addEventListener('mousedown', (event) => onPress(event, el, fn), false);
}

function removePopUpListener(el, fn) {
	window.removeEventListener('touchstart', (event) => onPress(event, el, fn), { passive:true });
	window.removeEventListener('mousedown', (event) => onPress(event, el, fn), { passive:true });
}


export { onPress, createPopUpListener, removePopUpListener };
