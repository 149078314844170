<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.empty-state {
	margin: 3em 0;
	text-align: center;
	color: #bbb;
	color: var(--empty-state-color);
	padding: 2em;
}
.empty-state-header {
	font-size: 2em;
}

.content-header {
	flex: 0 0 auto;
}
.content-toolbar {
	min-height: 40px;
	flex: 0 0 auto;
	padding: 0 20px;
	font-size: 0.9em;
	margin-bottom: 4px;
	display: flex;
	justify-content: space-between;
}


.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
}

.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
	opacity: var(--menu-separator-opacity);
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	background: var(--menu-popover-background-color);
	border: 1px solid var(--menu-popover-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}

@media only screen and (max-width : 500px) {
	.hide-500 {
		display: none;
	}
	.empty-state-header {
		font-size: 1.5em;
	}
}

</style>

<template>
	<div class="drive-personal-view">


		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs"></div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />

				<div class="location-title">Shared <span class="hide-500">With Me</span></div>

				<SearchBar class="location-search" folder-id="SHARES" />

			</div>
		</header>
		<div class="content-section">
			<div class="content-toolbar">
				<span>
					<button v-if="canDownload" class="toolbar-button" type="button" @click="downloadFiles(selection)"><img src="@/assets/icon-download-file.svg" class="icon-download-file" /> Download</button>
					<button v-if="selection.length === 1 && canEditItems(selection)" class="toolbar-button" type="button" @click="startItemRename(selection[0])"><img src="@/assets/icon-rename.svg" class="icon-rename" /> Rename</button>
					<button v-if="selection.length === 1" class="toolbar-button" type="button" @click="editItemMembers(selection[0])"><img src="@/assets/icon-share.svg" class="icon-share" /> Share</button>
					<button v-if="selection.length > 0" key="toolbarRemoveShare" class="toolbar-button" type="button" @click="removeMember(selection)"><img src="@/assets/icon-delete-file.svg" class="icon-delete-file" /> Remove Share</button>
				</span>

				<div></div>


				<div>
					<ToggleViewButton />
				</div>
			</div>
			<div class="content-view">
				<div v-if="!list || !list.length" class="empty-state">
					<p class="empty-state-header"><i class="fa fa-share-alt"></i> Nothing has been shared with you (yet)</p>
					<p class="empty-state-tip">
						When others share files and folders with you, they will appear here.
					</p>
				</div>
				<TileView v-else-if="childViewStyle === 'tile'" columns="owner,shared" :list="list" ref="listView" v-model:selection="selection" :allow-drag="false"/>
				<ListView v-else :list="list" columns="owner,shared" ref="listView" v-model:selection="selection" :allow-drag="false" />
			</div>
		</div>

		<context-menu ref="selectionContextMenu" v-slot="{context}">
			<ul class="menu-list" v-if="context && context.data && context.data.length > 0">
				<li v-if="context.data.length === 1">
					<div class="menu-action" @click="openItem(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Open</span>
					</div>
				</li>
				<li class="menu-group" v-if="context.data.length === 1 && (canOpenInWaves(context.data[0]) || canOpenInLines(context.data[0]))">
					<div class="menu-submenu">
						<ul class="menu-list">
							<li v-if="canOpenInWaves(context.data[0])">
								<div class="menu-action" @click="launchInWaves(context.data[0])">
									<span class="menu-icon"></span>
									<span class="menu-label">Waves</span>
								</div>
							</li>
							<li v-if="canOpenInLines(context.data[0])">
								<div class="menu-action" @click="launchInLines(context.data[0])">
									<span class="menu-icon"></span>
									<span class="menu-label">Lines</span>
								</div>
							</li>
						</ul>
					</div>
					<div class="menu-parent">
						<span class="menu-icon"></span>
						<span class="menu-label">Open With&hellip;</span>
					</div>
				</li>
				<li v-if="context.data.length === 1">
					<hr class="menu-separator">
				</li>
				<li v-if="canDownloadItems(context.data)">
					<div class="menu-action" @click="downloadFiles(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Download</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canEdit(context.data[0])">
					<div class="menu-action" @click="startItemRename(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Rename</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canEdit(context.data[0])">
					<div class="menu-action" @click="editItemMembers(context.data[0])">
						<span class="menu-icon"></span>
						<span class="menu-label">Share</span>
					</div>
				</li>
				<li v-if="context.data.length === 1 && canEdit(context.data[0])">
					<hr class="menu-separator">
				</li>
				<li>
					<div class="menu-action" @click="removeMember(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Remove Share</span>
					</div>
				</li>
			</ul>
		</context-menu>


	</div>

</template>

<script>
import ContextMenu from '@/components/ContextMenu.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListView from '@/components/ListView.vue';
import TileView from '@/components/TileView.vue';
import EnvVars from '@/modules/env-vars';
import ToggleViewButton from '@/components/ToggleViewButton.vue';
import UserMenu from '@/components/UserMenu.vue';
import NavButton from '@/components/NavButton.vue';
import driveService from '@/modules/drive-service';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';
import { useAuthStore } from '../store/auth';

var IS_FILE_TYPES = ['CSV', 'WFM', 'TSS', 'ISF', 'VCD'];
function getFileType(file) {
	if (file == null) {
		return '';
	}
	if (file.fileType === 'ZIP' & /\.tss$/i.test(file.name)) {
		// Workaround for the pipeline identifying TSS files as zip (technically true, but it's a special type of zip)
		return 'TSS';
	}
	if (typeof file.fileType === 'string' && file.fileType !== '') {
		return file.fileType.toUpperCase();
	}
	var name = (typeof file.name === 'string') ? file.name : '';
	var idx = name.lastIndexOf('.');
	var ext = (idx < 0) ? '' : name.slice(idx + 1);
	return ext.toUpperCase();
}

function isInitialStateFile(file) {
	if (file == null) {
		return false;
	}
	// Only TSS, WFM, ISF, CSV, and VCD files
	const type = getFileType(file);
	return IS_FILE_TYPES.indexOf(type) >= 0;
}

export default {
	name: 'SharesView',

	components: {
		SearchBar,
		ListView,
		TileView,
		ToggleViewButton,
		UserMenu,
		NavButton,
		ContextMenu
	},

	data: () => {
		return {
			editContext: null,
			selection: []
		};
	},

	computed: {

		...mapState(useRootStore, {
			userId: (store) => store.userInfo.id,
			childViewStyle: 'childViewStyle',
			list: (store) => {

				const silos = store.silos;

				if (!silos) {
					console.error('Unable to find silos');
					return [];
				}

				const sharesSilo = silos.SHARES;

				if (!sharesSilo) {
					console.error('Unable to find shares silo');
					return [];
				}

				const sharesSiloID = sharesSilo.id;

				if (!sharesSiloID) {
					console.error('Unable to find shares silo ID');
					return [];
				}
				// TODO: Sort
				// TODO: Create separate search and directory views (with separate listing patterns/filters)
				const shares = store.list.filter((item) => {
					return item != null
						&& item.parentFolderId === sharesSiloID;
				});
				return shares;
			}

		}),

		...mapState(useAuthStore, {
			refreshToken: 'refreshToken'
		}),

		canDownload() {
			const selection = this.selection;
			return (selection.length === 1 && selection[0].type === 'FILE');
		},

	},

	methods: {
		...mapActions(useRootStore, {
			EDIT_SHARE_MEMBERS: 'EDIT_SHARE_MEMBERS',
			CONFIRM_REMOVE_SHARE: 'CONFIRM_REMOVE_SHARE',
			CONFIRM_LAUNCH_ITEM: 'CONFIRM_LAUNCH_ITEM',
			GET_FOLDER_NODE: 'GET_FOLDER_NODE'

		}),

		downloadFiles (items) {
			const files = (!Array.isArray(items) || !items.length) ? []:
				items.filter((item) => item.type === 'FILE');
			if (!files.length) {
				return;
			}

			const jobs = files.map((file)=>{
				return driveService.downloadFile(file.id);
			});

			// TODO: jobs.catch(...);

		},

		canEditItems(items) {
			return Array.isArray(items) && items.every((item) => item.permissions.edit);
		},

		startItemRename: function (item) {
			return this.$refs.listView.startItemRename(item);
		},

		confirmItemRename: function (editContext, name) {
			if (editContext && typeof editContext.commit === 'function') {
				editContext.commit(name);
			}
		},

		cancelItemRename: function (editContext) {
			if (editContext && typeof editContext.cancel === 'function') {
				editContext.cancel();
			}
		},

		editItemMembers (item) {
			this.EDIT_SHARE_MEMBERS(item);
		},

		removeMember (item) {
			this.CONFIRM_REMOVE_SHARE(item)
				.then(() => {
					this.itemSelection([]);
					this.editContext = null;
				})
				.catch((reason) => {
					console.log('Share Removal Cancelled');
				});
		},

		onContextMenu(event) {
			if (!event) {
				return;
			}
			this.$refs.selectionContextMenu.open(event, {
				data: this.selection,
				close: () => {
					this.$refs.selectionContextMenu.close();
				}
			});
		},
		canDownloadItems(items) {
			return Array.isArray(items)
				&& items.some((item) => item != null && item.type === 'FILE');
		},

		canEdit(item) {
			if (!item || !item.id) {
				return false;
			}
			return item.permissions.edit;
		},

		canOpenInWaves(item) {
			if (!item || !item.id || item.type !== 'FILE') {
				return false;
			}
			return isInitialStateFile(item);
		},

		canOpenInLines(item) {
			if (!item || !item.id || item.type !== 'FILE') {
				return false;
			}
			return isInitialStateFile(item);
		},

		launchInWaves(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.WAVES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			// Confirm before launching (if small enough, confirm is skipped)
			this.CONFIRM_LAUNCH_ITEM(file)
				.then(() => {
					// Confirmed! Launching file...
					window.open(url);
					console.log('Launching File...', file);
				})
				.catch((reason) => {
					console.log('Launch Cancelled');
				});
		},

		launchInLines(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.LINES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			// Confirm before launching (if small enough, confirm is skipped)
			this.CONFIRM_LAUNCH_ITEM(file)
				.then(() => {
					// Confirmed! Launching file...
					window.open(url);
					console.log('Launching File...', file);
				})
				.catch((reason) => {
					console.log('Launch Cancelled');
				});
		},


	},

	beforeRouteEnter (to, from, next) {
		const store = useRootStore();

		store.GET_FOLDER_NODE('SHARES')
			.then((meta) => {
				next();
			})
			.catch((err) => {
				next(err);
			});
	},

	beforeRouteUpdate (to, from, next) {
		this.loadError = null;
		this.folder = null;
		this.editContext = null;
		this.selection = [];

		this.GET_FOLDER_NODE('SHARES')
			.then((meta) => {
				next();
			})
			.catch((err) => {
				next(err);
			});
	}

};
</script>
