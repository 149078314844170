<style scoped>

.notice-bar.warning {
  background-color: #ffeda4;
  color: #75550B;
  background: #FFF9EB;
  border: 1px solid #FEEFCD;
  border-radius: 3px;
  background-color: var(--warning-background-color);
	border: 1px solid var(--warning-border-color);
	color: var(--warning-color);
}
.notice-bar.error {
  background: #ffeceb;
  border: 1px solid #fcd1cf;
  border-radius: 3px;
  color: #442800;
  background-color: var(--error-background-color);
	border: 1px solid var(--error-border-color);
	color: var(--error-color);
}

.notice-message {
  padding-top: 0.2em;
}

.notice-message p {
	padding-left: 1em;
}

.notice-bar .manage-plan {
  border-width: 0;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  padding: 0.5em 0.75em;
  min-width: 140px;
}

.notice-bar.warning .manage-plan {
  background-color: #fcfcfc;
  color: #75550B;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.notice-bar.error .manage-plan {
  background: #fcfcfc;
  color: #750E0B;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

/* .notice-bar.error .exit-banner {
	color: #750E0B;
} */

.notice-bar .clear-notice {
  border-radius: 50%;
  border-width: 0;
  background-color: transparent;
  margin-left: 0.5em;
  padding-right: 0;
  text-decoration: none;
  cursor: pointer;
}
/* .notice-bar.warning .clear-notice {
  color: #75550B;
} */
/* .notice-bar.error .clear-notice {
  color: #fff;
} */

.notice-bar.warning a:not(.button) {
  text-decoration: underline;
  color: var(--warning-link-color);
}
.notice-bar.error a:not(.button) {
  text-decoration: underline;
  color: var(--error-link-color);
}

.notice-bar .button {
  background: var(--notice-button-background-color);
  border-color: var(--notice-button-border-color);
}

.banner-content {
	width: 100%;
	padding: .25em 2em;
}

@media only screen and (max-width: 550px) {
  .notice-bar.error .manage-plan  {
    display: none;
  }

.notice-bar.warning .manage-plan {
	  display: none;
  }

}

</style>

<template>
	<div>
		<div
			class="notice-bar error row"
			v-if="noticeFlags.overStorageNotice && usagePercentage > 1"
		>
			<div class="row centering banner-content">
				<div class="flexible notice-message row centering">
					<i class="fa fa-exclamation-circle fa-lg"></i>
					<p>        You've exceeded your
						<a
							href="https://help.tekcloud.com/hc/en-us/articles/360049872352-Storage-Limits"
							target="_blank"
						>storage limit</a
						>. You should update your plan or remove files.</p>
				</div>

				<div class="rigid notice-actions">
					<a :href="subscriptionsUrl" target="_blank" class="button"
					>Manage Storage Limit</a
					>
					<button
						type="button"
						class="clear-notice"
						title="Dismiss Notice"
						@click="dismissStorageExceededNotice()"
					>
						<i class="fa fa-times"></i>
					</button>
				</div>
			</div>
		</div>

		<div
			class="notice-bar warning row"
			v-else-if="
				noticeFlags.overStorageWarning &&
					usagePercentage >= 0.8 &&
					usagePercentage <= 1"
		>
			<div class="row centering banner-content">
				<div class="flexible notice-message row centering">
					<i class="fa fa-exclamation-circle fa-lg"></i>
					<p>
						You're nearing your
						<a
							href="https://help.tekcloud.com/hc/en-us/articles/360049872352-Storage-Limits"
							target="_blank"
						>storage limit</a
						>. Update your subscription to increase this limit.
					</p>
				</div>

				<div class="rigid notice-actions">
					<a :href="subscriptionsUrl" target="_blank" class="button"
					>Manage Storage Limit</a
					>
					<button
						type="button"
						class="clear-notice"
						title="Dismiss Notice"
						@click="dismissStorageExceededWarning()"
					>
						<i class="fa fa-times"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useRootStore } from '@/store/rootStore';
import { mapActions, mapState } from 'pinia';
import ENV_VAR from "@/modules/env-vars";

export default {
	name: "StorageNoticeBar",

	data() {
		return {};
	},

	computed: {
		...mapState(useRootStore, {
			usagePercentage: (store) => store.userStats.percentSizeLimit,
			noticeFlags: 'noticeFlags',
			hasOverloadedZeroStorage: (store) => store.userStats.percentSizeLimit,
			subscriptionsUrl: (store) => {
				const token = (store.auth && store.auth.refreshToken) || "";
				const subUrl =
			  (ENV_VAR.ACCOUNT_URL || "") + "#/signin/subscriptions%2Fdrive";
				return subUrl + (token ? "?token=" + encodeURIComponent(token) : "");

			}
		}),
	},

	methods: {
		...mapActions(useRootStore, {
			DISMISS_STORAGE_EXCEEDED_NOTICE: 'DISMISS_STORAGE_EXCEEDED_NOTICE',
			DISMISS_STORAGE_EXCEEDED_WARNING: 'DISMISS_STORAGE_EXCEEDED_WARNING'

		}),
		dismissStorageExceededNotice() {
			this.DISMISS_STORAGE_EXCEEDED_NOTICE();
		},

		dismissStorageExceededWarning() {
			this.DISMISS_STORAGE_EXCEEDED_WARNING();
		},
	},
};
</script>
