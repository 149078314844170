class DriveItem {
	id = '';
	name = '';
	type = '';
	parentFolderId = '';
	permissions = null;
	owner = null;
	creator = null;
	createdAt = null;
	updatedAt = null;
	trashedAt = null;
	sharedAt = null;
	members = null;
	thumbnails = null;
	hierarchy = null;
};

export default DriveItem;
