<style scoped>
/* header */
.content-header {
	flex: 0 0 auto;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}

.location {
	position: relative;
}

.location .fa-caret-down{
	position: absolute;
    content: "\f0d7";
    bottom: 2px;
    right: 0px;
    height: 20px;
    width: 20px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}
.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
}
.content-toolbar {
	flex: 0 0 auto;
	padding: 0 30px;
	font-size: 0.9em;
	margin-bottom: 4px;
	min-height: 40px;
}
.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px;
}

.events {
	padding: 1em 0;
}

/* webhook form */

.create-webhook {
	display: flex;
	flex-direction: column;
}

.form-input {
	padding: .5em 0;
	max-width: 400px;
}

.url {
	align-items: center;
}

.url img {
	padding: 0 .5em;
}

.location-container {
	justify-content: space-between;
}

#location {
	caret-color: transparent;
}

.location {
	width: 100px;
}

.depth {
	width: 50px;
}

.event {
	margin-bottom: 1em;
}

.checkbox {
	padding: 1em 1em .5em 0;
}

.checkbox label {
		text-align: center;
		padding-left: .5em;
}

.generate-webhook {
	margin-right: 1em;
	font-size: 1.1em;
	vertical-align: baseline;
	font-weight: normal;
	padding: 0.5em 1em;
}

.menu-action {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.select-input {
	height: 28.4px
}

.notice {
    margin-top: 1rem;
    padding: .5em 0 .5em 1em;
	max-width: 400px;
}

.notice.error {
	border: 1px solid #FCD1CF;
    background-color: #FFECEB;
    color: #750E0B;
}

.err {
	border: 1px solid #750E0B;
    color: #750E0B;
}

.errlabel {
	color: #750E0b;
}

.radio {
	padding: 1em 2.5em .5em 0;
}
.radio label {
	padding-left: .5em;
}

#item-id:disabled {
	border: none;
}


@media only screen and (max-width: 410px) {
	.location-container {
		flex-direction: column;
		align-items: start;
	}
	.location {
		width: 100%;
		padding: 0.5em 0;

	}
	.id {
		width: 100%;
		padding: 0.5em 0;
	}
	.depth {
		width: 100%;
		padding: 0.5em 0;
	}
	.event-radio {
		display: flex;
		flex-direction: column;
		align-items: start;
	}
}

</style>

<template>
	<section>
		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs">
					<span><router-link to="/settings/webhooks"><i class="fa fa-arrow-circle-left"></i> Settings</router-link></span>
				</div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<div class="location-title">Edit Webhook</div>
			</div>
		</header>

		<div class="content-section">
			<div class="content-view">
				<form class="create-webhook" @submit.prevent="onSubmit">
					<div class="form-input column">
						<label for="name">Name</label>
						<input type="text" id="name" v-model="name" autofocus>
					</div>
					<div class="form-input column">
						<div class="url row">
							<label for="url" :class="{'errlabel' : error.type === 'NO_URL' }">URL</label>
							<img src="@/assets/icon-secure-webhook.svg" alt="padlock"  v-if="hasSecret" title="This webhook is secured with a secret key"/>
						</div>
						<input type="text" id="url" v-model="url" :class="{'err' : error.type === 'NO_URL' }">
					</div>
					<div class="row form-input location-container">
						<div class="location column">
							<label for="location">Location</label>
							<select v-model="location" class="select-input">
								<option value="all" class="menu-action">All</option>
								<option value="folder" >Folder</option>
								<option value="file">File</option>
							</select>
						</div>
						<div class="id column">
							<label for="item-id" :class="{'errlabel' : error.type === 'NO_ID' }">ID</label>
							<input type="text" id="item-id" v-model.trim="folderId" v-if="location === 'folder' || location === 'all'" :class="{'err' : error.type === 'NO_ID' }" :disabled="location === 'all'">
							<input type="text" id="item-id" v-model.trim="fileId" v-else :class="{'err' : error.type === 'NO_ID' }">
						</div>
						<div class="depth column">
							<label for="depth" :class="{'errlabel' : error.type === 'NO_FOLDER_DEPTH' }">Depth</label>
							<input type="number" id="depth" step="1" v-model.number="depth" :disabled="location === 'all' || location === 'file'" :class="{'err' : error.type === 'NO_FOLDER_DEPTH' }">
						</div>
					</div>
					<div class="events">
						<label :class="{'errlabel' : error.type === 'NO_EVENTS' }">Events</label>
						<div class="row centering event-radio">
							<div class="radio">
								<input type="radio" id="individual-events" value="false" v-model="sendAllEvents" :checked="sendAllEvents === 'false'">
								<label for="individual-events">Select Individual Events</label>
							</div>
							<div class="radio">
								<input type="radio" id="all-events" value="true" v-model="sendAllEvents" :checked="sendAllEvents === 'true'">
								<label for="all-events">All Events</label>
							</div>
						</div>
						<div class="row flowing" :class="{'err' : error.type === 'NO_EVENTS' }">
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="file-created" value="FileCreated" v-model="events" :disabled="location === 'file'"/>
									<label for="file-created">File Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-trashed" value="FileTrashed" v-model="events"/>
									<label for="file-trashed">File Trashed</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-deleted" value="FileDeleted" v-model="events"/>
									<label for="file-deleted">File Deleted</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-restored" value="FileRestored" v-model="events"/>
									<label for="file-restored">File Restored</label>
								</div>
							</div>
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="file-meta-updated" value="FileMetaUpdated" v-model="events"/>
									<label for="file-meta-updated">File Meta Updated</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="thumbnail-created" value="ThumbnailCreated" v-model="events"/>
									<label for="thumbnail-created">Thumbnail Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-created" value="FolderCreated" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-created">Folder Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-trashed" value="FolderTrashed" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-trashed">Folder Trashed</label>
								</div>
							</div>
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="folder-deleted" value="FolderDeleted" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-deleted">Folder Deleted</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-restored" value="FolderRestored" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-restored">Folder Restored</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-meta-updated" value="FolderMetaUpdated" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-meta-updated">Folder Meta Updated</label>
								</div>
							</div>
						</div>
					</div>
					<div class="row centering">
						<button class="button generate-webhook" :disabled="submitting">Update Webhook</button>
						<router-link to="/settings/webhooks">Cancel</router-link>
					</div>
				</form>
				<div class="notice error" v-if="error.type">
					{{ error.msg }}
				</div>
			</div>

		</div>


	</section>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue';
import { mapActions, mapState } from 'pinia';
import driveService from '../modules/drive-service';
import { useRootStore } from '../store/rootStore';

export default {
	props: {
		id: String
	},
	components: {
		UserMenu,
	},
	data () {
		return {
			location: '',
			sendAllEvents: '',
			webhookId: '',
			ownerId: '',
			httpMethod: '',
			url: '',
			name: '',
			updatedAt: null,
			hasSecret: '',
			fileId: '',
			folderId: '',
			depth: null,
			events: [],
			submitting: false,
			error: {
				type: '',
				msg: ''
			},
		};
	},

	watch: {
		location(newLocation, oldLocation) {
			if (newLocation === 'all') {
				this.folderId = '';
				this.depth = null;
			}
			if (oldLocation === 'file' && newLocation === 'folder') {
				this.depth = 2;
			}
			if (oldLocation === 'folder' && newLocation === 'file') {
				this.depth = null;
			}
		},
		events(newList) {
			const fileEvents = newList.filter(e => !e.includes('Folder') && !e.includes('FileCreated'));
			if (newList.length >= 11) {
				this.sendAllEvents = 'true';
			} else if (this.location === 'file' && fileEvents.length >= 5) {
				this.sendAllEvents = 'true';
			} else {
				this.sendAllEvents = 'false';
			}
		},
		sendAllEvents(newBool) {
			const fileEvents = this.events.filter(e => !e.includes('Folder') && !e.includes('FileCreated'));
			const allEvents = ["FileCreated","FileTrashed","FileDeleted","FileRestored","FolderTrashed","FolderCreated","ThumbnailCreated","FileMetaUpdated","FolderDeleted","FolderRestored","FolderMetaUpdated"];
			if (newBool === 'true') {
				this.events = allEvents;
			}
			if ((newBool === 'false' && this.events.length >= 11) || (newBool === 'false' && fileEvents.length >= 5 && this.location === 'file')) {
				this.events = [];
			}
		}
	},

	computed: {
		...mapState(useRootStore, {
			webhook: (store) => store.webhookList.find(webhook => webhook.id === store.router.currentRoute.value.query.id)
		}),
	},

	methods: {
		...mapActions(useRootStore, {
			LIST_WEBHOOKS: 'LIST_WEBHOOKS'
		}),
		setWebhook() {
			this.httpMethod = this.webhook.httpMethod;
			this.ownerId = this.webhook.ownerId;
			this.updatedAt = this.webhook.updatedAt;
			this.url = this.webhook.url;
			this.name =  this.webhook.name || '';
			this.hasSecret = this.webhook.hasSecret;
			this.folderId = this.webhook.config.folderId;
			this.fileId = this.webhook.config.fileId;
			this.depth = this.webhook.config.depth || null;
			this.events = this.webhook.config.events || [];
			this.location = this.webhook.config.location;
		},

		formValidation() {
			const fileEvents = this.events.filter(e => !e.includes('Folder') && !e.includes('FileCreated'));
			this.error = {
				type: '',
				msg: ''
			};
			if (!this.url) {
				this.error.type = 'NO_URL';
				this.error.msg = 'Please enter a URL for your webhook';
				return;

			}
			if (this.location === 'file' && !this.fileId || this.location === 'folder' && !this.folderId) {
				this.error.type = 'NO_ID';
				this.error.msg = `Please enter a ${this.location} id.`;
				return;
			}

			if (this.location === 'folder' && (!this.depth || Number.isNaN(this.depth) || this.depth <= 0)) {
				this.error.type = 'NO_FOLDER_DEPTH';
				this.error.msg = 'Please enter a valid number for folder depth';
				return;
			}

			if (this.location === 'file' && !fileEvents.length) {
				this.error.type = 'NO_EVENTS';
				this.error.msg = 'Please select events for your webhook';
			}

			if (!this.events.length) {
				this.error.type = 'NO_EVENTS';
				this.error.msg = 'Please select events for your webhook';
				return;
			}

		},

		handleErrorResponse(reason) {
			//TODO: Match access key error handling with arrays
			this.submitting = false;
			//Quick fix to handle some error handling inconsistency
			reason = [reason];
			if (Array.isArray(reason)) {
				reason.find(err => {
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('url')) {
						this.error.type = 'NO_URL';
						this.error.msg = 'Please enter a valid URL.';
					}
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('fileId')) {
						this.error.type = 'NO_ID';
						this.error.msg = 'File ID not found.';
					}
					if (err.error === 'FILE_NOT_FOUND') {
						this.error.type = 'NO_ID';
						this.error.msg = 'File ID not found.';
					}
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('folderId')) {
						this.error.type = 'NO_ID';
						this.error.msg = 'Folder ID not found.';
					}
					if (err.error === 'FOLDER_NOT_FOUND') {
						this.error.type = 'NO_ID';
						this.error.msg = 'FOLDER ID not found.';
					}
					if (err.datapath.includes('depth')) {
						this.error.type = 'NO_FOLDER_DEPTH';
						this.error.msg = 'Please enter a number for depth.';
					}
					if (err.error === 'FORBIDDEN') {
						this.error.type = 'FORBIDDEN';
						this.error.msg = 'Unfortunately, you don\'t seem to have permissions to access this feature';
					}
					if (err.error === 'TOPIC_NOT_FOUND') {
						this.error.type = 'TOPIC_NOT_FOUND';
						this.error.msg = 'Unfortunately, we couldn\'t find these events types for the selected id';
					}
					if (err.error === 'WEBHOOK_NOT_FOUND') {
						this.error.type = 'WEBHOOK_NOT_FOUND';
						this.error.msg = 'Hmmm. It seems this webhook doesn\'t exist!';
					}
				});
			} else {
				this.error.type = reason.error;
				this.error.msg = reason.message;
			}
		},

		modelFormData() {
			const webhookUpdate = {
				httpMethod: 'POST',
				url: this.url,
				config: {
					events: this.events.length >= 11 ? "*" : this.events
				}
			};
			if (this.name) {
				webhookUpdate.name = this.name;
			}
			if (this.location === 'folder') {
				webhookUpdate.config.depth = this.depth;
				webhookUpdate.config.folderId = this.folderId;
			}

			if (this.location === 'file') {
				webhookUpdate.config.fileId = this.fileId;
				webhookUpdate.config.events = this.sendAllEvents === 'false' ? this.events.filter((e) => !e.includes('Folder') && !e.includes('FileCreated')) : '*';
			}

			if (this.location === 'all') {
				webhookUpdate.config.folderId = '*';
			}


			return webhookUpdate;
		},

		onSubmit() {
			this.submitting = true;
			const webhookUpdate = this.modelFormData();
			this.formValidation();
			if (this.error.type) {
				this.submitting = false;
				return;
			}
			const webhookId = this.id;
			//TODO: use store
			return driveService.updateWebhook(webhookId, webhookUpdate)
				.then(() => {
					this.$router.push({path: '/settings/webhooks?q=update'});
				}, (this.handleErrorResponse));
		}

	},

	mounted() {
		if (!this.url) {
			this.LIST_WEBHOOKS()
				.then(() => {
					this.setWebhook();
				});
		}
		this.setWebhook();
	}
};
</script>
