<style scoped>

.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
	opacity: var(--menu-separator-opacity);
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}

.user-menu-button {
	position: relative;
}

.user-menu-popover {
	background: var(--menu-popover-background-color);
	border: 1px solid var(--menu-popover-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	display: block;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	min-width: 150px;
	z-index: 99999;
	transition: none;
	top: 95%;
	right: 0;
	color: var(--body-color);
	box-sizing: border-box;
}
.user-menu-popover:focus {
	outline: none;
}

.user-menu-popover a {
	color: inherit;
	display: block;
	flex: 1 1 auto;
	padding: 0.4em 0.6em;
}

.user-name {
	display: inline-block;
	padding: 2px 6px;
	border-radius: 3px;
	cursor: pointer;
}
.active > .user-name {
	background-color: var(--active-user-name-background-color);
}

.menu-action {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-action:hover {
	background: #1e88e5;
	color: #fafafa;
}
.menu-action {
	cursor: pointer;
}

.fa {
	color: #aaa;
}
</style>

<template>
	<span class="account user-menu-button" ref="userMenu" :class="{active:userMenuVisible}">

		<span v-if="user" class="user-name" @click="userMenuVisible=!userMenuVisible">
			{{ user.usr }} <i class="fa fa-caret-down"></i>
		</span>

		<span v-else class="user-name">
			<router-link to="/signin">Sign in</router-link>
		</span>

		<div class="user-menu-popover" v-if="userMenuVisible" ref="userMenu">
			<div class="menu-action"><a :href="accountUrl" target="tekdcloudaccount" title="Manage My Account">My Account</a></div>
			<div class="menu-action"><router-link to="/settings">Settings</router-link></div>
			<div class="menu-action"><router-link to="/signout?reason=logout">Sign out</router-link></div>
		</div>

	</span>
</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import { useAuthStore } from '../store/auth';
import { mapState } from 'pinia';
import { createPopUpListener, removePopUpListener } from '@/modules/popup-menu';

export default {
	name: 'UserMenu',

	props: [],

	data () {
		return {
			userMenuVisible: false
		};
	},

	computed: {
		...mapState(useAuthStore, {

			user: (store) => store.user,

			accountUrl: (store) => {
				const token = store.refreshToken || '';
				const accountUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin';
				return accountUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			}

		})
	},

	methods: {
		initPopUpListener(element, fn) {
			return createPopUpListener(element, fn);
		},

		destroyPopUpListener(element, fn) {
			return removePopUpListener(element, fn);
		},

		showUserMenu(bool) {
			this.userMenuVisible = bool;
		}
	},

	mounted() {
		this.initPopUpListener(this.$refs.userMenu, this.showUserMenu);
	},
	beforeUnmount() {
		this.destroyPopUpListener(this.$refs.userMenu, this.showUserMenu);
	}

};
</script>
