<style scoped>
.v-context {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	background: var(--v-context-background-color);
	border: 1px solid var(--v-context-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	display: block;
	margin: 0;
	padding: 0;
	position: fixed;
	width: 250px;
	z-index: 99999;
	transition: none;
}
.v-context:focus {
	outline: none;
}
</style>

<template>
	<div ref="contextMenu"
		v-show="show"
		class="v-context"
		:class="{'menu-right':onRightEdge}"
		tabindex="-1"
		:style="style"
		@click="onClick"
		@contextmenu.capture.prevent>
		<slot :context="dataRef"></slot>
	</div>
</template>

<script setup>
import { createPopUpListener, removePopUpListener } from '@/modules/popup-menu';
import { ref, computed, watchEffect, nextTick, onMounted, onBeforeUnmount } from 'vue';

const onRightEdge = ref(false);
const top = ref(null);
const left = ref(null);
const show = ref(false);
const dataRef = ref(null);
const contextMenu = ref(null);

onMounted(() => {
	createPopUpListener(contextMenu.value, close);
});

onBeforeUnmount(() => {
	removePopUpListener(contextMenu.value, close);
	if (props.closeOnScroll) {
		removeScrollEventListener();
	}
});

const props = defineProps({
	closeOnClick: {
		type: Boolean,
		default: true
	},
	closeOnScroll: {
		type: Boolean,
		default: true
	}
});

const emit = defineEmits(['close', 'open']);

// Allow parent component to access open and close methods on this.$refs
defineExpose({
	open,
	close
});

const style = computed(() => {
	return show.value
		? { top: `${top.value}px`, left: `${left.value}px` }
		: null;
});

/**
	 * Add scroll event listener to close context menu.
	 */
function addScrollEventListener () {
	window.addEventListener('scroll', close);
};
/**
	 * Close the context menu.
	 *
	 * @param {boolean|Event} emit Used to prevent event being emitted twice from when menu is clicked and closed
	 */
function close (emit = true) {

	if (!show.value) {
		return;
	}
	top.value = null;
	left.value = null;
	dataRef.value = null;
	show.value = false;
	if (props.closeOnScroll) {
		removeScrollEventListener();
	}
	if (emit) {
		emit('close');
	}
};
/**
	 * Close the menu when pressing outside
	 */
// function onPressOutside (event) {
// 	close();
// };
/**
	 * Close the menu if `closeOnClick` is set to true.
	 */
function onClick () {
	if (props.closeOnClick) {
		close(false);
	}
};
/**
	 * Open the context menu.
	 *
	 * @param {MouseEvent} event
	 * @param {array|object|string} data User provided data for the menu
	 */
function open (event, data) {
	dataRef.value = data;
	show.value = true;
	nextTick(() => {
		positionMenu(event.clientY, event.clientX);
		contextMenu.value.focus();
		if (props.closeOnScroll) {
			addScrollEventListener();
		}
		emit('open', event, dataRef.value, top.value, left.value);
	});
	// TODO: return a promise for a selected action
};
/**
	 * Set the context menu top and left positions.
	 *
	 * @param {number} top
	 * @param {number} left
	 */
function positionMenu (yPos, xPos) {
	const largestHeight = window.innerHeight - contextMenu.value.offsetHeight - 25;
	const largestWidth = window.innerWidth - contextMenu.value.offsetWidth - 25;
	if (yPos > largestHeight) {
		yPos = largestHeight;
	}
	if (xPos > largestWidth) {
		xPos = largestWidth;
	}
	top.value = yPos;
	left.value = xPos;

	onRightEdge.value = (xPos + 250 > largestWidth);
};
/**
	 * Remove the scroll event listener to close the context menu.
	 */
function removeScrollEventListener () {
	window.removeEventListener('scroll', close);
};


/**
	 * Add or remove the scroll event listener when the prop value changes.
	 *
	 * @param {boolean} closeOnScroll
	 * @param {boolean} oldValue
	 */
watchEffect(() => {
	//watchEffect is triggered when dependencies change so comparison is not needed
	if (props.closeOnScroll && show.value) {
		addScrollEventListener();
	} else {
		removeScrollEventListener();
	}
});

</script>
