<style scoped>

.menu-wrapper {
	position: relative;
	font-size: 1rem;
	margin: auto;
}
.auto-width .menu-popover {
	width: auto;
	max-width: 250px;
}
.drag-in-progress .menu-popover {
	z-index: 1;
}

.menu-popover {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	background: var(--menu-popover-background-color);
	border: 1px solid var(--menu-popover-border-color);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	display: block;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	min-width: 150px;
	z-index: 99999;
	transition: none;
	top: 95%;
	left: 0;
	color: #333;
	color: var(--body-color);
	box-sizing: border-box;
}
.right .menu-popover {
	right: 0;
	left: auto;
}
.menu-popover:focus {
	outline: none;
}

.menu-popover a {
	color: inherit;
	display: block;
	flex: 1 1 auto;
	padding: 0.4em 0.6em;
}

.menu-button {
	display: inline-block;
	padding: 2px 6px;
	border-radius: 3px;
	cursor: pointer;
}
.button.menu-button {
	padding: 0;
}
.button {
	overflow: visible;
}
.menu-button.active {
	background-color: #f2f2f2;
}
.menu-button.tag {
	background-color: #eee;
	background-color: var(--menu-button-tag-background-color);
}
.menu-button.no-padding {
	padding: 0;
}
.menu-button.tag.active {
	background-color: #ddd;
}

.menu-button.tag .fa,
.menu-button.tag {
	color: #555;
	color: var(--menu-button-tag-color);
}

.menu-trigger {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.fixed {
	position: fixed;
}

.menu-popover.fixed {
	width: auto;
}

.menu-action {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-action:hover {
	background: #1e88e5;
	color: #fafafa;
}
.menu-action {
	cursor: pointer;
}

.fa {
	color: #aaa;
	padding-left: .25em;
}
</style>

<template>
	<span class="menu-wrapper" @dragenter="onClick" ref="menuWrapper" :class="{active:showMenu}">

		<span class="menu-button" @dragenter.prevent @click="onClick" :class="buttonClass">
			<slot name="buttonText"></slot>
			<i class="fa" :class="getIconClass"></i>
		</span>

		<div class="menu-popover column" v-if="showMenu || forceShowMenu" ref="menuPopover" :style="style" :class="{fixed:isFixed}" @click="onClick">
			<slot></slot>
		</div>

	</span>
</template>

<script>
import { createPopUpListener, removePopUpListener } from '@/modules/popup-menu';

export default {
	name: 'DropdownMenu',

	props: {
		forceShowMenu: Boolean,
		iconClass: String,
		buttonClass: String, /* needs a specific button class (tag, button, etc)*/
		isFixed: Boolean /* needs dynamic positioning */
	},

	data () {
		return {
			showMenu: false,
			style: null,
			scrollElement: null
		};
	},

	computed:{
		getIconClass() {
			return this.iconClass || 'fa-caret-down';
		}
	},

	methods:{
		/**
         * Add scroll event listener to close dropdown menu.
         */
		addScrollEventListener () {
			this.scrollElement.addEventListener('scroll', this.close);
		},
		/**
         * Remove the scroll event listener to close the dropdown menu.
         */
		removeScrollEventListener () {
			this.scrollElement.removeEventListener('scroll', this.close);
		},
		/**
		 * Handle Mouse Event click
		 *
		 */
		onClick(e) {
			this.showMenu = !this.showMenu;

			if (this.showMenu) {
				this.open(e);
			} else {
				this.close();
			}
		},
		/**
		 * Open the dropdown menu.
		 *
		 * @param {MouseEvent} event
		 */
		open (e) {
			// if dropdown menu needs fixed positioning
			if (this.isFixed) {
				this.scrollElement = e.currentTarget.closest('.dropdown-scroll-container');
				const offsetParentRect = e.currentTarget.offsetParent.getBoundingClientRect();
				const top = offsetParentRect.top + offsetParentRect.height ;
				const left = offsetParentRect.left;
				this.positionMenu(top, left);

				if (this.scrollElement) {
					this.addScrollEventListener();
				}

			}
		},
		/**
		 * Close the dropdown menu.
		 *
		 */
		close () {
			this.showMenu = false;
			this.style = null;

			if (this.scrollElement) {
				this.removeScrollEventListener();
				this.scrollElement = null;
			}

		},
		/**
		 * Set the dropdown menu top and left positions.
		 *
		 * @param {number} top
		 * @param {number} left
		 */
		positionMenu (top, left) {
			this.$nextTick(() => {
				const largestHeight = window.innerHeight - this.$refs.menuPopover.offsetHeight;
				const largestWidth = window.innerWidth - (this.$refs.menuPopover.offsetWidth);

				if (top > largestHeight) {
					// align to top edge of trigger button
					top = this.$el.offsetTop - this.$refs.menuPopover.offsetHeight;
				}
				if (left > largestWidth) {
					// align to right edge of trigger button
					left = this.$el.offsetLeft - (this.$refs.menuPopover.offsetWidth - this.$el.offsetWidth);
				}

				this.style =  { top: `${top}px`, left: `${left}px` };
			});
		},

		initPopUpListener(element, fn) {
			return createPopUpListener(element, fn);
		},

		destroyPopUpListener(element, fn) {
			return removePopUpListener(element, fn);
		}
	},

	mounted() {
		this.initPopUpListener(this.$refs.menuWrapper, this.close);
	},
	beforeUnmount() {
		this.destroyPopUpListener(this.$refs.menuWrapper, this.close);
	},

	beforeUnmount () {
		if (this.scrollElement) {
			this.removeScrollEventListener();
		}
	}

};
</script>
