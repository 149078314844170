<style src="./styles/styles.css"></style>

<style scoped>
.app {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	height: 100%;
}
.app-view {
	flex: 1 1 auto;
}
</style>

<template>
	<div id="app" class="app" @contextmenu.capture="onAppContext">
		<router-view class="app-view" />
	</div>
</template>

<script>
import { useRootStore } from '@/store/rootStore';
import { mapActions } from 'pinia';

export default {
	name: 'App',

	methods: {
		...mapActions(useRootStore, {
			UPDATE_USER_INFO: 'UPDATE_USER_INFO',
		}),
		onAppContext (event) {
			const matches = Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
			if (!event.ctrlKey && !matches.call(event.target, 'input, code, .allow-context, .allow-context *')) {
				event.preventDefault();
			}
		}
	},

	mounted () {
		// TODO: show loading until this is resolved
		//Getting an error on this on signin, bad request
		document.documentElement.classList.remove('navigating', 'initializing');
	},

};
</script>
