const ENV_VARS = {
	ENVIRONMENT:   'production',
	BRAND:         'tektronix',
	DWC_CLIENT_ID: 'ad60e1aec682ff27cec3b06209357076830bcfbc6831aafd',
	WEBSOCKET_URL: 'wss://sentinel.api.tekcloud.com',
	GTM_KEY:       'GTM-W3XV7KP',
	THEME_URL:     'https://theme.tekcloud.com/prod/',
	ACCOUNT_URL:   'https://account.tekcloud.com/',
	AUTH_URL:      'https://auth.tekcloud.com/#/',
	AUTH_API:      'https://api-init.tekcloud.com/auth/',
	ACCOUNT_API:   'https://api-init.tekcloud.com/account/',
	DRIVE_API:     'https://drive.api.tekcloud.com/',
	WAVES_APP_URL: 'https://waves.tekcloud.com/',
	LINES_APP_URL: 'https://lines.tekcloud.com/',
	SENTRY_DNS:	   'https://8d4770a64cd5406a9820c5895f5231bd@o966792.ingest.sentry.io/5919275'
};

function override(map) {
	for (var key in map) {
		if (map.hasOwnProperty(key) && typeof map[key] === 'string') {
			ENV_VARS[key] = map[key];
		}
	}
}

// TODO: add update event

export {
	ENV_VARS as default,
	override
};
