
const renameInput = {

	mounted: function(el, binding, node) {
		function getEndIndex(value) {
			if (typeof value !== 'string') {
				return 0;
			}
			var idx = value.lastIndexOf('.');
			return (idx > 0) ? idx : value.length;
		}
		function selectName() {
			el.selectionStart = 0;
			el.selectionEnd = getEndIndex(el.value);
		}
		if (binding && binding.instance && typeof binding.instance.$nextTick === 'function') {
			binding.instance.$nextTick(() => {
				selectName();
			});
		} else {
			selectName();
		}
	}
};

export { renameInput };
