<style scoped>
.location-search {
	position: relative;
}
.location-search > .search-input {
	border: 0 none;
	border-radius: 3px;
	background-color: #F2F2F2;
	background-color: var(--search-input-background-color);
	height: 100%;
	width: 100%;
	padding: 8px 40px 8px 12px;
	box-sizing: border-box;
	-webkit-appearance: textfield;
}
.location-search > .action-submit {
	position: absolute;
	top: 1px;
	bottom: 1px;
	right: 1px;
	margin: auto;
	border: 0 none;
	width: 40px;
	box-sizing: border-box;
	text-align: center;
	padding: 0;
	background-color: transparent;
}

.icon-search {
	vertical-align: bottom;
	height: 22px;
	width: 20px;
}

</style>

<template>
	<form class="location-search" @submit.prevent="applySearch(params)">
		<input class="search-input" type="search" :placeholder="placeholderText" v-model="params.name" @search="onSearch" />
		<button class="action-submit" type="submit"><img src="@/assets/icon-search.svg" class="icon-search" /></button>
	</form>
</template>

<script>

export default {
	name: 'SearchBar',

	props: {
		placeholder: String,
		// TODO: pass fixed params
		text: String,
		folderId: String,
		type: String,
		depth: Number,
		orderBy: Array,
		uploadState: String
	},

	directives: {

	},

	data () {
		return {
			placeholderText: this.placeholder || 'Search',

			params: {
				name: this.text || '',
				folderId: this.folderId,
				type: this.type,
				depth: (this.depth && isFinite(this.depth)) ? +this.depth : 0/0,
				orderBy: Array.isArray(this.orderBy) ? this.orderBy : [],
				uploadState: this.uploadState
			}
		};
	},

	computed: {

	},

	methods: {

		onSearch (event) {
			if (!this.params.name && !this.$router.$routing) {
				// search has been cleared
				this.$emit('searchcleared', '');
			}
		},

		applySearch (params) {
			if (!params) {
				params = {};
			}
			const qp = {};
			if (typeof params.name === 'string' && params.name !== '') {
				qp.name = params.name;
			}
			if (!qp.name) {
				// search has been cleared
				this.$emit('searchcleared', '');
			}
			if (typeof params.folderId === 'string' && params.folderId !== '') {
				qp.folderId = params.folderId;
			}
			if (typeof params.type === 'string' && params.type !== '') {
				qp.type = params.type;
			}
			if (typeof params.depth === 'number' && params.depth && isFinite(params.depth)) {
				qp.depth = params.depth;
			}
			if (Array.isArray(params.orderBy) && params.orderBy.length > 0) {
				qp.orderBy = params.orderBy;
			}
			if (!this.$router.$routing) {
				// only change route if not already changing
				this.$router.push({
					path: '/search',
					query: qp
				});
			}
		}

	}

};
</script>
