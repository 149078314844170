<style scoped>
.settings-container {
	padding: 2em;
	display: flex;
	flex-direction: column;
}
.toolbar {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.menu-item input {
	visibility: hidden;
}

.menu-item label {
	cursor: pointer;
	padding: .5em;
}

.menu-item input:checked + label{
	border-bottom: 2px solid #979797
}

a {
	color: #333;
	color: var(--body-color);
}

</style>

<template>

	<div>
		<section class="settings-container">
			<div class="toolbar" v-if="isProUser">
				<div class="menu-item">
					<router-link to="/settings/accesskeys">
						<input type="radio" value="accesskeys" id="accesskeys" v-model="settingsView"/>
						<label for="accesskeys">Access Keys</label>
					</router-link>
				</div>
				<div class="menu-item">
					<router-link to="/settings/webhooks">
						<input type="radio" value="webhooks" id="webhooks" v-model="settingsView"/>
						<label for="webhooks">Webhooks</label>
					</router-link>
				</div>
			</div>
			<AccessKeysList v-if="settingsView === 'accesskeys'" />
			<WebhooksList v-if="settingsView === 'webhooks' && isProUser" />

		</section>

	</div>

</template>

<script>
import { useAuthStore } from "../../store/auth";
import AccessKeysList from "./AccessKeysList.vue";
import WebhooksList from "./WebhooksList.vue";
import { mapState } from "pinia";

export default {
	props: {
		section: String
	},
	components: {
		AccessKeysList,
		WebhooksList
	},
	data () {
		return {
		};
	},
	computed: {
		...mapState(useAuthStore, {
			isProUser(store) {
				const userClaims = store.user.claims;
				const proClaims = ["drive:api:webhook:create", "drive:api:webhook:read", "drive:api:webhook:update","drive:api:webhook:delete"];
				return proClaims.every(claim => userClaims.includes(claim));
			},
		}),
		settingsView() {
			const section = this.section || 'accesskeys';
			return section;
		}
	},
};
</script>
