

function getFileNameFromUrl(url) {
	// Note: This is a pretty lame patter for S3 file names
	if (typeof url !== 'string') {
		return '';
	}
	var idx = url.indexOf('?');
	if (idx >= 0) {
		url = url.slice(0, idx);
	}
	idx = url.indexOf('#');
	if (idx >= 0) {
		url = url.slice(0, idx);
	}
	// Trim trailing slash
	idx = url.length;
	while (idx > 0 && url[idx - 1] === '/') {
		idx--;
	}
	// get last segment
	idx = url.lastIndexOf('/');
	if (idx >= 0) {
		url = url.slice(idx + 1);
	}
	return decodeURIComponent(url);
}

function agentDownload(url, filename) {
	if (typeof url !== 'string') {
		throw new Error('Invalid Download URL');
	}

	// TODO: consider ensuring extension on filename
	var a = document.createElement('a');
	a.href = url;
	//a.target = '_blank';

	// NOTE: The download attribute only works for same origin URLs
	// TODO: create fallback for browsers that don't support the "download" attribute
	a.download = filename || getFileNameFromUrl(url) || 'file';

	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}

export {
	agentDownload
};
