import { Buffer } from 'buffer';
// Offset expiry to prevent expiration between validation and use (e.g., xhr request & transit delay)
const EXPIRY_OFFSET_MS = 1e4; // 10 seconds
var REFRESH_URL = 'https://api.init.st/auth/tokens';

function tryJson(json) {
	try {
		return JSON.parse(json);
	} catch (err) {
		return null;
	}
}

function getResponseBodyError(body) {
	if (body == null) {
		return null;
	}
	const err = new Error('Unsuccessful Response');
	// Adopt response error message, if available
	if (typeof body.message === 'string' && body.message !== '') {
		err.message = body.message;
	} else if (body.error && typeof body.error.type === 'string') {
		// {"error":{"type":"TOKEN_EXPIRED","message":"[req_p1bf1johkebve] refresh token has expired"},"support":"https://api.init.st/support/errors/TOKEN_EXPIRED?v=1.2.15","version":"1.2.15"}
		err.message = body.error.type;
		if (body.version) {
			err.version = body.version;
		}
	}
	return err;
}

async function postJson(url, data) {
	return new Promise((resolve, reject) => {

		const xhr = new XMLHttpRequest();
		xhr.open('POST', url, true);

		function respond(err) {
			const body = tryJson(xhr.responseText);
			if (xhr.status < 200 || xhr.status >= 300) {
				if (!err) {
					err = getResponseBodyError(body) || new Error(xhr.statusText || 'Unsuccessful Response');
				}
				return reject(err);
			}
			if (body == null || typeof body.accessToken !== 'string') {
				return reject(new Error('Invalid API Response'));
			}
			resolve(body);
		}

		xhr.onerror = function () {
			respond(xhr.error || new Error('HTTP Request Failed'));
		};
		xhr.onabort = function () {
			respond(new Error('HTTP Request Aborted'));
		};
		xhr.onload = function () {
			respond(null);
		};

		xhr.responseType = 'text';
		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify(data));

		return xhr;

	});
}

async function requestAccessToken(refreshToken) {
	const data = await postJson(REFRESH_URL, {
		refreshToken: refreshToken
	});
	if (data == null || typeof data.accessToken !== 'string') {
		return Promise.reject(new Error('Invalid API Response'));
	}
	return data.accessToken;
}

function getTokenExpiry(token) {
	if (typeof token === 'string') {
		var data = tryJson(Buffer.from(token.split('.')[1], 'base64').toString());
		if (data) {
			// TODO: handle infinite exp (null/0)
			return data.exp * 1e3;
		}
	}
	return 0/0;
}

function isTokenValid(token) {
	if (typeof token !== 'string') {
		return false;
	}
	var expiry = getTokenExpiry(token);
	// check if now (plus offset) is before expiry timestamp
	return (Date.now() + EXPIRY_OFFSET_MS < expiry);
}




// TODO: refactor this to use promises


function TokenRefresher(token) {
	if (!(this instanceof TokenRefresher)) {
		return new TokenRefresher(token);
	}
	if (typeof token !== 'string') {
		throw new Error('Invalid refresh token');
	}
	this._refreshToken = token;
	this._accessToken = null;
	this._pending = null;
}
TokenRefresher.prototype = Object.create(Object.prototype, {
	constructor: { value: TokenRefresher },

	_refreshToken: { value: '', writable:true },
	_accessToken: { value: '', writable:true },
	_pending: { value: null, writable:true },

	getAccessToken: {
		value: function () {
			// get valid access token

			if (!isTokenValid(this._refreshToken)) {
				// refresh token has expired
				return Promise.reject(new Error('INVALID_TOKEN'));
			}

			if (isTokenValid(this._accessToken)) {
				// token is still valid, return it
				return Promise.resolve(this._accessToken);
			}

			if (!this._pending) {
				// Request a new access token
				this._pending = requestAccessToken(this._refreshToken)
					.then((accessToken) => {
						// clear token request
						this._pending = null;

						this._accessToken = accessToken;
						return accessToken;
					})
					.catch((reason) => {
						// clear token request
						this._pending = null;

						return Promise.reject(new Error('INVALID_TOKEN'));
					});
			}

			return this._pending;
		}
	}

});

function createTokenRefresher(refreshToken) {
	return new TokenRefresher(refreshToken);
}


var refresherCache = {};
function getTokenRefresher(refreshToken) {
	if (!refresherCache.hasOwnProperty(refreshToken)) {
		refresherCache[refreshToken] = createTokenRefresher(refreshToken);
	}
	return refresherCache[refreshToken];
}

function getAccessToken(refreshToken) {
	var refresher = getTokenRefresher(refreshToken);
	if (!refresher) {
		return Promise.reject(new Error('Invalid Refresh Token'));
	}
	return refresher.getAccessToken();
};

function clear() {
	// unload known refresh/access tokens
	refresherCache = {};
}

function setRefreshUrl(value) {
	if (typeof value === 'string') {
		REFRESH_URL = value;
	}
}

export { getAccessToken, clear, setRefreshUrl };

