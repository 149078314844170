<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 500px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

.modal-container textarea {
	resize: none;
	max-height: 100px;;
	max-height: 100px;
	background-color: var(--modal-input-background-color);
	border-color: var(--modal-input-border-color);
}

.loading-members {
	font-size: 1.6em;
	color: #777;
	margin: 1em 0;
}




.modal-header h3 {
	font-size: 1.4em;
	margin-top: 0;
}

.modal-body {
	margin: 20px 0;
}

.share-link-fields {
	margin-bottom: 1.4em;
	font-size: 0.9em;
}

.share-link-row {
	align-items: center;
	margin-bottom: 0.2em;
}
.share-link-label {
	text-align: right;
	min-width: 70px;
	color: #777;
}
.share-link-url {
	margin: 0 0.5em;
	white-space: nowrap;
	overflow: auto;
	padding: 0.5em;
	background-color: #f8f8f8;
	background-color: var(--share-link-url-background-color)#f8f8f8;
}
.share-link-actions {
	align-self: stretch;
}
.copy-url {
	padding: 0.2em 0.8em;
	height: 100%;
}

.share-member-fields {
	display: flex;
	flex-direction: column;
}

.add-member-form {
	margin-bottom: 0.5em;
}

.share-direct-header {
	margin-bottom: 0.5em;
	font-size: 1.1em;
	display: flex;
	align-items: center;
}

.share-direct-label {
	flex: 1 1 auto;
}

.username-invalid-message {
	font-size: 0.8em;
	color: #c00;
	flex: 0 0 auto;
}

.username-input {
	margin-right: 0.4em;
	padding: 0.4em 5em .4em 0.7em;
	border: 1px solid #999;
}

.members-list {
	border: 1px solid #bbb;
	background-color: #fafafa;
	border: 1px solid var(--members-list-border-color);
	background-color: var(--members-list-background-color);
	height: 180px;
	list-style: none;
	margin: 0;
	padding: 0.4em;
	overflow: auto;
}
.member-empty {
	color: #777;
	padding: .5em;
}

.member-item {
	display: flex;
}
.member-item + .member-item {
	margin-top: 0.2em;
}
.member-username {
	flex: 1 1 auto;
	padding: 0.4em;
	text-overflow: ellipsis;
	overflow: hidden;
}
.member-actions {
	flex: 0 0 auto;
	width: 80px;
}
.owner-member {
	display: inline-block;
	height: 100%;
	line-height: 1.6em;
	padding: 0.2em 0.6em;
	box-sizing: border-box;
	border-radius: 3px;
	cursor: pointer;
	background-color: #f8f8f8;
	background-color: var(--owner-member-background-color);
	font-style: italic;
	color: #999;
	color: var(--owner-member-color);
	width: 100px;
	text-align: center;
}
.remove-member {
	height: 100%;
	width: 90px;
	padding: 0.2em 0.6em;
	box-sizing: border-box;
	background-color: #fff;
	background-color: var(--owner-member-color);
	border: 1px solid #ccc;
	border-radius: 3px;
	font-weight: bold;
	cursor: pointer;
	border-color: #c00;
	color: #c00;
}

.modal-footer {
	text-align: right;
}
.action-close {
	padding: 0.5em 1em;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}

.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
	opacity: var(--menu-separator-opacity);
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}
.menu-wrapper.absolute {
	top: 5.5px;
	right: 12px;
	position: absolute;
}
.relative {
	position: relative;
}


</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">

			<div v-if="loadingMembers" class="modal-container loading">
				<div class="loading-members">Loading<span class="loading-dots"><span class="loading-dot">.</span><span class="loading-dot">.</span><span class="loading-dot">.</span></span></div>
			</div>
			<div v-else-if="loadingError" class="modal-container loading">
				<span class="loading-members-error">Oh my! It seems we can't share that.</span>
			</div>
			<div v-else class="modal-container loaded dropdown-scroll-container">

				<div class="modal-header">
					<h3>Share Settings</h3>
				</div>

				<div class="modal-body">
					<div class="share-link-fields">
						<div class="row share-link-row">
							<span class="rigid share-link-label">URL</span>
							<code class="flexible share-link-url">{{ shareUrl }}</code>
							<span class="rigid share-link-actions">
								<button type="button" class="button copy-url" @click="copyShareUrl" :class="{copied:shareUrlCopied}" title="Copy URL to clipboard">
									<i class="fa" :class="{'fa-clipboard':!shareUrlCopied,'fa-clipboard-check':shareUrlCopied}"></i>
								</button>
							</span>
						</div>
						<div class="row share-link-row">
							<span class="rigid share-link-label" v-if="dItem.type === 'FOLDER'">Folder ID</span>
							<span class="rigid share-link-label" v-else>File ID</span>
							<code class="flexible share-link-url">{{ dItem.id }}</code>
							<span class="rigid share-link-actions">
								<button type="button" class="button copy-url" @click="copyShareId" :class="{copied:shareIdCopied}" title="Copy ID to clipboard">
									<i class="fa" :class="{'fa-clipboard':!shareIdCopied,'fa-clipboard-check':shareIdCopied}"></i>
								</button>
							</span>
						</div>
					</div>

					<div class="share-member-fields">
						<form @submit.prevent="addMember(newUsernames)" class="rigid add-member-form">
							<div class="share-direct-header">
								<span class="share-direct-label">Shared With</span>
								<span v-if="newUsernameInvalid" class="username-invalid-message"><em>{{newUsernameInvalid}}</em> is not a valid email address.</span>
								<span v-else-if="canNotModifyMembersError" class="username-invalid-message">You don't have permission's to share this.</span>
								<span v-else-if="maximumMembersError" class="username-invalid-message">You may only share with {{maximumMembersError.params.limit}} others.</span>
								<span v-else-if="addMembersError && addMembersError.length" class="username-invalid-message">You're not able to share this.</span>
							</div>
							<div class="row centering">
								<div class="row flexible relative">
									<textarea rows="1" v-autoheight="newUsernames === ''" type="search" autocomplete="email" class="gap username-input" v-model="newUsernames" placeholder="Enter An Email Address" />
									<DropdownMenu class="rigid absolute" :button-class="'tag'">
										<template v-slot:buttonText>
											<span v-if="permissions.edit">Editor</span>
											<span v-else>Viewer</span>
										</template>
										<ul class="menu-list">
											<li>
												<div class="menu-action" @click="permissions.edit = false">
													<span class="menu-icon"><i v-if="!permissions.edit" class="fas fa-check"></i></span>
													<span class="menu-label">Viewer</span>
												</div>
											</li>
											<li>
												<div class="menu-action"  @click="permissions.edit = true">
													<span class="menu-icon"><i v-if="permissions.edit" class="fas fa-check"></i></span>
													<span class="menu-label">Editor</span>
												</div>
											</li>
										</ul>
									</DropdownMenu>
								</div>
								<button type="submit" :disabled="addingMembers" class="button action-add-member">Add</button>
							</div>
						</form>
						<ul class="members-list flexible dropdown-scroll-container">
							<li v-if="!members?.length" class="member-empty">
								No one has access (including you?!)
							</li>
							<li v-for="member in members" :key="member.username" class="member-item">
								<span class="member-username" :title="member.username">{{ member.username }}</span>
								<span class="member-actions row centering">
									<span v-if="member.permissions.owner" class="owner-member">Owner</span>
									<!-- <button v-else type="button" class="remove-member" @click="removeMember(member)" :disabled="!member.id">Remove</button> -->
									<DropdownMenu v-else :is-fixed="true" class="rigid" :button-class="'toolbar-button'">
										<template v-slot:buttonText>
											<span v-if="member.permissions.edit">Editor</span>
											<span v-else>Viewer</span>
										</template>
										<ul class="menu-list">
											<li>
												<div class="menu-action" @click="changeMemberPermissions(member, false)">
													<span class="menu-icon"><i v-if="!member.permissions.edit" class="fas fa-check"></i></span>
													<span class="menu-label">Viewer</span>
												</div>
											</li>
											<li>
												<div class="menu-action" @click="changeMemberPermissions(member, true)">
													<span class="menu-icon"><i v-if="member.permissions.edit" class="fas fa-check"></i></span>
													<span class="menu-label">Editor</span>
												</div>
											</li>
											<li>
												<hr class="menu-separator">
											</li>
											<li>
												<div class="menu-action" @click="removeMember(member)">
													<span class="menu-icon"></span>
													<span class="menu-label">Remove</span>
												</div>
											</li>
										</ul>
									</DropdownMenu>
								</span>
							</li>
						</ul>
					</div>
				</div>

				<div class="modal-footer">
					<button @click="close" type="button" class="button action-close">
						Done
					</button>
				</div>

			</div>
		</div>
	</transition>
</template>

<script>
import clipboard from '@/modules/clipboard';
import DropdownMenu from '@/components/DropdownMenu.vue';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '@/store/rootStore';
import { createModalMask, removeModalMask } from '@/modules/modalMask';

function memberSort(a, b) {
	if (a == null || b == null) {
		return (a == null && b == null) ? 0 : a == null ? 1 : -1;
	}
	var aOwn = (a.permissions != null && a.permissions.owner === true);
	var bOwn = (b.permissions != null && b.permissions.owner === true);
	if (aOwn) {
		return (bOwn) ? 0 : -1;
	}
	if (bOwn) {
		return 1;
	}
	var aName = (a.username || '');
	var bName = (b.username || '');
	if (!aName || !bName) {
		return (aName) ? -1 : (bName) ? 1 : 0;
	}
	return (aName === bName) ? 0 : (aName > bName) ? 1 : -1;
}

export default {
	name: 'MembersModal',

	components: {
		DropdownMenu
	},

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& value.item != null
					&& typeof value.item.id === 'string'
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	data () {
		return {
			loadingMembers: true,
			loadingError: null,

			addingMembers: false,
			addMembersError: null,

			members: [],
			dItem: this.context?.item || null,
			shareUrlCopied: false,
			shareIdCopied: false,
			permissions: {
				read: true,
				edit: true
			},

			newUsernames: '',
			newUsernameInvalid: false,
			invalidUserList: [],

			resolvable: this.context.resolvable || null
		};
	},

	computed: {

		...mapState(useRootStore, {
			canAddMembers: (store) => {
				const dItem = this.dItem;
				if (dItem == null) {
					return false;
				}

				// Check for "edit" permission on dItem
				const perms = (dItem.permissions != null) ? dItem.permissions : null;
				if (!perms.edit) {
					// Edit permission is required to change members
					return false;
				}

				const owner = (dItem.owner != null) ? dItem.owner : null;
				const userId = store && store.userInfo && store.userInfo.id || '';
				if (!owner || !userId) {
					// Unable to determine
					return false;
				}

				const isOwner = (owner.id === userId);
				if (!isOwner) {
					// TODO: determine logic for shared dItem
					// Presently, if a file is shared with you and you have edit access, you can modify members
					return true;
				}

				// sharingType UNLIMITED required for owned files
				const plan = store.userInfo && store.userInfo.plan || {};
				// TODO: check if this is self-owned
				return (plan.sharingType === 'UNLIMITED');

			}

		}),

		shareUrl () {
			var dItem = this.dItem;
			var id = dItem && dItem.id || '';
			if (!id) {
				return '';
			}
			const origin = location.origin || 'https://drive.tekcloud.com';
			const path = '/#/' + (dItem.type === 'FOLDER' ? 'd' : 'f') + '/';
			return origin + path + encodeURIComponent(id);
		},

		maximumMembersError(){
			return this.addMembersError && this.addMembersError.errors && this.addMembersError.errors.find(error => error.keyword === 'maxItems');
		},

		canNotModifyMembersError() {
			return this.addMembersError === 'Cannot modify file members';
		}

	},

	methods: {

		...mapActions(useRootStore, {
			SET_MEMBERS: 'SET_MEMBERS',
			GET_SHARE_MEMBERS: 'GET_SHARE_MEMBERS'
		}),

		close: function () {
			this.resolvable.resolve(this.dItem);
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		setMembers (members) {
			if (!Array.isArray(members) || !members.length) {
				return;
			}
			this.members = members.slice().sort(memberSort);
		},

		addMember (usernames) {
			this.addingMembers = true;
			const usernameList = usernames.split(",").map(item=>item.trim());
			// TODO: determine if users will be read-only before pushing to member list
			const members = this.members.slice();
			for (var index = 0; index < usernameList.length; index++) {
				const username = usernameList[index].toLowerCase();

				// W3C email pattern (https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html)
				const USERNAME_PATTERN = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
				if (!USERNAME_PATTERN.test(username)) {
					// invalid username
					this.newUsernameInvalid = username;
					break;
				}

				this.newUsernameInvalid = false;

				if (members.find((item) => item.username === username)) {
					// username is already a member
					continue;
				}

				members.push({
					username,
					permissions: {
						read: this.permissions.read,
						edit: this.permissions.edit
					}
				});

			};

			if (this.newUsernameInvalid) {
				this.addingMembers = false;
				return;
			}

			this.addMembersError = null;

			this.SET_MEMBERS({
				item: this.dItem,
				members
			})
				.then((updatedMemberList) => {
					// TODO: update item in stack instead of replacing list
					this.setMembers(updatedMemberList);
					this.newUsernames = '';
					this.addingMembers = false;
				})
				.catch(reason => {
					this.addMembersError = reason.message;
					this.addingMembers = false;
				});

		},

		changeMemberPermissions (member, canEdit) {
			// TODO: show item in processing state until updated
			const members = this.members.map(mbr => {
				// if member matches flip edit flag
				if (mbr.id === member.id) {
					mbr.permissions.edit = canEdit;
				};
				return mbr;
			});

			this.members = members;

			this.SET_MEMBERS({
				item: this.dItem,
				members
			})
				.then((updatedMemberList) => {
					this.setMembers(updatedMemberList);
				});
		},

		removeMember (member) {

			// TODO: check if member is the current user. If so, warn first, then close dialog and remove item (or navigate) from item cache.

			// TODO: show item in processing state until removed
			const members = this.members.filter((item) => item !== member);
			this.members = members;

			this.SET_MEMBERS({
				item: this.dItem,
				members
			})
				.then((updatedMemberList) => {
					// TODO: remove item after success
					this.setMembers(updatedMemberList);
				});// TODO: restore item on error
		},

		copyShareUrl () {
			this.shareUrlCopied = clipboard.copyText(this.shareUrl);
		},

		copyShareId () {
			this.shareIdCopied = clipboard.copyText(this.dItem.id);
		},


		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},

	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);

		const dItem = this.dItem;
		this.loadingMembers = true;

		this.GET_SHARE_MEMBERS(dItem)
			.then((members) => {
				this.loadingMembers = false;
				this.members = members;
				this.setMembers(members);
			})
			.catch((reason) => {
				this.loadingMembers = false;
				this.loadingError = reason || new Error('Unknown Error');
			});
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}


};
</script>
