<style scoped>
.app-layout-view {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	height: 100vh;
	width: 100vw;
	min-width: 0;
}
.app-header {
	flex: 0 0 auto;
	width: 250px;
	box-sizing: border-box;
	color: #fff;
	background-color: #293F52;
	padding: 0;
	display: flex;
	flex-direction: column;
}

.app-header.upload-active {
	background-color: #293F52;
	background-image:
		linear-gradient(to top, rgba(41, 63, 82, .7) 0%, rgba(41, 63, 82, 1) 40%),
		url('/images/bg-upload-pattern.svg'),
		url('/images/bg-upload-pattern.svg'),
		url('/images/bg-upload-pattern.svg');
	background-size:
		100% 100%,
		64px 64px,
		128px 128px,
		256px 256px;
	background-position: 0 0, 0 0, 0 0, 0 0;
	animation: bgarrowup 2s linear infinite;
	transition: background-image 3s;
}
@keyframes bgarrowup {
	0% {
		background-position:
			0 0,
			0 0,
			8px 0,
			16px 0,
			0 300px;
	}
	100% {
		background-position:
			0 0,
			0 -64px,
			8px -128px,
			16px -256px;
	}
}

.app-header a {
	color: #fff;
}

.app-brand {
	font-size: 22px;
	font-weight: bold;
	margin: 30px 20px 20px;
	flex: 0 0 auto;
}
.tekcloud-logo {
	vertical-align: -10px;
	margin-right: 8px;
}

.app-nav {
	flex: 0 0 auto;
}

.app-nav-item {
	display: flex;
	flex-direction: row;
	padding: 12px 16px;
	margin: 8px 16px;
	line-height: 1em;
}
.app-nav-item > .drop-target {
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
}

.router-link-exact-active, .active{
	border-radius: .5rem;
    background: #223343;
}
.nav-icon {
	display: block;
	flex: 0 0 auto;
	margin-right: 12px;
}
.icon-recent,
.icon-personal,
.icon-shares,
.icon-trash {
	width: 31px;
}
.nav-title {
	line-height: 1.8em;
}
.nav-content {
	display: block;
	flex: 1 1 auto;
}
.nav-info {
	font-size: 0.8em;
	color: #8EB3D2;
	margin-top: 8px;
}
.progress-bar {
	height: 16px;
	padding: 1px;
	box-sizing: border-box;
	border: 1px solid #8EB3D2;
}
.progress-fill {
	height: 100%;
	background-color: #8EB3D2;
	max-width: 100%;
}
.over-limit {
	border-color: #ff9900;
}
.over-limit .progress-fill {
	background-color: #ff9900;
}
.info-storage {
	display: flex;
	flex-direction: row;
	margin-top: 2px;
}
.info-storage-used {
	flex: 1 1 auto;
}
.info-storage-limit {
	flex: 1 1 auto;
	text-align: right;
}

.app-nav-spacer {
	flex: 1 1 0;
}

.app-actions {
	flex: 0 0 auto;
	padding: 20px;
}

.action-add-files {
	width: 100%;
	box-sizing: border-box;
	padding: 18px;
	background-color: #2E88D6;
	border-radius: 3px;
	border: 0 none;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
	font-size: 1.2em;
}





.app-body {
	flex: 1 1 auto;
	overflow: auto;
}



.icon-upload {
	vertical-align: bottom;
}




.drop-screen {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	background-color: rgba(0,0,0,0.5);
}
.drop-zone-message {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;
	margin: auto;
	background-color: #fff;
	border: 2px solid #ccc;
	border-radius: 0.5em;
	width: fit-content;
	padding: 2em;
	text-align: center;
	box-shadow: 0 1px 3px rgba(221,238,255, .5);
	font-size: 4em;
	height: 1.5em;
	box-sizing: content-box;
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}
.fade-enter-active .drop-zone-message,
.fade-leave-active .drop-zone-message {
	transition: transform .5s, opacity .5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.fade-enter .drop-zone-message {
	transform: translate(0, 100px);
	opacity: 0;
}
.fade-leave-to .drop-zone-message {
	transform: translate(0, -100px);
	opacity: 0;
}





.app-upload-activity {
	position: relative;
}

.upload-progress-bar {
	display: block;
	width: 100%;
	height: 5px;
	position: absolute;
	bottom: 0;
}
.upload-progress-loaded {
	display: block;
	height: 100%;
	width: 0%;
	background-color: #35638b;
	transition: width 200ms linear;
	background-image: linear-gradient(to right, rgba(255,255,255,0) 30%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0) 80%);
	background-size: 600px;
	background-position: 0 0;
	animation: backgroundrollx 1s linear infinite;
}
@keyframes backgroundrollx {
	0% { background-position-x: 600px; }
	100% { background-position-x: 0; }
}

.upload-progress-enter-active {
	transition: opacity 0.5s;
}
.upload-progress-leave-active {
	transition: opacity 1s 1s, background-color 0.5s;
}
.upload-progress-enter,
.upload-progress-leave-to {
	opacity: 0;
}

.uploading-message {
	margin: 8px 22px;
	display: flex;
	flex-direction: row;
	font-size: 0.9em;
	color: #cae7ff;
}
.uploading-label {
	flex: 1 1 auto;
}
.uploading-percent {
	text-align: right;
}

.app-header + .app-body:before {
	background-color: rgba(0,0,0,0);
}

@media only screen and (max-width : 700px) {
	.app-header.open {
		left: 0;
	}

	.app-header.open + .app-body:before {
		width: 100vw;
		height: 100vh;
		content: '';
		z-index: 9;
		background-color: rgba(0,0,0, .4);
		position: absolute;
	}

	.app-header {
		left: -250px;
		overflow: hidden;
		position: fixed;
		z-index: 10;
		height: 100%;
		transition: left .25s ease;
	}
}

</style>

<template>

	<div class="app-layout-view"
		ref="dropzone"
		v-confirm-unload="isUploading">

		<header class="app-header" :class="{'upload-active':isUploading, 'open':leftNavVisible}" ref="header">
			<div class="app-brand">
				<router-link to="/" class="home-link" @click="showLeftNav(false)"><img src="@/assets/tekcloud-nav-logo.svg" class="tekcloud-logo" alt="TekCloud" /> <span class="tekdrive-label">TekDrive</span></router-link>
			</div>
			<nav class="app-nav">
				<router-link to="/recent" class="app-nav-item nav-recent" @click="showLeftNav(false)">
					<div class="nav-icon"><img src="@/assets/icon-recent.svg" alt="recent" class="icon-recent" /></div>
					<div class="nav-content">
						<div class="nav-title">Recent</div>
					</div>
				</router-link>
				<router-link to="/personal" class="app-nav-item nav-personal" @click="showLeftNav(false)" :class="{'active': isChildView && isOwned}">

					<drop tag="div"
						class="drop-target"
						@drop="moveItemsToPersonal($event.data)"
						mode="cut"
						accepts-type="ditems"
						:accepts-data="canAcceptDragItems">

						<div class="nav-icon"><img src="@/assets/icon-personal.svg" alt="My Files" class="icon-personal" /></div>
						<div class="nav-content">
							<div class="nav-title">My Files</div>
							<div class="nav-info">
								<div class="progress-bar"
									:class="{'over-limit':userStats.totalBytesOwned >= userStats.storageSizeLimit}"><div class="progress-fill" :style="getStorageProgressStyle(userStats)"></div></div>
								<div class="info-storage">
									<span class="info-storage-used" title="Storage Used">{{ formatBytes(userStats.totalBytesOwned) }}</span>
									<span class="info-storage-limit" title="Storage Limit">{{ formatBytes(userStats.storageSizeLimit) }}</span>
								</div>
							</div>
						</div>

					</drop>
				</router-link>
				<router-link to="/shares" class="app-nav-item nav-shares" @click="showLeftNav(false)" :class="{'active': isChildView && !isOwned}">
					<div class="nav-icon"><img src="@/assets/icon-shares.svg" alt="Shared With Me" class="icon-shares" /></div>
					<div class="nav-content">
						<div class="nav-title">Shared With Me</div>
						<div class="nav-info" v-if="sharesCount === 1">1 Share</div>
						<div class="nav-info" v-else-if="sharesCount > 0">{{ sharesCount }} Shares</div>
					</div>
				</router-link>
				<router-link to="/trash" class="app-nav-item nav-trash" @click="showLeftNav(false)">
					<div class="nav-icon"><img src="@/assets/icon-trash.svg" alt="Trash Bin" class="icon-trash" /></div>
					<div class="nav-content">
						<div class="nav-title">Trash Bin</div>
					</div>
				</router-link>
			</nav>
			<div class="app-nav-spacer storage"></div>
			<transition name="upload-progress">
				<div class="app-upload-activity" v-if="isUploading" title="Upload in progress">
					<div class="uploading-message">
						<span v-if="activeUploadTasks.length === 1" class="uploading-label">Uploading 1 File</span>
						<span v-else class="uploading-label">Uploading {{ activeUploadTasks.length }} Files</span>
						<span class="uploading-percent">{{ formatPercent(newFilePercent) }}</span>
					</div>
					<div class="upload-progress-bar">
						<div class="upload-progress-loaded" :style="getUploadProgressStyle(newFilePercent)"></div>
					</div>
				</div>
			</transition>
			<div class="app-actions" v-if="hasPersonalStorage">
				<PickFileButton class="action-add-files" @select="addFiles" multiple="true">
					<img src="@/assets/icon-upload.svg" class="icon-upload" />
					Add Files
				</PickFileButton>
			</div>
		</header>

		<router-view class="app-body"/>

		<transition name="fade">
			<div v-if="isActiveDropZone" class="drop-screen" title="Drop file to upload">
				<div class="drop-zone-message"><i class="fa fa-file-upload"></i> Drop Files</div>
			</div>
		</transition>

		<transition name="toast">
			<div v-if="isUploadPanelVisible" class="upload-panel">
				<header>
					File Uploads
					<button type="button" @click="closeUploadPanel()">Close</button>
				</header>
				<ul v-if="uploadList && uploadList.length > 0">
					<li v-for="item in uploadList" :key="item.id">{{ item }}</li>
				</ul>
				<div v-else>
					Nothing to see here.
				</div>
			</div>
		</transition>

		<MembersModal v-if="memberEditContext" :context="memberEditContext" />
		<PermanentlyDeleteConfirmModal v-if="permanentlyDeleteConfirmContext" :context="permanentlyDeleteConfirmContext" />
		<DeleteConfirmModal v-if="deleteConfirmContext" :context="deleteConfirmContext" />
		<RemoveShareConfirmModal v-if="removeShareConfirmContext" :context="removeShareConfirmContext " />
		<EmptyTrashConfirmModal v-if="emptyTrashConfirmContext" :context="emptyTrashConfirmContext" />
		<LaunchConfirmModal v-if="launchConfirmContext" :context="launchConfirmContext" />
		<UploadLimitModal v-if="uploadLimitContext" :context="uploadLimitContext" />
		<UploadRefusedModal v-if="uploadRefusedContext" :context="uploadRefusedContext" />
		<CreateFolderRefusedModal v-if="createFolderRefusedContext" :context="createFolderRefusedContext" />

	</div>

</template>

<script>
import { Drop } from "vue-easy-dnd";
import { byteFormatFilter, percentFilter } from '@/modules/filters.js';
import PickFileButton from '@/components/PickFileButton.vue';

import MembersModal from '@/components/MembersModal.vue';
import EmptyTrashConfirmModal from '@/components/EmptyTrashConfirmModal.vue';
import DeleteConfirmModal from '@/components/DeleteConfirmModal.vue';
import RemoveShareConfirmModal from '@/components/RemoveShareConfirmModal.vue';
import PermanentlyDeleteConfirmModal from '@/components/PermanentlyDeleteConfirmModal.vue';
import LaunchConfirmModal from '@/components/LaunchConfirmModal.vue';
import UploadLimitModal from '@/components/UploadLimitModal.vue';
import UploadRefusedModal from '@/components/UploadRefusedModal.vue';
import CreateFolderRefusedModal from '@/components/CreateFolderRefusedModal.vue';

import { createFileDropZone, removeFileDropZone } from '@/modules/file-drop.js';
import { useRootStore } from '../store/rootStore';
import { useUploadStore } from '../store/uploadStore';
import { useNavStore } from '../store/navigationStore';
import { mapState, mapActions } from 'pinia';
import { createPopUpListener, removePopUpListener } from '@/modules/popup-menu';

export default {
	name: 'AppLayoutView',

	components: {
		PickFileButton,
		MembersModal,
		EmptyTrashConfirmModal,
		DeleteConfirmModal,
		RemoveShareConfirmModal,
		PermanentlyDeleteConfirmModal,
		LaunchConfirmModal,
		UploadLimitModal,
		UploadRefusedModal,
		CreateFolderRefusedModal,
		Drop,
	},

	data() {
		return {
			isActiveDropZone: false,
			isUploadPanelVisible: false,
			store: null,
		};
	},

	computed: {
		...mapState(useRootStore, {
			userStats: 'userStats',
			viewItem: (store) => {
				const route = store.router && store.router.currentRoute && store.router.currentRoute.value;
				const id = route && route.params.fileId || route && route.params.folderId;
				return store.list.find(item => item.id === id);
			},
			hasPersonalStorage: (store) => store.userStats.storageSizeLimit > 0,
			memberEditContext: 'memberEditContext',
			emptyTrashConfirmContext: 'emptyTrashConfirmContext',
			permanentlyDeleteConfirmContext: 'permanentlyDeleteConfirmContext',
			deleteConfirmContext: 'deleteConfirmContext',
			removeShareConfirmContext: 'removeShareConfirmContext',
			launchConfirmContext: 'launchConfirmContext',
			uploadLimitContext: 'uploadLimitContext',
			uploadRefusedContext: 'uploadRefusedContext',
			createFolderRefusedContext: 'createFolderRefusedContext',
			userInfo: 'userInfo',
			sharesCount: (store) => {
				const sharesId = store.silos.SHARES && store.silos.SHARES.id || 'UNKNOWN';
				return store.list.reduce((count, dItem) => {
					return count + (dItem && dItem.parentFolderId === sharesId ? 1 : 0);
				}, 0);
			},
		}),

		...mapState(useUploadStore, {
			isUploading: 'isUploading',
			uploadList: 'uploadList',
			// uploadProgressPercent: 'uploadProgressPercent',
			activeUploadTasks: (store) => store.uploadList.filter((task) => task.active),
			failedUploads: 'failedUploads',
			uploadError: 'uploadError',
			newFilePercent: 'newFilePercent'
		}),

		...mapState(useNavStore, {
			leftNavVisible: 'leftNavVisible'
		}),

		uploadError() {
			return this.uploadList.some(task => task.uploadError);
		},

		isChildView () {
			return this.viewItem && this.viewItem.parentFolderId !== null;
		},

		isOwned () {
			return this.viewItem && this.isOwnedItem(this.viewItem, this.userInfo);
		},

	},

	methods: {

		...mapActions(useRootStore, {
			updateUserStats: 'UPDATE_USER_STATS',
			getInitialDirectory: 'GET_INITIAL_DIRECTORY',
			MOVE_ITEMS_TO_PERSONAL: 'MOVE_ITEMS_TO_PERSONAL',
			CREATE_FOLDER: 'CREATE_FOLDER',
		}),

		...mapActions(useUploadStore, ['UPLOAD_FILES']),

		...mapActions(useNavStore, ['showLeftNav']),

		formatPercent(value) {
			return percentFilter(value);
		},
		formatBytes(value) {
			return byteFormatFilter(value);
		},
		getUploadProgressStyle (percent) {
			if (!isFinite(percent)) {
				return null;
			}
			return {
				width: (percent * 100).toFixed(2) + '%'
			};
		},

		isOwnedItem (item, userInfo) {
			const owner = (item != null && item.owner != null) ? item.owner : null;
			const userId = userInfo && userInfo.id;
			if (!owner || !userId) {
				return false;
			}
			return owner.id === userId;
		},

		canAcceptDragItems (dItems) {

			if (!Array.isArray(dItems) || !dItems.length) {
				return false;
			}
			if (this.userStats.storageSizeLimit === 0) {
				// requires storage limit to drop on "My Files"
				return false;
			}
			const userId = this.userInfo.id;
			return dItems.every((item) => item.owner != null && item.owner.id === userId);
		},

		moveItemsToPersonal (dItems) {
			this.MOVE_ITEMS_TO_PERSONAL(dItems);
		},

		onFileEnter: function() {
			this.isActiveDropZone = true;
		},

		onFileLeave: function() {
			this.isActiveDropZone = false;
		},

		onFileDrop: function(files) {
			this.isActiveDropZone = false;
			this.addFiles(files);
		},

		addFiles(entryContent) {
			const parentFolderId = this.$route.params.folderId;
			var configs = Array.prototype.map.call(entryContent, (file) => {
				return file ? {
					file: file,
					parentFolderId: parentFolderId,
					name: file.uploadName || file.name || file.fileName || '',
				} : null;
			}).filter(Boolean);

			this.UPLOAD_FILES(configs)
				.catch((reason) => {
					// TODO: handle response that include upload warning different than other warning.
					if (reason && reason.response) {
						console.error('upload forbidden:', reason.response.status, reason.response);
					}
				});
		},

		showUploadPanel () {
			//this.isUploadPanelVisible = true;
			console.log('TODO: show upload panel');
		},

		hideUploadPanel () {
			this.isUploadPanelVisible = false;
		},

		getStorageProgressStyle (stats) {
			const total = stats && +stats.storageSizeLimit || 0;
			if (stats == null || !total) {
				return null;
			}
			const used = stats.totalBytesOwned || 0;
			return {
				width: ((used / total) * 100).toFixed(2) + '%',
				minWidth: (used > 0) ? '1px' : '0'
			};
		},
		initFileDrop(dropzone, fns) {
			return createFileDropZone(dropzone, fns);
		},
		destroyFileDrop(dropzone, fns) {
			return removeFileDropZone(dropzone, fns);
		},

		initPopUpListener(element, fn) {
			return createPopUpListener(element, fn);
		},

		destroyPopUpListener(element, fn) {
			return removePopUpListener(element, fn);
		}

	},
	beforeRouteUpdate (to, from, next) {
		Promise.all([
			this.updateUserStats(),
			this.getInitialDirectory()
		])
			.then(() => {
				next();
			})
			.catch((err) => {
				// TODO: Show catastrophic error message
				console.log('Error:', err);
				next('/account-error');
			});
	},

	mounted() {

		this.initFileDrop(this.$refs.dropzone, {
			onFileEnter: this.onFileEnter,
			onFileLeave: this.onFileLeave,
			onFileDrop: this.onFileDrop
		});

		this.initPopUpListener(this.$refs.header, this.showLeftNav);
	},
	beforeUnmount() {
		this.destroyFileDrop(this.$refs.dropzone, {
			onFileEnter: this.onFileEnter,
			onFileLeave: this.onFileLeave,
			onFileDrop: this.onFileDrop
		});
		this.destroyPopUpListener(this.$refs.header, this.showLeftNav);
	}

};
</script>
