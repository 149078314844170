
function addGoogleTagManager(gtmKey) {
	if (!gtmKey) {
		return;
	}
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({ 'gtm.start': new Date().getTime(), event:'gtm.js' });
	var f = document.getElementsByTagName('script')[0],
		j = document.createElement('script');
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmKey;
	f.parentNode.insertBefore(j, f);
};

function addGoogleTagManagerWithConsent(gtmKey) {
	if (!gtmKey) {
		return;
	}
	function addSnippet() {
		addGoogleTagManager(gtmKey);
	}

	if (window.cookieconsent) {
		window.cookieconsent.initialise({
			container: document.body,
			revokable: true,
			law: {regionalLaw: false},
			location: true,
			cookie:{ domain: document.domain.replace(/.+(?=(\.\w+){2})/,'')},
			palette:{ popup: {background: "#000"},button: {background: "#f1d600"}},
			theme: "edgeless",
			position: "bottom",
			content:{
				message: "This website uses cookies to understand how product features are used, report technical errors, and evaluate the quality of our customer engagement. \nThis information is not recorded until you accept this notice, which you may later revoke by clearing your browser's cookies.",
				dismiss: "Accept",
				policy: ""
			},
			location: {
				serviceDefinitions: {
					isauth: function () {
						return {
							url: 'https://api.init.st/auth/ip',
							callback: function(done, response) {
								try {
									var data = JSON.parse(response);
									if (!data || !data.country_code) {
										throw new Error('Invalid Response');
									}
									return { code:data.country_code };
								} catch (err) {
									throw new Error('Invalid Response');
								}
							}
						};
					}
				},
				services:['isauth']
			},
			onInitialise: function() {
				if(this.hasConsented()){
					addSnippet();
				}
			},
			onStatusChange: function() {
				if(this.hasConsented()){
					addSnippet();
				}
			},
			onNoCookieLaw: addSnippet,
			addSnippet: function () {
				if (!this.hasSnippet) {
					this.hasSnippet = true;
					addSnippet();
				}
			}
		});
	} else {
		addSnippet();
	}

};

export default {
	addGoogleTagManager,
	addGoogleTagManagerWithConsent
};
