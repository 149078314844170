<style scoped>
.content-header {
	flex: 0 0 auto;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}
.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
}
.content-toolbar {
	flex: 0 0 auto;
	padding: 0 30px;
	font-size: 0.9em;
	margin-bottom: 4px;
	min-height: 40px;
}
.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px;
}












.access-keys-list-section {
	margin-top: 1em;
}
.access-keys-list-section .empty-state {
	color: #777;
	color: var(--access-keys-list-empty-state-color);
}

.input-label {
	display: block;
	margin-bottom: 0.25em;
}

.access-key-name-input {
	width: 400px;
}
.new-access-key-header{
	font-weight: bold;
	margin: 1em 0 .5em;
}
.claim-options-list {
	list-style: none;
	margin: 0.5em 0;
	padding: 0.25em;
	border: 1px solid #eee;
	border-radius: 3px;
	font-size: 0.9em;
}
.claim-option {
	margin: 0;
	padding: 0.4em;
	display: flex;
	flex-direction: row;
}
.claim-options-header {
	margin: 1em 0 0.25em;
}
.claim-option-label {
	cursor: pointer;
	width: 280px;
	display: inline-block;
	vertical-align: text-bottom;
	flex: 0 0 auto;
}
.access-key-claim-code {
	border: 0 none;
}
.access-key-claim-description {
	color: #777;
	flex: 1 1 auto;
}
.access-key-token {
	display: block;
	font-size: 1.5em;
}
.input-tip {
	font-size: 0.9em;
	color: #777;
}
.access-key-form-footer {
	margin-top: 2em;
}
.label-note {
	color: #777;
}
.invalid input[type=text],
.invalid.claim-options-list {
	border-color: #c00;
}
.invalid-message {
	color: #c00;
}
.generate-key {
	margin-right: 1em;
	font-size: 1.1em;
	vertical-align: baseline;
	font-weight: normal;
	padding: 0.5em 1em;
}
.cancel-key {
	line-height: 2em;
}

.access-key-token-section {
	margin-bottom: 2em;
}

.section-title {
	font-weight: bold;
}
.access-key-token-header {
	display: flex;
	align-items: flex-end;
}
.access-key-token-header .section-title {
	flex: 1 1 auto;
}
.access-key-token-header .tools {
	flex: 0 0 auto;
}
.access-key-token-warning {
	display: block;
	border: 1px solid #FEEFCD;
	border: 1px solid var(--warning-border-color);
	border-radius: 3px;
	background: #FFF9EB;
	background-color: var(--warning-background-color);
	color: #75550B;
	color: var(--warning-color);
	padding: .5em;
	margin-top: 0.5em;
}
.access-key-token {
	padding: .5em;
	margin-bottom: 0.5em;
	border: 1px dashed #ddd;
	border: 1px dashed var(--code-border-color);
	border-radius: 3px;
	background-color: #f2f2f2;
	background-color: var(--code-background-color);
	font-family: monospace;
	user-select: all;
}

.property-pair {
	display: flex;
	flex-direction: row;
	padding: 0.5em;
}
.property-pair > .property {
	flex: 0 0 auto;
	width: 100px;
	text-align: right;
	color: #777;
	margin-right: 0.8em;
}
.property-pair > .value {
	flex: 1 1 auto;
}
.value.claims-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.download-token {
	margin-right: .5em;
}
</style>

<template>
	<div class="access-keys-create-view">

		<form v-if="!createdAccessKey" class="new-access-key-form" @submit.prevent="commitAccessKeyGeneration(name, claims)" novalidate="true">


			<header class="content-header">
				<div class="location-bar">
					<div class="breadcrumbs">
						<span><router-link to="/settings/accesskeys"><i class="fa fa-arrow-circle-left"></i> Settings</router-link></span>
					</div>
					<div class="user-menu">
						<UserMenu />
					</div>
				</div>
				<div class="title-bar">
					<div class="location-title">Create New Access Key</div>
				</div>
			</header>

			<div class="content-section">
				<div class="content-view">


					<div class="new-access-key-name">
						<label :class="{ invalid:formErrors.name.required }">
							<span class="input-label">Name</span>
							<input type="text" class="access-key-name-input" v-model.trim="name" />
						</label>
						<div v-if="formErrors.name.required" class="invalid-message">The access key name cannot be blank.</div>
						<div class="access-key-name-tip input-tip">Tip: Pick a name that describes where/how this access key is used.</div>
					</div>

					<div class="claim-options">
						<div class="claim-options-header">Key Claims <span class="label-note">(Select one or more)</span></div>
						<ul class="claim-options-list" :class="{ invalid:formErrors.claims.minLength }">
							<li v-for="item in claimList" :key="item.claim" class="claim-option">
								<label class="claim-option-label">
									<input type="checkbox" v-model="claims" :value="item.claim" />
									<code class="access-key-claim-code">{{ item.claim }}</code>
								</label>
								<span class="access-key-claim-description">{{ item.description }}</span>
							</li>
						</ul>
						<div v-if="formErrors.claims.minLength" class="invalid-message">At least one claim must be selected</div>
						<div class="claim-options-tip input-tip">Tip: Select only the claims you need for this access key.</div>
					</div>

					<div v-if="commitError === 'ACCESS_KEY_LIMIT_REACHED'" class="invalid-message limit-reached">This account's access key limit has been reached.</div>
					<div v-else-if="commitError" class="invalid-message">An unexpected error occured while generating an access key.</div>

					<footer class="access-key-form-footer">
						<button type="submit" class="button primary-action generate-key" :disabled="committing">Generate Key</button>
						<router-link to="/settings/accesskeys" class="secondary-action cancel-key">Cancel</router-link>
					</footer>


				</div>
			</div>

		</form>
		<div v-else class="new-access-key-receipt">

			<header class="content-header">
				<div class="location-bar">
					<div class="breadcrumbs">
						<span><router-link to="/settings/accesskeys"><i class="fa fa-arrow-circle-left"></i> Settings</router-link></span>
					</div>
					<div class="user-menu">
						<UserMenu />
					</div>
				</div>
				<div class="title-bar">
					<div class="location-title">New Access Key Created</div>
				</div>
			</header>

			<div class="content-section">
				<div class="content-view">


					<div class="access-key-token-section">
						<div class="access-key-token-header">
							<span class="section-title">Access Key Token</span>
							<span class="tools"><button type="button" class="toolbar-button download-token" @click="saveTokenAsFile(createdAccessKey.key)">
								<i class="fa fa-cloud-download"></i>
								Save as File
							</button><button type="button" class="toolbar-button copy-token" @click="copyAccessKeyToken(createdAccessKey.key)" :class="{copied:tokenCopied}">
								<i class="fa" :class="{'fa-clipboard':!tokenCopied,'fa-clipboard-check':tokenCopied}"></i>
								Copy to Clipboard
							</button></span>
						</div>
						<p class="access-key-token allow-context">{{ createdAccessKey.key }}</p>
						<div class="access-key-token-warning">
							<i class="fa fa-exclamation-triangle"></i>
							Make a copy of the above token now. It will never be shown again!
						</div>
					</div>

					<div class="access-key-info">
						<p class="access-key-info-header"><span class="section-title">Access Key Details</span></p>

						<div class="property-list">
							<div class="property-pair access-key-name">
								<span class="property">Name</span>
								<span class="value">{{ createdAccessKey.name }}</span>
							</div>
							<div class="property-pair access-key-claims">
								<span class="property">Claims</span>
								<ul class="value claims-list" v-if="createdAccessKey.claims.length > 0">
									<li v-for="item in createdAccessKey.claims" :key="item">{{ item }}</li>
								</ul>
								<span v-else class="empty-list">This access key has no claims.</span>
							</div>
						</div>

					</div>

					<footer class="access-key-form-footer">
						<router-link to="/settings/accesskeys" class="button action-leave">Return to settings</router-link>
					</footer>


				</div>
			</div>

		</div>


	</div>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue';
import clipboard from '@/modules/clipboard';
import FileSaver from 'file-saver';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';

export default {
	name: 'AccessKeyCreateView',

	components:{
		UserMenu
	},

	data () {
		return {

			name: '',
			claims: [],

			committing: false,

			formErrors: {
				invalid: false,
				count: 0,
				name: {
					required: false
				},
				claims: {
					minLength: false
				}
			},
			commitError: null,

			createdAccessKey: null,
			tokenCopied: false

		};
	},

	computed: {
		...mapState(useRootStore, {
			claimList: (store) => store.accessKeyClaimOptions || []
		})

	},

	methods: {
		...mapActions(useRootStore, {
			CREATE_ACCESS_KEY: 'CREATE_ACCESS_KEY'
		}),

		validateFields() {
			const name = this.name;
			const claims = this.claims;

			var count = 0;

			// validate name
			const nameErrors = {
				required: false
			};

			if (typeof name !== 'string' || name === '') {
				count += 1;
				nameErrors.required = true;
			}

			// validate claims
			const claimErrors = {
				minLength: false
			};

			if (!Array.isArray(claims) || !claims.length) {
				count += 1;
				claimErrors.minLength = true;
			}

			this.formErrors = {
				invalid: count > 0,
				count,
				name: nameErrors,
				claims: claimErrors
			};
		},

		commitAccessKeyGeneration(name, claims) {
			// validate & show form errors
			this.validateFields();
			if (this.formErrors.invalid) {
				return;
			}
			this.commitError = null;
			this.committing = true;
			return this.CREATE_ACCESS_KEY({ name, claims })
				.then((result) => {
					// create temporary view of data
					this.createdAccessKey = {
						id: result.accessKey.id,
						key: result.key,
						name: result.accessKey.name,
						claims: result.accessKey.claims,
						createdAt: result.accessKey.createdAt
					};
					this.committing = false;
				})
				.catch((reason) => {
					this.commitError = reason && reason.errorCode || reason || 'UNKNOWN_ERROR';
					this.committing = false;
				});
		},

		cancelAccessKeyGeneration() {
			// navigate
			this.$router.push('/accesskeys');
		},

		copyAccessKeyToken(textToCopy) {
			this.tokenCopied = clipboard.copyText(textToCopy);
		},

		saveTokenAsFile(token) {
			if (typeof token !== 'string') {
				token = this.createdAccessKey && this.createdAccessKey.key || '';
			}
			const filename = (this.createdAccessKey && this.createdAccessKey.name || 'access-key') + '.txt';
			return FileSaver.saveAs(
				new Blob([token], { type: 'text/plain;charset=utf-8' }),
				filename
			);

		}

	}

};
</script>
