<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 400px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

h3 {
	margin-top: 0;
}

.modal-footer {
	margin-top: 2em;
	text-align: right;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}

</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">
			<div class="modal-container">
				<h3>Folder Cannot Be Created</h3>
				<p v-if="reason">You have reached the nested folder limit.</p>
				<p v-else>A folder cannot be created at this time.</p>
				<footer class="modal-footer">
					<button type="button" class="button" @click="close()">Ok</button>
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
import { createModalMask, removeModalMask } from '@/modules/modalMask';

export default {
	name: 'CreateFolderRefusedModal',

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& value.reason !== 'string'
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	directives: {

		modalMask: {

			bind: function (el, binding, node, oldNode) {
				function onPress(event) {
					if (event.target === el) {
						node.context.$emit('directclick', event);
					}
				}
				el.addEventListener('click', onPress, false);
			}

		}

	},

	data () {
		return {
			updating: false,

			reason: this.context.reason || '',

			resolvable: this.context.resolvable || null
		};
	},

	methods: {

		close () {
			this.resolvable.reject(this.items);
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		cancel () {
			this.close();
		},

		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},


	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}


};
</script>
