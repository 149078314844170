<style scoped>
/* header */
.content-header {
	flex: 0 0 auto;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}

.location {
	position: relative;
}

.location .fa-caret-down{
	position: absolute;
    content: "\f0d7";
    bottom: 2px;
    right: 0px;
    height: 20px;
    width: 20px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}
.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
}
.content-toolbar {
	flex: 0 0 auto;
	padding: 0 30px;
	font-size: 0.9em;
	margin-bottom: 4px;
	min-height: 40px;
}
.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px;
}

.tooltip {
	justify-content: space-between;
}

.events {
	padding: 1em 0;
}

/* webhook form */

.create-webhook {
	display: flex;
	flex-direction: column;
}

.form-input {
	padding: .5em 0;
	max-width: 400px;
}

.err {
	border: 1px solid #750E0B;
	border: 1px solid var(--error-border-color);
	color: #750E0B;
	color: var(--error-color);
}

.errlabel {
	color: #750E0b;
	color: var(--error-color);
}

.select-input {
	height: 28.4px
}

.location-container {
	justify-content: space-between;
	align-items: center;
}

#location {
	caret-color: transparent;
}

.location {
	width: 100px;
}

.depth {
	width: 50px;
}

.event {
	margin-bottom: 1em;
}

.radio {
	padding: 1em 2.5em .5em 0;
}

.radio label {
	padding-left: .5em;
}

.checkbox {
	padding: 1em 1em .5em 0;
}

.checkbox label {
	text-align: center;
	padding-left: .5em;
}


.generate-webhook {
	margin-right: 1em;
	font-size: 1.1em;
	vertical-align: baseline;
	font-weight: normal;
	padding: 0.5em 1em;
}

.menu-action {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.notice {
    margin-top: 1rem;
    padding: .5em 0 .5em 1em;
	max-width: 400px;
}

.notice.error {
	border: 1px solid #FCD1CF;
	border: 1px solid var(--error-border-color);
	background-color: #FFECEB;
	background-color: var(--error-background-color);
	color: #750E0B;
	color: var(--error-color);
}

.input-tip {
	padding-left: .5em;
	font-size: 0.9em;
	color: #777;
}

#item-id:disabled {
	border: none;
}

@media only screen and (max-width: 410px) {
	.location-container {
		flex-direction: column;
		align-items: start;
	}
	.location {
		width: 100%;
		padding: 0.5em 0;

	}
	.id {
		width: 100%;
		padding: 0.5em 0;
	}
	.depth {
		width: 100%;
		padding: 0.5em 0;
	}
	.event-radio {
		display: flex;
		flex-direction: column;
		align-items: start;
	}
}
</style>

<template>
	<section>
		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs">
					<span><router-link to="/settings/webhooks"><i class="fa fa-arrow-circle-left"></i> Settings</router-link></span>
				</div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<div class="location-title">Create New Webhook</div>
			</div>
		</header>

		<div class="content-section">
			<div class="content-view">
				<form class="create-webhook" @submit.prevent="onSubmit">
					<div class="form-input column">
						<label for="name">Name</label>
						<input type="text" id="name" v-model="name">
					</div>
					<div class="form-input column">
						<label for="url" :class="{'errlabel' : error.type === 'NO_URL' }">URL</label>
						<input type="text" id="url" autofocus v-model.trim="url" :class="{'err' : error.type === 'NO_URL' }">
					</div>
					<div class="column secret form-input">
						<div class="row centering">
							<label for="secret">Secret</label>
							<aside class="input-tip">(Optional) Ensures requests to URL are from TekDrive</aside>
						</div>
						<input type="text" id="secret" v-model="secret">
					</div>
					<div class="row form-input location-container">
						<div class="location column">
							<label for="location">Location</label>
							<select v-model="location" class="select-input">
								<option value="all" class="menu-action">All</option>
								<option value="folder">Folder</option>
								<option value="file">File</option>
							</select>
						</div>
						<div class="id column">
							<div class="row tooltip">
								<label for="item-id" :class="{'errlabel' : error.type === 'NO_ID' }">ID</label>
							</div>
							<input type="text" id="item-id" v-model.trim="itemId" :disabled="location === 'all'" :class="{'err' : error.type === 'NO_ID' }">
						</div>
						<div class="depth column">
							<label for="depth" :class="{'errlabel' : error.type === 'NO_FOLDER_DEPTH' }">Depth</label>
							<input type="number" id="depth" v-model.number="depth" step="1" :disabled="location === 'all' || location === 'file'" :class="{'err' : error.type === 'NO_FOLDER_DEPTH' }">
						</div>
					</div>
					<div class="events">
						<label>Events</label>
						<div class="row centering event-radio">
							<div class="radio">
								<input type="radio" id="individual-events" value="false" v-model="sendAllEvents" :checked="sendAllEvents === 'false'">
								<label for="individual-events">Select Individual Events</label>
							</div>
							<div class="radio">
								<input type="radio" id="all-events" value="true" v-model="sendAllEvents" :checked="sendAllEvents === 'true'">
								<label for="all-events">All Events</label>
							</div>
						</div>
						<div class="row flowing" :class="{'err' : error.type === 'NO_EVENTS' && sendAllEvents === 'false' && !events.length }">
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="file-created" value="FileCreated" v-model="events" :disabled="location === 'file'"/>
									<label for="file-created">File Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-trashed" value="FileTrashed" v-model="events"/>
									<label for="file-trashed">File Trashed</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-deleted" value="FileDeleted" v-model="events"/>
									<label for="file-deleted">File Deleted</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="file-restored" value="FileRestored" v-model="events"/>
									<label for="file-restored">File Restored</label>
								</div>
							</div>
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="file-meta-updated" value="FileMetaUpdated" v-model="events"/>
									<label for="file-meta-updated">File Meta Updated</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="thumbnail-created" value="ThumbnailCreated" v-model="events"/>
									<label for="thumbnail-created">Thumbnail Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-created" value="FolderCreated" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-created">Folder Created</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-trashed" value="FolderTrashed" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-trashed">Folder Trashed</label>
								</div>
							</div>
							<div class="event column">
								<div class="checkbox">
									<input type="checkbox" id="folder-deleted" value="FolderDeleted" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-deleted">Folder Deleted</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-restored" value="FolderRestored" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-restored">Folder Restored</label>
								</div>
								<div class="checkbox">
									<input type="checkbox" id="folder-meta-updated" value="FolderMetaUpdated" v-model="events" :disabled="location === 'file'"/>
									<label for="folder-meta-updated">Folder Meta Updated</label>
								</div>
							</div>
						</div>
					</div>
					<div class="row centering">
						<button class="button generate-webhook" :disabled="submitting">Create Webhook</button>
						<router-link to="/settings/webhooks">Cancel</router-link>
					</div>
				</form>

				<div class="notice error" v-if="error.type">
					{{ error.msg }}
				</div>
			</div>

		</div>


	</section>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue';
import driveService from '../modules/drive-service';
import router from '../router';

export default {
	components: {
		UserMenu
	},
	data () {
		return {
			location: 'all',
			url: '',
			secret: null,
			name: null,
			itemId: null,
			depth: 1,
			events: [],

			sendAllEvents: 'false',
			submitting: false,

			error: {
				type: '',
				msg: ''
			}
		};
	},

	watch: {
		events(newList) {
			if (newList.length >= 11) {
				this.sendAllEvents = 'true';
			}
			else {
				this.sendAllEvents = 'false';
			}
		},
		sendAllEvents(newBool) {
			const allEvents = ["FileCreated","FileTrashed","FileDeleted","FileRestored","FolderTrashed","FolderCreated","ThumbnailCreated","FileMetaUpdated","FolderDeleted","FolderRestored","FolderMetaUpdated"];
			if (newBool === 'true') {
				this.events = allEvents;
			}
			if (newBool === 'false' && this.events.length === 11) {
				this.events = [];
			}
		}
	},


	methods: {

		formValidation() {
			const fileEvents = this.events.filter(e => !e.includes('Folder') && !e.includes('FileCreated'));
			this.error = {
				type: '',
				msg: ''
			};
			if (!this.url.length) {
				this.error.type = 'NO_URL';
				this.error.msg = 'Please enter a URL for your webhook';

			}
			if (this.location === 'file' && !this.itemId || this.location === 'folder' && !this.itemId) {
				this.error.type = 'NO_ID';
				this.error.msg = `Please enter a ${this.location} id.`;
			}

			if (this.location === 'folder' && (Number.isNaN(this.depth) || this.depth <= 0)) {
				this.error.type = 'NO_FOLDER_DEPTH';
				this.error.msg = 'Please enter a valid number for folder depth';
			}

			if (this.location === 'file' && !fileEvents.length) {
				this.error.type = 'NO_EVENTS';
				this.error.msg = 'Please select events for your webhook';
			}

			if (this.sendAllEvents === 'false' && !this.events.length) {
				this.error.type = 'NO_EVENTS';
				this.error.msg = 'Please select events for your webhook';
			}

			return;
		},

		modelFormData() {
			const webhook = {
				httpMethod: 'POST',
				url: this.url,
				config: {
					events: this.sendAllEvents === 'true' ? "*" : this.events
				}
			};

			if (this.name) {
				webhook.name = this.name;
			}

			if (this.secret) {
				webhook.secret = this.secret;
			}

			if (this.location === 'all') {
				webhook.config.folderId = "*";
			}

			if (this.location === 'folder') {
				webhook.config.folderId = this.itemId;
				webhook.config.depth = this.depth;
			}

			if (this.location === 'file') {
				webhook.config.fileId = this.itemId;
			}

			if (this.location === 'file' && this.sendAllEvents === 'false') {
				webhook.config.events = this.sendAllEvents === 'false' ? this.events.filter((e) => !e.includes('Folder') && !e.includes('FileCreated')) : '*';
			}

			return webhook;
		},

		handleErrorResponse(reason) {
			//TODO: Match access key error handling with arrays
			this.submitting = false;
			//Quick fix to handle some error message inconsistency
			if (!Array.isArray(reason)) {
				reason = [reason];
			}
			if (Array.isArray(reason)) {
				reason.find(err => {
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('url')) {
						this.error.type = 'NO_URL';
						this.error.msg = 'Please enter a valid URL.';
					}
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('fileId')) {
						this.error.type = 'NO_ID';
						this.error.msg = 'File ID not found.';
					}
					if (err.error === 'FILE_NOT_FOUND') {
						this.error.type = 'NO_ID';
						this.error.msg = 'File ID not found.';
					}
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('folderId')) {
						this.error.type = 'NO_ID';
						this.error.msg = 'Folder ID not found.';
					}
					if (err.error === 'FOLDER_NOT_FOUND') {
						this.error.type = 'NO_ID';
						this.error.msg = 'FOLDER ID not found.';
					}
					if (err.error === 'MALFORMED_INPUT' && err.dataPath.includes('depth')) {
						this.error.type = 'NO_FOLDER_DEPTH';
						this.error.msg = 'Please enter a number for depth.';
					}
				});
			} else {
				this.error.type = reason.error;
				this.error.msg = reason.message;
			}
		},

		onSubmit() {
			this.submitting = true;
			this.formValidation();
			if (this.error.type) {
				this.submitting = false;
				return;
			}
			const webhook = this.modelFormData();
			//TODO: use store
			return driveService.createWebhook(webhook)
				.then(() => {
					router.push({path: '/settings/webhooks?q=success'});
				}, (this.handleErrorResponse));
		}

	}
};
</script>
