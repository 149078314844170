import { defineStore } from 'pinia';

export const useNavStore = defineStore('navStore', {
	state: () => ({
		leftNavVisible: false,
	}),

	actions: {
		showLeftNav(bool) {
			this.leftNavVisible = bool;
		}
	}
});
