<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.empty-state {
	margin: 3em 0;
	text-align: center;
	color: #bbb;
	color: var(--empty-state-color);
	padding: 2em;
}
.empty-state-header {
	font-size: 2em;
}



.content-header {
	flex: 0 0 auto;
}
.content-toolbar {
	min-height: 40px;
	flex: 0 0 auto;
	padding: 0 20px;
	font-size: 0.9em;
	margin-bottom: 4px;
	display: flex;
	justify-content: space-between;
}


.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
}

@media only screen and (max-width : 500px) {
	.hide-500 {
		display: none;
	}
	.empty-state-header {
		font-size: 1.5em;
	}
}








.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
	opacity: var(--menu-separator-opacity);
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}

</style>

<template>
	<div class="drive-personal-view">


		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs"></div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />

				<div class="location-title">Trash Bin</div>

				<SearchBar class="location-search" folder-id="TRASH" />

			</div>
		</header>
		<div class="content-section">
			<div class="content-toolbar">
				<span v-if="!selection.length">
					<button class="toolbar-button" type="button" @click="restoreAll()" :disabled="!list.length"><img src="@/assets/icon-restore.svg" class="icon-download-file" /> Restore All</button>
					<button class="toolbar-button" type="button" @click="deleteAll()" :disabled="!list.length"><img src="@/assets/icon-delete-file.svg" class="icon-download-file" /> Empty Trash</button>
				</span>
				<span v-if="selection.length > 0">
					<button class="toolbar-button" type="button" @click="restoreItems(selection)"><img src="@/assets/icon-restore.svg" class="icon-download-file" /> Restore</button>
					<button class="toolbar-button" type="button" @click="deleteItems(selection)"><img src="@/assets/icon-delete-file.svg" class="icon-download-file" /> Delete Permanently</button>
				</span>

				<div>
					<button v-if="childViewStyle === 'list'" @click="setSelectedView('tile')" class="toolbar-button tile-view" type="button"><img src="@/assets/icon-list-view.svg" alt="List View"></button>
					<button v-else-if="childViewStyle === 'tile'" @click="setSelectedView('list')" class="toolbar-button toolbar-button-tile tile-view" type="button"><img src="@/assets/icon-tile-view.svg" alt="Tile View"></button>
				</div>
			</div>
			<div class="content-view">
				<div v-if="!list || !list.length" class="empty-state">
					<p class="empty-state-header"><i class="fa fa-broom"></i> Your Trash Bin Is Empty</p>
					<p class="empty-state-tip">
						You can recover removed files here.
					</p>
				</div>
				<TileView v-else-if="childViewStyle === 'tile'" :list="list" columns="size,trashed" ref="listView" v-model:selection="selection" :allow-drag="false" @contextmenu.prevent="onContextMenu" :allow-navigation="false" />
				<ListView v-else :list="list" columns="size,trashed" ref="listView" v-model:selection="selection" :allow-drag="false" @contextmenu.prevent="onContextMenu" :allow-navigation="false" />
			</div>
		</div>

		<context-menu ref="selectionContextMenu" v-slot="{context}">
			<ul class="menu-list" v-if="context && context.data && context.data.length > 0">
				<li>
					<div class="menu-action" @click="restoreItems(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Restore</span>
					</div>
				</li>
				<li>
					<hr class="menu-separator">
				</li>
				<li>
					<div class="menu-action" @click="deleteItems(context.data)">
						<span class="menu-icon"></span>
						<span class="menu-label">Delete Permanently</span>
					</div>
				</li>
			</ul>
		</context-menu>

	</div>

</template>

<script>
import ContextMenu from '@/components/ContextMenu.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListView from '@/components/ListView.vue';
import TileView from '@/components/TileView.vue';
import UserMenu from '@/components/UserMenu.vue';
import NavButton from '@/components/NavButton.vue';
import { useRootStore } from '../store/rootStore';
import { mapActions, mapState } from 'pinia';

export default {
	name: 'TrashBinView',

	components: {
		SearchBar,
		ListView,
		TileView,
		UserMenu,
		NavButton,
		ContextMenu
	},

	data: () => {
		return {
			loadError: null,
			editContext: null,
			selection: [],
			paging: {}
		};
	},

	computed: {

		...mapState(useRootStore, {
			childViewStyle: 'childViewStyle',
			list: 'trashList'
		})

	},

	methods: {
		...mapActions(useRootStore, {
			RESTORE_ITEMS: 'RESTORE_ITEMS',
			CONFIRM_EMPTY_TRASH: 'CONFIRM_EMPTY_TRASH',
			RESTORE_ITEMS: 'RESTORE_ITEMS',
			CONFIRM_PERMANENTLY_DELETE_ITEMS: 'CONFIRM_PERMANENTLY_DELETE_ITEMS',
			GET_TRASH_LIST: 'GET_TRASH_LIST',
			SET_CHILD_VIEW_STYLE: 'SET_CHILD_VIEW_STYLE'
		}),

		initErrors(loadError, data) {
			if (loadError) {
				this.loadError = loadError;
				return;
			}
			this.loadError = null;
			// TODO: handle paging
			this.paging = data && data.meta || {};
		},


		restoreAll() {
			this.updating = true;
			const items = this.list.slice();
			this.RESTORE_ITEMS(items)
				.then(() => {
					this.updating = false;
					this.selection = [];
				})
				.catch((reason) => {
					this.updating = false;
					this.updateError = reason;
					console.error('Restore all failed');
				});
		},

		deleteAll() {
			this.updating = true;
			this.CONFIRM_EMPTY_TRASH()
				.then(() => {
					this.updating = false;
					this.selection = [];
				})
				.catch(() => {
					this.updating = false;
				});
		},

		restoreItems(selection) {
			this.updating = true;
			const items = Array.isArray(selection) ? selection.slice() : [];
			this.RESTORE_ITEMS(items)
				.then(() => {
					this.updating = false;
					this.selection = [];
				})
				.catch((reason) => {
					this.updating = false;
					this.updateError = reason;
					console.error('Items restore failed');
				});
		},

		deleteItems(selection) {
			this.updating = true;
			const items = Array.isArray(selection) ? selection.slice() : [];
			this.CONFIRM_PERMANENTLY_DELETE_ITEMS(items)
				.then(() => {
					this.updating = false;
					this.selection = [];
				})
				.catch(() => {
					this.updating = false;
				});
		},


		onContextMenu(event) {
			if (!event) {
				return;
			}
			this.$refs.selectionContextMenu.open(event, {
				data: this.selection,
				close: () => {
					this.$refs.selectionContextMenu.close();
				}
			});
		},

		setSelectedView(view) {
			this.SET_CHILD_VIEW_STYLE(view);
		}

	},

	beforeRouteEnter (to, from, next) {
		// NOTE: this component may not be created, either defer this.$store reference or use global reference (i.e., import)
		// TODO: ensure this doesn't circumvent route auth verification

		const store = useRootStore();

		store.GET_TRASH_LIST()
			.then((meta) => {
				next((vm) => vm.initErrors(null, meta));
			})
			.catch((err) => {
				next((vm) => vm.initErrors(err, null));
			});
	},

	beforeRouteUpdate (to, from, next) {
		const vm = this;
		vm.loadError = null;
		vm.paging = {};
		vm.selection = [];

		this.GET_TRASH_LIST()
			.then((meta) => {
				vm.initErrors(null, meta);
				next();
			})
			.catch((err) => {
				vm.initErrors(err, null);
				next();
			});
	}

};
</script>
