<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 400px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

h3 {
	margin-top: 0;
}

.modal-footer {
	margin-top: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}

</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">
			<div class="modal-container">
				<h3>Unable To Upload Files</h3>
				<p>You must increase your storage limit before uploading any more files.</p>
				<footer class="modal-footer">
					<a :href="subscriptionsUrl" target="tekdcloudaccount">Increase Storage Limit</a>
					<button type="button" class="button" @click="close()">Ok</button>
				</footer>
			</div>
		</div>
	</transition>
</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import { mapState } from 'pinia';
import { useAuthStore } from '../store/auth';
import { useRootStore } from '../store/rootStore';
import { createModalMask, removeModalMask } from '@/modules/modalMask';

export default {
	name: 'UploadRefusedModal',

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& Array.isArray(value.items)
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	data () {
		return {
			resolvable: this.context && this.context.resolvable || null
		};
	},

	computed: {
		...mapState(useRootStore, {
			// TODO: get the target/resolved folder ID upload was intended for. Determine reason for refusal.
			// Refusal reasons: no personal space, exceeded system limit, does not have edit permission to folder, folder not foun

			hasPersonalStorage (store) {
				return store.userStats.storageSizeLimit > 0;
			},

		}),
		...mapState(useAuthStore, {
			subscriptionsUrl: (store) => {
				const token = store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			},
		}),
	},

	methods: {

		close () {
			this.resolvable.reject(this.items);
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		cancel () {
			this.close();
		},

		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},

	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}

};
</script>
