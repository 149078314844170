<style scoped>
.section-header {
  padding: 0 30px;
}

.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px 12px 0;
}

.content-toolbar {
	flex: 0 0 auto;
	font-size: 0.9em;
	margin-bottom: 4px;
	min-height: 40px;
}

.empty-state {
	color: #777;
	color: var(--access-keys-list-empty-state-color);
	padding: 30px;
	font-size: 1.4em;
}

.webhooks-list {
	list-style: none;
	border: 1px solid #ddd;
	border: 1px solid var(--table-border-color);
	border-radius: 3px;
	margin: 0;
	padding: 0;
}
.webhooks-list > li {
	padding: 0;
}
.webhooks-list > li + li {
	border-top: 1px solid #ddd;
	border-top: 1px solid var(--table-border-color);
}

.webhook {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.webhook-type {
	display:inline-block;
	width: 2em;
	text-align: center;
}
.icon-key-generic {
	vertical-align: middle;
}

.webhook-name {
	font-weight: bold;
	max-width: 300px;
	padding-left: 1em;
}

.trailing-off {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.webhook-claims {
	color: #999;
}

.item-field {
	padding: 0.75em 1em;
	line-height: 1.8em;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
}

.secured-webhook {
	padding: 0 .25em;
}

.item-tools {
	padding: 0.5em;
	flex: 0 0 auto;
}

.actions {
	display:inline-block;
	margin-left: 1em;
}

.action-revoke {
	background-color: #b22;
	color: #fff;
	border-radius: 3px;;
}

.edit {
	border-radius: 3px;
    border: 1px solid #ccc;
	border: 1px solid var(--table-border-color);
}

a .button {
	color: #333;
	color: var(--body-color);
}

@media only screen and (max-width: 1030px) {
	.webhook-name {
		max-width: 200px;
	}
}

@media only screen and (max-width: 895px) {
	.date {
		display: none;
	}
}

@media only screen and (max-width: 871px) {
	.webhook {
		flex-direction: column;
	}

	.actions {
		margin: 0 .5em;
	}
	.webhook-name {
		padding: 0;
	}
}

@media only screen and (max-width: 395px) {
	.item-field {
		flex-direction: column;
	}
	.webhook-name {
		max-width: 150px;
	}
}
</style>

<template>
	<section>
		<div class="section-header">
			<h2>Webhooks</h2>
			<p>Webhooks can be used to POST data to a URL from TekDrive events</p>
			<div class="content-toolbar">
				<router-link to="/webhooks/new" class="button" v-if="!webhookLimit">Create Webhook</router-link>
			</div>
			<div class="content-view">
				<div v-if="!webhookList || !webhookList.length" class="empty-state">
					No active webhooks...
				</div>
				<ul v-else class="webhooks-list">
					<li v-for="item in webhookList" :key="item.id" class="webhook">
						<div class="item-field">
							<span class="webhook-type"
							><img
								src="@/assets/icon-webhook.svg"
								class="icon-key-generic"
								draggable="false"
							/></span>
							<div class="webhook-name row">
								<div class="trailing-off" v-if="item.name">{{ item.name }}</div>
								<div class="trailing-off" v-else>{{item.url}}</div>
								<div v-if="item.hasSecret" class="secured-webhook"><img src="../../assets/icon-secure-webhook.svg" alt="padlock" title="This webhook is secured with a secret key"/></div>
							</div>
						</div>
						<div class="item-tools row centering">
							<span class="date">{{ formatDate(item.updatedAt) }}</span>
							<span class="actions"><router-link :to="{ path: '/webhooks/edit', query: {id: item.id}}"><button type="button" class="toolbar-button button edit">Edit</button></router-link></span>
							<span class="actions"><button type="button" class="toolbar-button button action-revoke" @click="removeWebhook(item)">Remove</button></span>
						</div>
					</li>

				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import { dateFormatFilter } from '@/modules/filters';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../../store/rootStore';

export default {
	name: 'WebhooksList',

	data() {
		return {
		};
	},
	computed: {
		...mapState(useRootStore, {
			webhookList: 'webhookList',
			webhookLimit: (store) => store.webhookList >= 20,
		})
	},
	methods: {
		...mapActions(useRootStore, {
			CONFIRM_DELETE_ITEMS: 'CONFIRM_DELETE_ITEMS',
			LIST_WEBHOOKS: 'LIST_WEBHOOKS'
		}),
		formatDate(value, format) {
			return dateFormatFilter(value, format);
		},
		removeWebhook(items) {
			if (!Array.isArray(items) || !items.length) {
				items = [items];
			}

			this.CONFIRM_DELETE_ITEMS(items)
				.catch((reason) => {
					console.log('Deletion Cancelled');
				});
		},

	},
	mounted() {
		this.LIST_WEBHOOKS();
	},

};

</script>
