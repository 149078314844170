<style scoped>
button {
	justify-content: center;
}

button img {
	height: 24px;
	width: 24px;
}

</style>

<template>
	<div>
		<button
			v-if="childViewStyle === 'list'"
			@click="setSelectedView('tile')"
			class="row centering toolbar-button"
			type="button"
		>
			<img
				src="@/assets/icon-tile-view.svg"
				alt="Switch to Tile View"
				title="Switch to Tile View"
			/>
		</button>
		<button
			v-else-if="childViewStyle === 'tile'"
			@click="setSelectedView('list')"
			class="row centering toolbar-button"
			type="button"
		>
			<img
				src="@/assets/icon-list-view.svg"
				alt="Switch to List View"
				title="Switch to List View"
			/>
		</button>
	</div>
</template>

<script>
import { useRootStore } from '../store/rootStore';
import { mapActions, mapState } from 'pinia';

export default {
	computed: {
		...mapState(useRootStore, {
			childViewStyle: 'childViewStyle'
		}),
	},
	methods: {
		...mapActions(useRootStore, {
			SET_CHILD_VIEW_STYLE: 'SET_CHILD_VIEW_STYLE'
		}),
		setSelectedView(view) {
			this.SET_CHILD_VIEW_STYLE(view);
		},
	},
};
</script>
