<style scoped>

.section-header {
  padding: 0 30px;
}

.content-toolbar {
	flex: 0 0 auto;
	padding: 0 30px;
	font-size: 0.9em;
	margin-bottom: 4px;
	min-height: 40px;
}
.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px;
}

.empty-state {
	color: #777;
    color: var(--access-keys-list-empty-state-color);
	padding: 30px;
	font-size: 1.4em;
}

.access-key.highlight {
	background-color: #ffd;
}

.access-key-list {
	list-style: none;
	border: 1px solid #ddd;
	border: 1px solid var(--table-border-color);
	border-radius: 3px;
	margin: 0;
	padding: 0;
}
.access-key-list > li {
	padding: 0;
}
.access-key-list > li + li {
	border-top: 1px solid #ddd;
	border-top: 1px solid var(--table-border-color);
}
.access-key {
	display: flex;
	flex-direction: row;
}
.item-field {
	padding: 0.75em 1em;
	line-height: 1.8em;
	flex: 1 1 auto;
}
.access-key-type {
	display:inline-block;
	width: 2em;
	text-align: center;
}
.icon-key-device,
.icon-key-generic {
	vertical-align: middle;
}
.access-key-name {
	font-weight: bold;
}
.item-tools {
	padding: 0.5em;
	flex: 0 0 auto;
}
.access-key-claims {
	color: #999;
}
.actions {
	display:inline-block;
	margin-left: 1em;
}
.limit-reached {
	color: #999;
}

.action-revoke {
	background-color: #b22;
	color: #fff;
	border-radius: 3px;
	border: none;

}

.removed {
  display: none;
}


</style>

<template>
	<div>
		<div class="section-header">
			<h2>Access Keys Management</h2>
			<p>
				Access keys can be used instead of login credentials for
				devices/software to interact with your files.
			</p>
		</div>

		<div class="content-toolbar" :class="{ removed: list.length >= accessKeyLimit }">
			<router-link v-if="list.length < accessKeyLimit" to="/accesskeys/new" class="button create-access-key">Generate An Access Key</router-link>
		</div>
		<div class="content-view">
			<div v-if="!list || !list.length" class="empty-state">
				<i class="fa fa-lock"></i>
				No Active Access Keys
			</div>
			<ul v-else class="access-key-list">
				<li v-for="item in list" :key="item.id" class="access-key" :class="{ highlight: highlightId && item.id === highlightId }">
					<div class="item-field">
						<span class="access-key-type" v-if="item.keyType === 'SHORTCODE'"><img src="@/assets/icon-device.svg" class="icon-key-device" draggable="false"
						/></span>
						<span class="access-key-type" v-else><img src="@/assets/icon-key.svg" class="icon-key-generic" draggable="false"
						/></span>
						<span class="access-key-name">{{ item.name }}</span>
						<span class="access-key-claims"
						>&ndash; {{ formatClaims(item.claims) }}</span
						>
					</div>
					<div class="item-tools">
						<span class="access-key-date">{{ formatDate(item.updatedAt) }}</span>
						<span class="actions" ><button type="button" class="button action-revoke" @click="revokeAccessKey(item)">Revoke</button></span>
					</div>
				</li>
			</ul>
		</div>
	</div>

</template>

<script>
import { dateFormatFilter } from '@/modules/filters';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../../store/rootStore';

export default {
	name: 'AccessKeyListView',

	data () {
		return {
			highlightId: null
		};
	},

	computed: {

		...mapState(useRootStore, {
			list (store) {
				const keys = Array.isArray(store.accessKeys) ? store.accessKeys.slice() : [];

				// Sort by updated date
				return keys.sort((a, b) => {
					if (a == null || b == null) {
						return (a == null) ? (b == null) ? 0 : 1 : -1;
					}
					return	(a.updatedAt === b.updatedAt) ? 0:
						(a.updatedAt > b.updatedAt) ? -1 : 1;
				});
			},

			claimList (store) {
				// TODO: move this to a constant (module)
				return store.accessKeyClaimOptions || [];
			},

			accessKeyLimit (store) {
				const plan = store.userInfo && store.userInfo.plan || {};
				if (!plan || !isFinite(plan.accessKeyLimit)) {
					// Unknown Limit
					return Infinity;
				}
				return +plan.accessKeyLimit;
			}

		})

	},

	methods: {
		...mapActions(useRootStore, {
			REVOKE_ACCESS_KEY: 'REVOKE_ACCESS_KEY'
		}),
		formatDate(value, format) {
			return dateFormatFilter(value, format);
		},
		formatClaim(value) {
			switch (value) {
			case 'drive:api:directory:create':    return 'Create';
			case 'drive:api:directory:read':      return 'List';
			case 'drive:api:directory:update':    return 'Update';
			case 'drive:api:directory:delete':    return 'Delete';
			case 'drive:api:user:details:self':   return 'Account Info';
			case 'drive:api:user:analytics:self': return 'Storage Info';
			}
			return value || '';
		},
		formatClaims: function (value) {
			if (Array.isArray(value)) {
				return value.map(this.formatClaim).join(', ');
			}
			if (typeof value === 'string') {
				return this.formatClaim(value);
			}
			return value || '';
		},
		revokeAccessKey (accessKey) {
			return this.REVOKE_ACCESS_KEY(accessKey.id);
		}

	},

};
</script>
