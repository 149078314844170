<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.empty-state {
	margin: 3em 0;
	text-align: center;
	color: #bbb;
	color: var(--empty-state-color);
	padding: 2em;
}
.empty-state-header {
	font-size: 2em;
}







.drive-personal-view {
	display: flex;
	flex-direction: column;
}
.content-header {
	flex: 0 0 auto;
}



.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}

.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
	width: 0;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}

.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	text-overflow: ellipsis;
	overflow-wrap: anywhere;
}

.trailing-off {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}



.item-name-block {
	display: inline-block;
	position: relative;
	height: 1.4em;
	max-width: fit-content;
	flex: 1 1 auto;
}
.item-name-block.editing {
	overflow: visible;
}
.editing .item-name {
	visibility: hidden;
}
.editing .item-name{
	white-space: normal;
}
.item-name {
	height: 1.4em;
}
.item-name-editor {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	height: 1.4em;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 -4px;
	padding: 0;
	min-width: 1em;
	border: 1px solid transparent;
}
.item-name-editor-input {
	font-size: 1em;
	border: 1px solid #999;
	border: 1px solid var(--item-name-editor-input-border-color);
	border-radius: 0;
	padding: 0 2px;
	width: calc(100% + .5rem);
	margin: -8px 0;
	max-width: 100vw;
	height: 100%;
}
.location-title .item-name-block {
	width: 1em;
}


.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
	min-width: 120px;
	margin-left: 1em;
}



.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
	opacity: var(--menu-separator-opacity)
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}





.content-toolbar {
	min-height: 40px;
}
.content-toolbar-layout {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
}
.content-toolbar {
	flex: 0 0 auto;
	padding: 0 20px;
	font-size: 0.9em;
	margin-bottom: 4px;
}
.content-view {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.drop-in {
	outline: 1px dashed green;
}
.drop-allowed {
	outline: 1px dashed rgba(0,0,0,.1);
}
.drop-forbidden {
	opacity: 0.8;
}

.hidebanner {
  display: none;
}

.notice-bar {
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error {
	color: #750E0B;
	background: #FFECEB;
	border: 1px solid #FCD1CF;
	border-radius: 3px;
	background-color: var(--error-background-color);
	border: 1px solid var(--error-border-color);
	color: var(--error-color);
}

.error .exit-banner {
 color:#ec5b7a;
 color: var(--error-color)
}

.banner-content {
  padding: 0.25em 2em;
}

.banner-content p {
  padding-left: 1em;
}

.banner-action {
  background: #fcfcfc;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  /* color: #75550b; */
  text-align: center;
  padding: 0.5em 0.75em;
  min-width: 140px;
}

.notice-bar .button {
	background: var(--notice-button-background-color);
	border-color: var(--notice-button-border-color);
}

.exit-banner {
	cursor: pointer;
	padding: 0 2em 0 1em;
}
@media only screen and (max-width: 550px) {
  .banner-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .banner-action {
    display: none;
  }
  .banner-content {
    padding-right: 0;
  }
  .exit-banner {
    padding-left: 0;
  }
}


@media only screen and (max-width : 500px) {
	.hide-500 {
		display: none;
	}
}


</style>

<template>
	<main v-if="loadError">
		<div class="folder-missing">
			Folder Not Found.
		</div>
	</main>
	<main class="drive-personal-view" v-else-if="folder">

		<div
			v-if="failedUploadBanner"
			class="notice-bar error">
			<div class="banner-content row centering">
				<i class="fas fa-exclamation-circle fa-lg"></i>
				<!-- Test text wrapping -->
				<p>
					There was an error uploading <span v-if="failedUploads.length > 1">these files:</span> <span v-else>this file:</span> <span v-for="file,index in failedUploads" :key="index">{{ file.name }}<span v-if="failedUploads.length > 1 && file !== failedUploads.at(-1)">, </span></span>.
					You can delete it, or leave the corrupted file in TekDrive.
				</p>
			</div>
			<div class="banner-actions row centering">
				<button to="/settings" class="banner-action button" @click="uploadStore.DELETE_FAILED_UPLOADS">Delete File<span v-if="failedUploads.length > 1">s</span></button>
				<a class="exit-banner fa fa-times" @click="clearFailedUploads()"></a>
			</div>
		</div>

		<header class="content-header">

			<StorageNoticeBar v-if="isOwnedFolder" />

			<div class="location-bar row">
				<Breadcrumbs v-on:move="onMoveItems" :item="folder" :selection="selection" />
				<div class="user-menu rigid">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />
				<div class="location-title" v-if="folder.folderType === 'PERSONAL'">My Files</div>
				<div class="location-title" v-else-if="folder.folderType === 'SHARES'">Shared <span class="hide-500">With Me</span></div>
				<div class="location-title row flexible separated centering" v-else>
					<span class="item-name-block row flexible trailing-off" :class="{'editing':editContext}">
						<span class="item-name" :title="(editContext && editContext.item === folder ? editContext.value : folder.name)">{{ (editContext && editContext.item === folder ? editContext.value : folder.name) }}</span>
						<span class="item-name-editor" v-if="editContext && editContext.item === folder">
							<input ref="folderName" v-autofocus v-rename-input class="item-name-editor-input" type="text" v-model="editContext.value" @blur="editContext && confirmFolderRename(editContext)" @keypress.enter="confirmFolderRename(editContext)" @keydown.esc="cancelFolderRename(editContext)" />
						</span>
					</span>
					<DropdownMenu v-if="canEdit(folder)" class="rigid">
						<ul class="menu-list">
							<li>
								<div class="menu-action" @click="startFolderRename(folder)">
									<span class="menu-icon"></span>
									<span class="menu-label">Rename</span>
								</div>
							</li>
							<li>
								<div class="menu-action" @click="editItemMembers(folder)">
									<span class="menu-icon"></span>
									<span class="menu-label">Share</span>
								</div>
							</li>
							<li>
								<hr class="menu-separator">
							</li>
							<li>
								<div class="menu-action" @click="deleteFolder(folder)">
									<span class="menu-icon"></span>
									<span class="menu-label">Remove</span>
								</div>
							</li>
						</ul>
					</DropdownMenu>
				</div>
				<div class="gap"></div>
				<SearchBar class="location-search rigid" :folder-id="folder.id" :key="'search_' + folder.id" />

			</div>
		</header>
		<div class="content-section">
			<div class="content-toolbar content-toolbar-layout grid-header">
				<div>
					<PickFileButton v-if="canEdit(folder)" class="toolbar-button" @select="uploadToFolder(folder.id, $event)" multiple="true"><img src="@/assets/icon-toolbar-upload.svg" class="icon-toolbar-upload" /> Add Files</PickFileButton>
					<button v-if="canEdit(folder)" class="toolbar-button" type="button" @click="createFolder(folder.id)"><img src="@/assets/icon-create-folder.svg" class="icon-create-folder" /> Create Folder</button>

					<transition-group name="toolbar-transition">
						<button key="toolbarDownload" v-if="canDownload" class="toolbar-button" type="button" @click="downloadFiles(selection)"><img src="@/assets/icon-download-file.svg" class="icon-download-file" /> Download</button>
						<button key="toolbarRename" v-if="selection.length === 1 && canEditItems(selection)" class="toolbar-button" type="button" @click="startItemRename(selection[0])"><img src="@/assets/icon-rename.svg" class="icon-rename" /> Rename</button>
						<button key="toolbarShare" v-if="selection.length === 1" class="toolbar-button" type="button" @click="editItemMembers(selection[0])"><img src="@/assets/icon-share.svg" class="icon-share" /> Share</button>
						<button key="toolbarDelete" v-if="selection.length > 0 && canEditItems(selection)" class="toolbar-button" type="button" @click="deleteItems(selection)"><img src="@/assets/icon-delete-file.svg" class="icon-delete-file" /> Remove</button>
					</transition-group>
				</div>
				<div>
					<ToggleViewButton />
				</div>
			</div>
			<div class="content-view">
				<div v-if="!filteredList || !filteredList.length" class="empty-state">
					<p class="empty-state-header"><i class="fa fa-user-astronaut"></i> Empty File Space</p>
					<p v-if="canAddFiles" class="empty-state-tip">
						Add files by <span  v-if="canFileDrop" class="can-file-drop">dropping them here or</span> pressing the "Add Files" button.
					</p>
				</div>
				<TileView v-else-if="childViewStyle === 'tile'" :list="filteredList" ref="listView" v-model:selection="selection" />
				<ListView v-else :list="filteredList" ref="listView" v-model:selection="selection"  />
			</div>
		</div>


	</main>
	<main v-else>
		<p class="loading-message">
			Loading&hellip;
		</p>
	</main>

</template>

<script setup>
// import { Drag, Drop } from "vue-easy-dnd";
import UserMenu from '@/components/UserMenu.vue';
import PickFileButton from '@/components/PickFileButton.vue';
import StorageNoticeBar from '@/components/StorageNoticeBar.vue';
import DropdownMenu from '@/components/DropdownMenu.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import NavButton from '@/components/NavButton.vue';
import SearchBar from '@/components/SearchBar.vue';
import ListView from '@/components/ListView.vue';
import TileView from '@/components/TileView.vue';
import ToggleViewButton from '@/components/ToggleViewButton.vue';
import { promiser } from '@/modules/promise-util';
import driveService from '@/modules/drive-service';
import { storeToRefs } from 'pinia';
import { useRootStore } from '../store/rootStore';
import router from '../router';

import { ref, computed } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import { useUploadStore } from '../store/uploadStore';

const rootStore = useRootStore();
const uploadStore = useUploadStore();

const props = defineProps({
	folderId: String
});

const parentFolder = ref(null);
const loadError = ref(null);
const editContext = ref(null);
const lastSelected = ref(null);
const selection = ref([]);

const listView = ref(null);
const folderName = ref(null);
const selectionContextMenu = ref(null);

const { userInfo, list, folders, childViewStyle } = storeToRefs(rootStore);
const { failedUploads, failedUploadBanner } = storeToRefs(uploadStore);


const filteredList = computed(() => {

	const folderId = rootStore.router.currentRoute.value.params.folderId || props.folderId;

	if (!folderId) {
		console.error('Unable to find folder ID');
		return [];
	}

	const shares = list.value.filter((item) => {
		// Should this validate ownership as well?
		return item != null
				&& item.parentFolderId === folderId;
	}).filter(Boolean);

	return shares.sort((a, b) => {
		if (a == null || b == null) {
			return	(a == null && b == null) ? 0 :
				(b == null) ? 1 : -1;
		}
		if (a.type !== b.type) {
			// Folders first
			return (a.type === 'FOLDER') ? -1 : 1;
		}
		const aname = (typeof a.name === 'string') ? a.name.toUpperCase() : '';
		const bname = (typeof b.name === 'string') ? b.name.toUpperCase() : '';
		return	(aname > bname) ? 0 :
			(aname > bname) ? 1 : -1;
	});
});

const folder = computed(() => list.value.find((item) => item.id === props.folderId));

const userId = computed(() => {
	return userInfo.value && userInfo.value.id;
});

const isOwnedFolder = computed(() => {
	const owner = (folder.value != null && folder.value.owner != null) ? folder.value.owner : null;
	// const userId = rootStore.userInfo && rootStore.userInfo.id;
	if (!owner || userId.value) {
		return false;
	}
	return owner.id === userId.value;
});

const parentFolderName = computed(() => {
	return parentFolder.value && parentFolder.value.name;
});

const canAddFiles = computed(() => {
	return folder.value != null
		&& folder.value.permissions != null
		&& folder.value.permissions.edit === true;
});

const canFileDrop = computed(() => {
	// Note: this is may provide a false-positive since DragAndDrop API is stubbed in incompatible browsers
	return ('ondrop' in document.documentElement && window.FileReader);
});

const canDownload = computed(() => {
	return (selection.value.length === 1 && selection.value[0].type === 'FILE');
});

function clearFailedUploads() {
	uploadStore.CLEAR_FAILED_UPLOADS();
};

function setLoadError(err) {
	loadError.value = err;
};

function onMoveItems(){
	selection.value = [];
};

function canDownloadItems(items) {
	return Array.isArray(items)
		&& items.some((item) => item != null && item.type === 'FILE');
};

function uploadToFolder (folderId, files) {
	if (!files || !files.length) {
		return;
	}

	const parentFolderId = rootStore.router.currentRoute.value.params.folderId;

	var configs = Array.prototype.map.call(files, (file) => {
		return file ? {
			file: file,
			parentFolderId: parentFolderId,
			name: file.uploadName || file.name || file.fileName || '',
		} : null;
	}).filter(Boolean);

	uploadStore.UPLOAD_FILES(configs);
};

function createFolder(parentFolderId) {
	// TODO: generate unique folder name in directory

	rootStore.CREATE_FOLDER({
		name: 'New Folder',
		parentFolderId: parentFolderId
	})
		.then((folder) => {
			//Add to selection list, child views will see selection update, remove current selection and select new folder
			selection.value = [folder];
			startItemRename(folder);
		});
};

function downloadFiles (items) {
	const files = (!Array.isArray(items) || !items.length) ? []:
		items.filter((item) => item.type === 'FILE');
	if (!files.length) {
		return;
	}

	files.forEach((file)=>{
		driveService.downloadFile(file.id);
	});

};

function openItem(item) {
	if (!item || !item.id) {
		return;
	}
	if (item.type === 'FOLDER') {
		router.push('/d/' + encodeURIComponent(item.id));
	} else {
		router.push('/f/' + encodeURIComponent(item.id));
	}
};

function canEdit(item) {
	if (!item || !item.id) {
		return false;
	}
	return item.permissions.edit;
};

function canEditItems(items) {
	return Array.isArray(items)
		&& items.every((item) => item.permissions.edit);
};

function shareItems(items) {
	if (!Array.isArray(items) || !items.length) {
		return;
	}
	console.error('Not Yet Implemented');
};

function deleteFolder (item) {
	if (!item) {
		return;
	}

	rootStore.CONFIRM_DELETE_ITEMS([item])
		.then(() => {
			// after delete, send to parent folder
			router.replace('/d/' + encodeURIComponent(item.parentFolderId));
		})
		.catch((reason) => {
			console.log('Deletion Cancelled');
		});

};

function deleteItems (items) {
	if (!Array.isArray(items) || !items.length) {
		return;
	}

	rootStore.CONFIRM_DELETE_ITEMS(items)
		.then(() => {
			// reset selection and edit context
			selection.value = [];
			editContext.value = null;
		})
		.catch((reason) => {
			console.log('Deletion Cancelled');
		});

};

function onContextMenu(event) {
	if (!event) {
		return;
	}
	selectionContextMenu.value.open(event, {
		data: selection.value,
		close: () => {
			selectionContextMenu.value.close();
		}
	});
};

function startItemRename (item) {
	return listView.value.startItemRename(item);
};

function startFolderRename(item) {
	//confirmItemRename doesnt exist?
	// if (editContext.value) {
	// 	// clear edit context
	// 	confirmItemRename(editContext.value);
	// }

	if (item) {

		const resolveable = promiser();

		editContext.value = {
			item: item,
			value: item.name,
			originalValue: item.name,
			promise: resolveable.promise,
			commit: (value) => {
				rootStore.SET_ITEM_NAME({ item, name: editContext.value.value });
				editContext.value = null;
				resolveable.resolve(value);
			},
			cancel: (reason) => {
				editContext.value = null;
				resolveable.reject(reason);
			}
		};

		// return new edit promise
		return editContext.value.promise;
	}

	// no new edit context, resolve as non-edit
	return Promise.resolve(null);
};

function confirmFolderRename(editContext, name) {
	if (editContext && typeof editContext.commit === 'function') {
		editContext.commit(name);
	}
};

function editItemMembers (item) {
	rootStore.EDIT_SHARE_MEMBERS(item);
};


onBeforeRouteUpdate((to, from, next) => {
	loadError.value = null;
	editContext.value = null;
	selection.value = [];

	var folderId = to.params.folderId;
	if (typeof folderId !== 'string' || folderId === '') {
		// TODO: service should look up the personal root ID
		folderId = 'PERSONAL';
	}
	const rootStore = useRootStore();

	rootStore.GET_FOLDER_NODE(folderId)
		.then((meta) => {
			next();
		})
		.catch((err) => {
			setLoadError(err);
			next();
		});
});
</script>
