import { messages } from '@/modules/socket-connect';
import actionHelper from '@/modules/action-helper';
import events from 'events';
const EventEmitter = events.EventEmitter;
const topicTable = Object.create(null);

messages.addListener('open', onOpen);

function genNonce() {
	return (Math.random() * 0xffffff | 0).toString(10);
}

function restoreTopic(topic) {
	const topicEmitter = getCurrentTopic(topic.topicId);
	topicEmitter.restore()
		.then(()=> {
			// remove after restoring
			delete topicTable[topic.topicId];
		});
}

function getCurrentTopic(id) {
	if (id == null || typeof id !== 'string') {
		throw new Error('Invalid Topic ID');
	}
	const topic = topicTable[id];
	if (topic) {
		return topic;
	} else {
		console.error(new Error('Topic instance not found.'));
	}
}

function createTopicRequest(topicFilter) {
	if (topicFilter == null) {
		return;
	}
	var nonce = genNonce();
	var action = {
		action: 'createTopic',
		config: topicFilter,
		nonce: nonce
	};
	return actionHelper.send(action, nonce)
		.then((data) => {
			return {
				id: data.topicId,
				close: () => closeTopic(data.topicId),
				restore: () => createTopicRequest(topicFilter)
			};
		})
		.catch(err => {
			return Promise.reject(err);
		});
}

function closeTopic(topicId) {
	if (topicId == null) {
		return;
	}
	var action = {
		action: 'deleteTopic',
		topicId: topicId
	};
	return actionHelper.send(action, topicId)
		.then((data) => {
			return data;
		});
}

function onOpen() {
	var topics = Object.entries(topicTable);
	if (topics.length === 0) {
		return;
	}

	topics.map((key) => {
		return {
			topicId: key[0],
			emitter: key[1]
		};
	}).forEach(restoreTopic);
}

function registerTopic(id, emitter) {
	if (!topicTable[id]) {
		topicTable[id] = emitter;
	}
}

function deregisterTopic(id) {
	if (id == null || typeof id !== 'string') {
		throw new Error('Invalid Topic ID');
	}
	const emitter = getCurrentTopic(id);
	if (emitter) {
		emitter.removeAllListeners();
		delete topicTable[id];
	}
}

export default {

	getCurrentTopic,

	createTopic(topicFilter) {
		// topic filter must be an object with keys folderId and events
		if (topicFilter == null || !topicFilter.hasOwnProperty('folderId') || !topicFilter.hasOwnProperty('events')) {
			return Promise.reject('Invalid Filter');
		}
		// topic filter keys must be strings
		if (typeof topicFilter.folderId !== 'string' || typeof topicFilter.events !== 'string') {
			return Promise.reject('Invalid Filter Key Type');
		}
		return createTopicRequest(topicFilter)
			.then((topic) => {
				const emitter = new EventEmitter();

				function onTopicClose(topic) {
					topic.close()
						.then(() => {
							deregisterTopic(topic.id);
						});
				}

				emitter.close = () => onTopicClose(topic);

				emitter.restore = function () {
					return topic.restore()
						.then((topic) => {
							emitter.id = topic.id;
							registerTopic(topic.id, emitter);
							emitter.close = () => onTopicClose(topic);
							return emitter;
						});
				};

				registerTopic(topic.id, emitter);
				return emitter;
			})
			.catch(err => {
				return err;
			});
	}
};
