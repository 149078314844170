<style scoped>
.drive-personal-view {
	display: flex;
	flex-direction: column;
}

.content-header {
	flex: 0 0 auto;
}
.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
	overflow-wrap: anywhere;
}
.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
	min-width: 120px;
}

.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	padding: 0 30px;
}

.no-storage-notice {
	padding: 1em;
	background-color: #eee;
	border-radius: 3px;
	display: flex;
	flex-direction: row;
}
.notice-icon {
	flex: 0 0 0;
	margin-right: 1.2em;
}
.notice-body {
	flex: 1 1 auto;
}

@media only screen and (max-width : 500px) {
	.hide-500 {
		display: none;
	}
}

</style>

<template>
	<main class="drive-personal-view no-storage">


		<header class="content-header">

			<div class="location-bar">
				<div class="breadcrumbs">

				</div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />
				<div class="location-title">My Files</div>

			</div>
		</header>
		<div class="content-section">
			<div>
				<p>This is where you could keep personal files and even share files with others.</p>
				<div class="no-storage-notice">
					<img src="@/assets/icon-no-storage.svg"  class="notice-icon" />
					<div class="notice-body">
						<p>Unfortunately, your current subscription does not include personal storage space.</p>
						<p><a :href="subscriptionsUrl" target="_blank">Update your subscription</a> to get some space.</p>
					</div>
				</div>
			</div>
		</div>

	</main>

</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import UserMenu from '@/components/UserMenu.vue';
import NavButton from '@/components/NavButton.vue';
import { useAuthStore } from '../store/auth';
import { mapState } from 'pinia';


export default {
	name: 'PersonalNoStorageView',

	directives: {

	},

	components: {
		UserMenu,
		NavButton
	},

	data () {
		return {

		};
	},

	computed: {

		...mapState(useAuthStore, {

			subscriptionsUrl: (store) => {
				const token = store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			}

		})

	},

	methods: {


	}

};
</script>
