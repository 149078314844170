<style scoped></style>

<template>
	<div>
		<h1>You lost, stranger?</h1>
	</div>
</template>

<script>


export default {
	name: 'UnknownRouteView'
};
</script>
