<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.file-missing,
.empty-state {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #bbb;
	color: var(--empty-state-color);
}







.content-header {
	flex: 0 0 auto;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
	width: 0;
}
.breadcrumbs a {
	color: #777;
	width: 100px;
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	text-overflow: ellipsis;
	overflow-wrap: anywhere;
}


.trailing-off {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.location-title button {
	font-size: 1rem;
}
.location-title button:hover {
	background: transparent;
	color: #555;
}


.item-name-block {
	display: inline-block;
	position: relative;
	height: 1.4em;
	max-width: fit-content;
	flex: 1 1 auto; /*flex: 0 1 auto;*/
}
.location-title .item-name-block {
	width: 1em;
}
.editing .item-name {
	visibility: hidden;
}
.item-name {
	height: 1.4em;
}

.item-name-editor {
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 -4px;
	width: 100%;
}
.item-name-editor-input {
	font-size: 1em;
	border: 1px solid #999;
	border-radius: 0;
	padding: 0 2px;
	width: calc(100% + .5rem);
	margin: 0;
	max-width: 100vw;
	min-width: 1em;
	height: 100%;
}





.content-toolbar {
	min-height: 40px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
}

.content-section {
	flex: 1 1 0;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%
}
.content-actions {
	flex: 0 0 auto;
	font-size: 0.9em;
	margin-bottom: 4px;
	width: 90%;
}

.details-toggle {
	justify-content: center;
}

.def-list {
	padding: 1em 2em;
	position: relative;
	height: 100%;
	max-width: 300px;
	/* background: #FFFFFF; */
	border-left: 1px solid #ddd;
	border-left: 1px solid var(--def-list-border-color);
	box-sizing: border-box;
}


.details-enter-active {
	transition: all .25s ease;
}

.details-leave-active {
	transition: all .25s ease;
}

.details-enter, .details-leave-to {
	transform: translateX(500px);
	opacity: 0;
}

.def-pair {
	margin-bottom: 1em;
	display: flex;
}
.def-key {
	display: inline-block;
	width: 100px;
	color: #777;
	text-align: left;
	margin-right: 1em;
	font-weight: bold;
}
.def-value {
	display: inline-block;
}

.preview-image-container {
	max-width: 100vw;
	max-height: 600px;
}

.preview-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	object-position: left;
	filter: var(--image-brightness);
}

.grid-header {
	flex: 0 0 auto;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--grid-header-border-bottom);
}

.notice {
	margin: 1em 30px 0;
	color: #666;
	padding: 1em;
	border-radius: 3px;
}

.notice.warning {
	padding: .25em;
	color: #666;
}

.notice.info {
	border: 1px solid #CFE7FC;
	background-color: #EBF6FF;
	color: #2E88D6;
}


@media only screen and (max-width: 500px) {
	.content-toolbar {
		display: block;
	}
	.details-toggle {
		justify-content: flex-start;
	}
	.open-details {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.def-list {
		padding: 1em;
	}
}

</style>

<template>
	<div v-if="loadError && !file">
		<div class="file-missing">
			File Not Found.
		</div>
	</div>
	<div class="drive-personal-view column" v-else-if="file">
		<header class="content-header">
			<StorageNoticeBar v-if="isOwnedFolder" />
			<div class="location-bar row">
				<Breadcrumbs :item="file" />
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />
				<div class="location-title row flexible centering">
					<span class="item-name-block row flexible" :class="{'editing':editContext}">
						<div :title="(editContext && editContext.item === file ? editContext.value : file.name)" class="item-name trailing-off">{{ (editContext && editContext.item === file ? editContext.value : file.name) }}</div>
						<span class="item-name-editor" v-if="editContext && editContext.item === file">
							<input ref="fileName" v-autofocus v-rename-input class="item-name-editor-input" type="text" v-model="editContext.value" @blur="editContext && confirmItemRename(editContext)" @keypress.enter="confirmItemRename(editContext)" @keydown.esc="cancelItemRename(editContext)" />
						</span>
					</span>
				</div>
			</div>
		</header>

		<div class="content-section">

			<div class="content-toolbar grid-header">
				<div class="content-actions">
					<button class="toolbar-button" type="button" :disabled="fileUploading" @click="downloadFile(file)"><img src="@/assets/icon-download-file.svg" class="icon-download-file" /> Download</button>
					<button v-if="canOpenInWaves" class="toolbar-button" type="button" @click="launchInWaves(file)"><img src="@/assets/icon-waves.svg" class="icon-waves" /> Launch in Waves</button>
					<button v-if="canOpenInLines" class="toolbar-button" type="button" @click="launchInLines(file)"><img src="@/assets/icon-lines.svg" class="icon-lines" /> Launch in Lines</button>
					<button v-if="canEdit" class="toolbar-button" type="button" @click="startItemRename(file)"><img src="@/assets/icon-rename.svg" class="icon-rename" /> Rename</button>
					<button v-if="canEdit" class="toolbar-button" type="button" @click="editItemMembers(file)"><img src="@/assets/icon-share.svg" class="icon-share" /> Share</button>
					<button v-if="canEdit" class="toolbar-button" type="button" @click="deleteItem(file)"><img src="@/assets/icon-delete-file.svg" class="icon-delete-file" /> Remove</button>
				</div>
				<div v-if="file.hasFileTypeViewer" class="details-toggle row centering toolbar-button">
					<img v-if="!detailsOpen" @click="openDetails" src="@/assets/icon-show-details.svg" alt="Show Details" title="Show Details">
					<img v-else @click="openDetails" src="@/assets/icon-hide-details.svg" alt="Show Details" title="Show Details">
				</div>
			</div>

			<div v-if="file.hasFileTypeViewer" class="row flexible">
				<div v-if="fileUploading" class="loading-message flexible">
					File Uploading&hellip;
				</div>
				<FileContentViewer v-else class="flexible" :class="{'open-details': detailsOpen}" :previewUrl="file.downloadUrl" :file="file" />
				<transition name="details">
					<div v-if="detailsOpen" class="def-list rigid">
						<h3>Details</h3>
						<div class="def-pair column">
							<span class="def-key">Bytes</span>
							<div v-if="fileUploading" class="modal-container loading">
								<div class="loading-members">Pending<span class="loading-dots"><span class="loading-dot">.</span><span class="loading-dot">.</span><span class="loading-dot">.</span></span></div>
							</div>
							<span v-else class="def-value">{{ formatBytes(+file.bytes) }}</span>
						</div>

						<div class="def-pair column">
							<span class="def-key">Owner</span>
							<span class="def-value">{{ file.owner.username }}</span>
						</div>

						<div class="def-pair column">
							<span class="def-key">Creator</span>
							<span class="def-value">{{ file.creator.username }}</span>
						</div>

						<div class="def-pair column">
							<span class="def-key">Created On</span>
							<span class="def-value">{{ formatDate(file.createdAt) }}</span>
						</div>

						<div class="def-pair column">
							<span class="def-key">Last Updated</span>
							<span class="def-value">{{ formatDate(file.updatedAt) }}</span>
						</div>

						<div class="def-pair column" v-if="file.sharedAt">
							<span class="def-key">Shared On</span>
							<span class="def-value">{{ formatDate(file.sharedAt) }}</span>
						</div>
					</div>
				</transition>
			</div>
			<div v-else class="def-list">
				<div class="def-pair column">
					<span class="def-key">Bytes</span>
					<div v-if="fileUploading" class="modal-container loading">
						<div class="loading-members">Pending<span class="loading-dots"><span class="loading-dot">.</span><span class="loading-dot">.</span><span class="loading-dot">.</span></span></div>
					</div>
					<span v-else class="def-value">{{ formatBytes(+file.bytes) }}</span>
				</div>

				<div class="def-pair column">
					<span class="def-key">Owner</span>
					<span class="def-value">{{ file.owner.username }}</span>
				</div>

				<div class="def-pair column">
					<span class="def-key">Creator</span>
					<span class="def-value">{{ file.creator.username }}</span>
				</div>

				<div class="def-pair column">
					<span class="def-key">Created On</span>
					<span class="def-value">{{ formatDate(file.createdAt) }}</span>
				</div>

				<div class="def-pair column">
					<span class="def-key">Last Updated</span>
					<span class="def-value">{{ formatDate(file.updatedAt) }}</span>
				</div>

				<div class="def-pair column" v-if="file.sharedAt">
					<span class="def-key">Shared On</span>
					<span class="def-value">{{ formatDate(file.sharedAt) }}</span>
				</div>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="loading-message">
			Loading&hellip;
		</div>
	</div>

</template>

<script>
import EnvVars from '@/modules/env-vars';
import UserMenu from '@/components/UserMenu.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import NavButton from '@/components/NavButton.vue';
import FileContentViewer from '@/components/FileContentViewer.vue';
import { promiser } from '@/modules/promise-util';
import StorageNoticeBar from '@/components/StorageNoticeBar.vue';
import driveService from '@/modules/drive-service';
import { byteFormatFilter, dateFormatFilter } from '@/modules/filters';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';
import { useAuthStore } from '../store/auth';


var IS_FILE_TYPES = ['CSV', 'WFM', 'TSS', 'ISF', 'VCD'];
function getFileType(file) {
	if (file == null) {
		return '';
	}
	if (typeof file.fileType === 'string' && file.fileType !== '') {
		return file.fileType.toUpperCase();
	}
	var name = (typeof file.name === 'string') ? file.name : '';
	var idx = name.lastIndexOf('.');
	var ext = (idx < 0) ? '' : name.slice(idx + 1);
	return ext.toUpperCase();
}

function isInitialStateFile(file) {
	if (file == null) {
		return false;
	}
	// Only TSS, WFM, ISF, CSV, and VCD files
	const type = getFileType(file);
	return IS_FILE_TYPES.indexOf(type) >= 0;
}

export default {
	name: 'FileView',

	props: {
		fileId: String
	},

	components: {
		UserMenu,
		StorageNoticeBar,
		NavButton,
		FileContentViewer,
		Breadcrumbs
	},

	data () {
		return {
			loadError: null,
			parentFolder: null,
			editContext: null,
			detailsOpen: false
		};
	},

	computed: {

		...mapState(useRootStore, {
			file: (store) => store.list.find((file) => file.id === store.router.currentRoute.value.params.fileId),
			userId: (store) => {
				const userId = store && store.userInfo && store.userInfo.id;
				return userId;
			},
			//access getters through mapState with pinia
			folders: 'folders'
		}),

		...mapState(useAuthStore, {
			user: 'user',
			refreshToken: 'refreshToken'
		}),

		isOwnedFolder() {
			const owner = (this.file != null && this.file.owner != null) ? this.file.owner : null;
			if (!owner || !this.userId) {
				return false;
			}
			return owner.id === this.userId;
		},

		parentFolderName () {
			return this.parentFolder && this.parentFolder.name;
		},

		canEdit() {
			if (!this.file || !this.file.id) {
				return false;
			}
			return this.file.permissions.edit;
		},

		canOpenInWaves() {
			if (!this.file || !this.file.id) {
				return false;
			}
			return isInitialStateFile(this.file);
		},

		canOpenInLines() {
			if (!this.file || !this.file.id) {
				return false;
			}
			return isInitialStateFile(this.file);
		},

		fileUploading() {
			if (!this.file || !this.file.id) {
				return false;
			}
			return this.file.uploadState === 'PENDING' && (this.file.bytes === 0 || isNaN(this.file.bytes));
		}

	},

	methods: {
		...mapActions(useRootStore, {
			GET_FOLDER_NODE: 'GET_FOLDER_NODE',
			EDIT_SHARE_MEMBERS: 'EDIT_SHARE_MEMBERS',
			CONFIRM_DELETE_ITEMS: 'CONFIRM_DELETE_ITEMS',
			SET_ITEM_NAME: 'SET_ITEM_NAME',
			GET_FILE: 'GET_FILE'
		}),
		formatDate(value, format) {
			return dateFormatFilter(value, format);
		},
		formatBytes(value) {
			return byteFormatFilter(value);
		},
		openDetails() {
			this.detailsOpen = !this.detailsOpen;
		},

		resetState() {
			this.loadError = null;
			this.file = null;
		},

		setLoadError(err) {
			this.loadError = err;
		},

		downloadFile (item) {
			if (!item || item.type !== 'FILE' || !item.id) {
				return;
			}
			driveService.downloadFile(item.id);
		},

		getParentFolder(parentFolderId){
			const parentFolder = this.folders && this.folders.find(folder => parentFolderId === folder.id);
			// is parent in cache?
			if (parentFolder) {
				return Promise.resolve(parentFolder);
			} else {
				return this.GET_FOLDER_NODE(parentFolderId)
					.then(parentFolder => {
						return Promise.resolve(parentFolder);
					});
			}
		},

		launchInWaves(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.WAVES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			window.open(url);
		},

		launchInLines(file) {
			const fileId = (typeof file === 'string') ? file : file && file.id || '';
			if (!fileId) {
				throw new Error('Invalid File ID');
			}
			const baseUrl = EnvVars.LINES_APP_URL || '';
			const refreshToken = this.refreshToken || '';
			const url = baseUrl + '?fid=' + encodeURIComponent(fileId) +  (refreshToken ? '#' + refreshToken : '');

			window.open(url);
		},

		editItemMembers (item) {
			this.EDIT_SHARE_MEMBERS(item);
		},

		deleteItem (item) {
			if (item == null || !item.id) {
				return;
			}
			// Navigate away after deleting the file
			const userId = this.user && this.user.id || '';
			const isOwner = (item.owner.id === userId);
			const exitPath = (item.parentFolderId) ? '/d/' + encodeURIComponent(item.parentFolderId) :
				isOwner ? '/personal' : '/shares';

			this.CONFIRM_DELETE_ITEMS([item])
				.then(() => {
					this.$router.push(exitPath);
				})
				.catch((reason) => {
					console.log('Deletion Cancelled');
				});
		},

		startItemRename: function (item) {
			if (this.editContext) {
				// clear edit context
				this.confirmItemRename(this.editContext);
			}

			if (item) {

				const resolveable = promiser();
				const state = this;

				this.editContext = {
					item: item,
					value: item.name,
					originalValue: item.name,
					promise: resolveable.promise,
					commit: (value) => {
						this.SET_ITEM_NAME({ item, name: this.editContext.value });
						this.editContext = null;
						resolveable.resolve(value);
					},
					cancel: (reason) => {
						this.editContext = null;
						resolveable.reject(reason);
					}
				};

				// return new edit promise
				return this.editContext.promise;
			}

			// no new edit context, resolve as non-edit
			return Promise.resolve(null);
		},

		confirmItemRename: function (editContext, name) {
			if (editContext && typeof editContext.commit === 'function') {
				editContext.commit(name);
			}
		},

	},

	beforeRouteEnter (to, from, next) {
		const store = useRootStore();
		const fileId = to.params.fileId;
		store.GET_FILE(fileId)
			.then(() => {
				next();
			})
			.catch((err) => {
				next((vm) => vm.setLoadError(err));
			});
	},

	beforeRouteUpdate (to, from, next) {
		this.resetState();
		const fileId = to.params.fileId;
		this.GET_FILE(fileId)
			.then(() => {
				next();
			})
			.catch((err) => {
				this.setLoadError(err);
				next();
			});
	},

	mounted() {

	}

};
</script>
