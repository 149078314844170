<style scoped>
</style>

<template>
	<div class="flexible">
		<iframe height="100%" width=100% :src="getFilePath" ></iframe>
	</div>
</template>

<script>

export default {
	name: 'PdfViewer',
	props: {
		fileName: String
	},
	data() {
		return {
			path: '/lib/pdfjs/web/viewer.html',
			mounted: false
		};
	},
	computed: {
		getFilePath() {
			if (this.fileName !== '') {
				return this.path + '?file=' + encodeURIComponent(this.fileName);
			}
			return this.path;
		}
	},
};
</script>

