<style scoped>
.account-error-view {
	margin: 1em 2em;
}


.context-header {
	display: flex;
	align-items: baseline;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 40px;
}
.app-brand {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	flex: 1 1 auto;
}
.home-link {
	color: inherit;
}
.tekcloud-logo {
	vertical-align: -12px;
	margin-right: 6px;
	height: 50px;
	width: 80px;
}

.resolve-option {
	margin: 0.5em 0;
}
</style>

<template>
	<div class="account-error-view">

		<div class="context-header">
			<div class="app-brand">
				<router-link to="/" class="home-link">
					<img src="@/assets/tekcloud-nav-logo.svg" alt="TekDrive" class="tekcloud-logo" />
					<span class="tekdrive-font">TekDrive</span>
				</router-link>
			</div>
		</div>

		<h1>Account Error</h1>

		<p>
			An error occured while accessing your account information. To fix this, try any of the following:
		</p>

		<ul class="resolve-options">
			<li class="resolve-option">You may need to <a :href="subscriptionsUrl" target="tekdcloudaccount">subscribe to a plan</a> then <a href="/">reload this web app</a>.</li>
			<li class="resolve-option"><router-link to="/signout?reason=accounterror">Sign in again</router-link> or with a different account.</li>
			<li class="resolve-option"><a href="https://www.tekcloud.com/contact/index.html" target="_blank">Contact support</a></li>
		</ul>

	</div>
</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import { mapState } from 'pinia';
import { useAuthStore } from '../store/auth';

export default {
	name: 'AccountErrorView',

	computed: {
		...mapState(useAuthStore, {

			subscriptionsUrl: (store) => {
				const token = store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			},

			authUrl: (store) => {
				const token = store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			}

		})
	},

	methods: {

	}

};
</script>
