import { defineStore } from 'pinia';
import axios from 'axios';
import { getAccessToken, setRefreshUrl as setRefresherUrl } from '@/modules/token-refresher';
import { setRefreshToken as setAuthRefreshToken } from '@/modules/auth-request';
import ENV_VARS from '@/modules/env-vars';
import { Buffer } from 'buffer';
const LOGOUT = 'LOGOUT';
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
const UPDATING_SESSION = 'UPDATING_SESSION';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_ERROR = 'AUTH_ERROR';
const SET_CLAIMS = 'SET_CLAIMS';

function decodeToken(token) {
	try {
		return (typeof token !== 'string' || token.indexOf('.') < 0) ? null :
			JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
	} catch (err) {
		return null;
	}
}

function getAuthUrl(path) {
	return (ENV_VARS.AUTH_API || '').replace(/\/$/, '') + '/' + path.replace(/^]\//, '');
}


export const useAuthStore = defineStore('authStore', {

	state: () => ({

		status: {
			loading: false,
			success: false,
			error: false
		},

		user: null,

		refreshToken: null

	}),

	getters: {

		isAuthenticated: state => {
			const token = decodeToken(state.refreshToken);
			// validate refresh token by expiry
			var expiryMs = (token && token.exp * 1e3 || 0);
			return expiryMs > Date.now();
		},

		userEmail: state => {
			const token = decodeToken(state.refreshToken);
			return token && token.usr || '';
		},

		// refreshTokenJTI: state => this.getTokenJti(state.refreshToken)

	},



	actions: {

		//outer function

		getTokenJti(token) {
			const data = decodeToken(this.refreshToken);
			return data && data.jti || '';
		},
		//Was a getter
		refreshTokenJTI() {
			return this.getTokenJti(this.refreshToken);
		},
		//mutations

		UPDATING_SESSION(isActive) {
			this.status = {
				loading: isActive === true,
				success: isActive !== true,
				error: false
			};
		},

		SET_REFRESH_TOKEN_CACHE(refreshToken) {
			if (typeof refreshToken !== 'string') {
				refreshToken = '';
			}

			this.refreshToken = refreshToken;

			if (refreshToken) {
				localStorage.setItem('refreshToken', refreshToken);
			} else {
				localStorage.removeItem('refreshToken');
			}

			setAuthRefreshToken(refreshToken);

			var session = decodeToken(refreshToken);
			this.user = session;
		},

		AUTH_SUCCESS(accessToken) {
			this.status = {
				loading: false,
				success: true,
				error: false
			};
		},

		AUTH_ERROR() {
			this.status = {
				loading: false,
				success: false,
				error: true
			};
		},

		CLEAR_USER() {
			// clear refresh token and any cached session data
			this.refreshToken = '';
			this.user = null;
			setAuthRefreshToken(null);
			localStorage.removeItem('refreshToken');
		},

		SET_CLAIMS(payload) {
			const user = decodeToken(payload);
			this.user.claims = user.claims;
		},

		//actions

		async SET_REFRESH_TOKEN(refreshToken) {
			// TODO: validate token, get session/claims data

			this.UPDATING_SESSION(true);

			this.SET_REFRESH_TOKEN_CACHE(refreshToken);

			// Change this to an user update (possible claims change)
			return getAccessToken(refreshToken)
				.then((accessToken) => {
					this.AUTH_SUCCESS(accessToken);
					this.UPDATING_SESSION(false);
					this.SET_CLAIMS(accessToken);
					// context.commit(SET_CLAIMS, accessToken);
					// debugger;
					// axios({ url: getAuthUrl('/me' + accessToken), method: 'GET' })
					//     .then((data) => {
					//         context.commit(SET_CLAIMS, data.claims);
					//     })
					//     .catch((reason) => {
					//         return Promise.reject(reason);
					//     });
				})
				.catch((reason) => {
					// clear refresh token
					this.SET_REFRESH_TOKEN_CACHE(null);
					this.AUTH_ERROR(reason);
					return Promise.reject(reason);
				});
		},

		async LOGOUT(context) {
			const refreshJTI = this.getTokenJti(context.refreshToken);
			return new Promise((resolve) => {
				if (refreshJTI) {
					// Invalidate refresh token
					// TODO: move XHR requests to separate service
					return axios({ url: getAuthUrl('/me/security/tokens/' + refreshJTI), method: 'DELETE' })
						.catch(function noop() { /* Ignore failure */ })
						.then(resolve);
				}
				resolve();
			})
				.then(() => {
					this.CLEAR_USER();
				});
		}

	}

});
