<style scoped>

.drive-personal-view {
	display: flex;
	flex-direction: column;
}

</style>

<template>
	<FolderView ref="folderView" v-if="hasStorage" class="drive-personal-view" :folder-id="folderId" />
	<PersonalNoStorageView v-else class="drive-personal-view no-storage" />
</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import FolderView from '@/views/FolderView.vue';
import PersonalNoStorageView from '@/views/PersonalNoStorageView.vue';
import { mapActions, mapState } from 'pinia';
import { useAuthStore } from '../store/auth';
import { useRootStore } from '../store/rootStore';


export default {
	name: 'PersonalStorageView',

	directives: {

	},

	components: {
		FolderView,
		PersonalNoStorageView
	},

	data () {
		return {
			hasStorage: true,
			folderId: null
		};
	},

	computed: {

		...mapState(useRootStore, {
			userStats: 'userStats',
		}),
		...mapState(useAuthStore, {
			subscriptionsUrl: (store) => {
				const token = store && store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			}
		})

	},

	methods: {
		...mapActions(useRootStore, {
			GET_FOLDER_NODE: 'GET_FOLDER_NODE'
		})


	},

	mounted () {
		const userStats = this.userStats || {};
		const hasPersonal = userStats.totalBytesOwned > 0 || userStats.storageSizeLimit > 0;

		// TODO: only do this if storage exists (has files or storage)

		if (hasPersonal) {
			this.hasStorage = true;
			const folderView = this.$refs.folderView;
			const folderId = 'PERSONAL';
			const vm = this;

			this.GET_FOLDER_NODE(folderId)
				.then((meta) => {
					vm.folderId = meta.id;
				})
				.catch((err) => {
					folderView.setLoadError(err, null);
				});

		} else {
			this.hasStorage = false;
		}


	}

};
</script>
