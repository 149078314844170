const autoheight = {

	updated: function(el, binding) {
		if (el) {
			const computedStyle = window.getComputedStyle(el, null);
			const borders = parseInt(computedStyle.getPropertyValue('border-top-width')) + parseInt(computedStyle.getPropertyValue('border-bottom-width'));
			// (re)set back to auto if binding value is true
			el.style.height = binding.value ? 'auto' : (el.scrollHeight + borders) + 'px';
		}
	}

};


export { autoheight };
