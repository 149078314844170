import driveApi from '@/modules/drive-api';
import { agentDownload } from '@/modules/download-service';
import EnvVars from '@/modules/env-vars';


function search(params) {
	return driveApi.search(params);
	// { list:[], page:0, limit:0, offset:0, next() }
}

function silos() {
	return driveApi.silos();
}

function tree(folderId, depth) {
	return driveApi.tree(folderId, depth);
}

function treePath(item, depth) {
	return driveApi.treePath(item, depth);
}

function listTrash(orderBy) {
	return driveApi.listTrash(orderBy);
}

function emptyTrash() {
	return driveApi.emptyTrash();
}

function getFile(fileId, params) {
	return driveApi.getFileMeta(fileId, params);
}

function uploadFile(config) {
	return driveApi.createFileMeta(config);
}

function downloadFile(file) {
	const fileId = (typeof file === 'string') ? file : (file && typeof file.id === 'string') ? file.id: '';
	if (!fileId) {
		return Promise.reject(new Error('Invalid File ID'));
	}
	const fileName = (file && typeof file.name === 'string') ? file.name: '';

	return driveApi.getFileDownloadInfo(fileId)
		.then((info) => {
			if (info == null || !info.url) {
				throw new Error('Invalid Download URL');
			}
			// Trigger a browser download
			agentDownload(info.url, fileName);
			return info;
		});
}

function downloadFileArtifact(artifact) {
	const fileId = (typeof artifact === 'string') ? artifact : (artifact && typeof artifact.fileId === 'string') ? artifact.fileId: '';
	const artifactId = (typeof artifact === 'string') ? artifact : (artifact && typeof artifact.id === 'string') ? artifact.id: '';
	if (!fileId) {
		return Promise.reject(new Error('Invalid File ID'));
	}
	if (!artifactId) {
		return Promise.reject(new Error('Invalid Artifact ID'));
	}
	const fileName = (artifact && typeof artifact.name === 'string') ? artifact.name: '';

	return driveApi.getFileArtifactDownloadInfo(fileId, artifactId)
		.then((info) => {
			if (info == null || !info.url) {
				throw new Error('Invalid Download URL');
			}
			// Trigger a browser download
			agentDownload(info.url, fileName);
			return info;
		});
}

function getFileArtifacts(fileId) {
	let artifactTimer = null,
		trys = 1;

	function getFileArtifactsRecursive() {
		return new Promise((resolve, reject) => {
			function getArtifactsRequest() {
				if (artifactTimer) {
					clearTimeout(artifactTimer);
				}
				return driveApi.getFileArtifacts(fileId)
					.then(artifacts => {
						// ensure screen capture and settings zip are available
						if (artifacts && artifacts.screenCapture && artifacts.settingsZip) {
							return resolve(artifacts);
						} else {
							trys += 1;
							if (trys > 15) {
								return reject(new Error('Failed to get Artifacts'));
							}
							artifactTimer = setTimeout(() => {
								getArtifactsRequest();
							}, 2000);
						}
					});
			}
			getArtifactsRequest();
		});
	}
	// use recursion to ensure screen capture is available in artifacts model
	return getFileArtifactsRecursive()
		.then(artifacts => {
			return artifacts;
		});
}

function getFileDownloadUrl(file) {
	const fileId = (typeof file === 'string') ? file : (file && typeof file.id === 'string') ? file.id: '';
	if (!fileId) {
		return Promise.reject(new Error('Invalid File ID'));
	}
	const fileName = (file && typeof file.name === 'string') ? file.name: '';

	return driveApi.getFileDownloadInfo(fileId)
		.then((info) => {
			if (info == null || !info.url) {
				throw new Error('Invalid Download URL');
			}
			return Promise.resolve(info.url);
		})
		.catch((err) => {
			return Promise.reject(err);
		});
}

function getFileDownloadUrlOnUploadComplete(file) {
	let trys = 0;
	let downloadUrlTimer = null;

	function getDownloadUrlRecursive() {
		return new Promise((resolve, reject) => {
			function getDownloadUrlRequest() {
				if (downloadUrlTimer) {
					clearTimeout(downloadUrlTimer);
				}
				return getFileDownloadUrl(file)
					.then(url => {
						return resolve(url);
					})
					.catch(err => {
						trys += 1;
						// console.log(err);
						if (trys > 9) {
							return reject(err);
						}
						downloadUrlTimer = setTimeout(() => {
							getDownloadUrlRequest();
						}, 1000);
					});
			};
			getDownloadUrlRequest();
		});
	}

	return getDownloadUrlRecursive()
		.then((url) => {
			return url;
		});
}

function getFileArtifactDownloadUrl(artifact) {
	const fileId = (typeof artifact === 'string') ? artifact : (artifact && typeof artifact.fileId === 'string') ? artifact.fileId: '';
	const artifactId = (typeof artifact === 'string') ? artifact : (artifact && typeof artifact.id === 'string') ? artifact.id: '';
	if (!fileId) {
		return Promise.reject(new Error('Invalid File ID'));
	}
	if (!artifactId) {
		return Promise.reject(new Error('Invalid Artifact ID'));
	}

	return driveApi.getFileArtifactDownloadInfo(fileId, artifactId)
		.then((info) => {
			if (info == null || !info.url) {
				throw new Error('Invalid Download URL');
			}
			return info.url;
		});
}

function restoreFile(fileId) {
	return driveApi.restoreFile(fileId);
}

function getFileMembers(fileId) {
	return driveApi.getFileMembers(fileId);
}

function setFileMembers(fileId, members) {
	return driveApi.setFileMembers(fileId, members);
}

function updateFile(fileId, config) {
	return driveApi.updateFileMeta(fileId, config);
}

function deleteFile(fileId, hardDelete) {
	return driveApi.deleteFileMeta(fileId, hardDelete);
}

function deleteFileMember(fileId, memberId) {
	return driveApi.deleteFileMember(fileId, memberId);
}

function deleteFolderMember(folderId, memberId) {
	return driveApi.deleteFolderMember(folderId, memberId);
}

function getFolder(folderId) {
	return driveApi.getFolder(folderId);
}

function getFolderMembers(folderId) {
	return driveApi.getFolderMembers(folderId);
}

function setFolderMembers(folderId, members) {
	return driveApi.setFolderMembers(folderId, members);
}

function createFolder(config) {
	return driveApi.createFolder(config);
}

function updateFolder(folderId, config) {
	return driveApi.updateFolder(folderId, config);
}

function deleteFolder(folderId, hardDelete) {
	return driveApi.deleteFolder(folderId, hardDelete);
}

function restoreFolder(folderId) {
	return driveApi.restoreFolder(folderId);
}

function getUserInfo() {
	return driveApi.getUserInfo();
}

function getUserStats() {
	return driveApi.getUserStats();
}

function listAccessKeys() {
	return driveApi.getAccessKeys();
}

function createAccessKey(name, claims) {
	return driveApi.createAccessKey(name, claims, EnvVars.DWC_CLIENT_ID);
}

function deleteAccessKey(accessKeyId) {
	return driveApi.deleteAccessKey(accessKeyId);
}

function getShortcodeContext(shortcode) {
	return driveApi.getShortcodeContext(shortcode);
}
function acceptShortcode(shortcode) {
	return driveApi.acceptShortcode(shortcode);
}
function rejectShortcode(shortcode) {
	return driveApi.rejectShortcode(shortcode);
}

function createWebhook(webhook) {
	return driveApi.createWebhook(webhook);
}

function listWebhooks() {
	return driveApi.listWebhooks();
}

function updateWebhook(webhookId, webhook) {
	return driveApi.updateWebhook(webhookId, webhook);
}

function deleteWebhook(webhookId) {
	return driveApi.deleteWebhook(webhookId);
}

export default {
	search,
	tree,
	treePath,
	silos,

	listTrash,
	emptyTrash,

	getFile,
	restoreFile,
	downloadFile,
	downloadFileArtifact,
	getFileArtifacts,
	getFileDownloadUrl,
	getFileDownloadUrlOnUploadComplete,
	getFileArtifactDownloadUrl,
	getFileMembers,
	setFileMembers,
	uploadFile,
	updateFile,
	deleteFile,
	deleteFileMember,

	getFolder,
	restoreFolder,
	getFolderMembers,
	setFolderMembers,
	createFolder,
	updateFolder,
	deleteFolder,
	deleteFolderMember,

	getUserInfo,
	getUserStats,

	listAccessKeys,
	createAccessKey,
	deleteAccessKey,

	getShortcodeContext,
	acceptShortcode,
	rejectShortcode,

	createWebhook,
	listWebhooks,
	updateWebhook,
	deleteWebhook

};

