function promiser() {
	var syncState = 0;
	var syncValue;
	var resolver = null;
	var rejector = null;
	const promise = new Promise((resolve, reject) => {
		if (syncState > 0) {
			resolve(syncValue);
		} else if (syncState < 0) {
			reject(syncValue);
		} else {
			resolver = resolve;
			rejector = reject;
		}
	});

	return {
		promise: promise,
		resolve: (value) => {
			if (typeof resolver === 'function') {
				resolver(value);
			} else if (!syncState) {
				syncState = 1;
				syncValue = value;
			}
			return promise;
		},
		reject: (value) => {
			if (typeof rejector === 'function') {
				rejector(value);
			} else if (!syncState) {
				syncState = -1;
				syncValue = value;
			}
			return promise;
		}
	};
}

export { promiser };
