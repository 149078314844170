<style scoped>
	.nav-button {
		display: none;
		margin-right: .5em;
		margin-left: -.5em;
	}

	@media only screen and (max-width : 700px) {
		.nav-button {
			display: block;
		}
	}
</style>

<template>
	<button type="button" @click="showLeftNav(true)" class="toolbar-button nav-button centered"><i class="fas fa-bars"></i></button>
</template>

<script>
import { useNavStore } from '../store/navigationStore';
import { mapActions } from 'pinia';

export default {
	name: 'NavButton',

	methods: {
		...mapActions(useNavStore, {
			showLeftNav: 'showLeftNav'
		})
	}
};
</script>
