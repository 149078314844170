<style scoped>
.content-header {
	flex: 0 0 auto;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}









.section-header {
  padding: 0 30px;
}

.content-section {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
}


.quick-actions {
	flex-wrap: wrap;
}

.quick-actions a {
	margin-top: 1em;
}

.card {
  width: 222px;
  background: #f2f2f2;
  background: var(--card-background-color);
  border-radius: 0.5rem;
  justify-content: center;
  padding: 1.5rem;
  align-items: flex-start;
  margin-right: 1em;
}


.card h4 {
  color: #555;
  color: var(--card-color);
  margin-bottom: 0;
}

/* Banner */

.success {
  	background: #ebfff7;
 	border: 1px solid #cdfeec;
  	border-radius: 3px;
	color: #0B754E;
}

.notice-bar {
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notice-bar.success a:not(.button) {
  text-decoration: underline;
  color: var(--success-link-color);
}


.exit-banner {
  padding: 0 2em 0 1em;
}

.banner-content {
  padding: 0.25em 2em;
}

.banner-content p {
  padding-left: 1em;
}

.banner-action {
  background: #fcfcfc;
  border: 1px solid #cccccc;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  color: #75550b;
  text-align: center;
  padding: 0.5em 0.75em;
  min-width: 140px;
}

.hidebanner {
  display: none;
}


@media only screen and (max-width: 550px) {
  .banner-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .banner-action {
    display: none;
  }
  .banner-content {
    padding-right: 0;
  }
  .exit-banner {
    padding-left: 0;
  }
}

</style>
<template>
	<div class="access-keys-layout">

		<div v-if="newWebhookSuccess" :class="{ hidebanner : bannerIsHidden }" class="notice-bar success">
			<div class="banner-content row centering">
				<i class="fas fa-check-circle fa-lg"></i>
				<p>Webhook successfully created!</p>
			</div>
			<a class="exit-banner fa fa-times" @click="hideBanner"></a>
		</div>

		<div v-if="updateWebhookSuccess" :class="{ hidebanner : bannerIsHidden }" class="notice-bar success">
			<div class="banner-content row centering">
				<i class="fas fa-check-circle fa-lg"></i>
				<p>Webhook successfully updated!</p>
			</div>
			<a class="exit-banner fa fa-times" @click="hideBanner"></a>
		</div>

		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs">
					<span><router-link to="/"><i class="fa fa-arrow-circle-left"></i> Leave Settings</router-link></span>
				</div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<div class="location-title">Settings</div>
			</div>
		</header>

		<div class="content-section">
			<div class="section-header">
				<h2>Quick Actions</h2>
				<div class="quick-actions row">
					<router-link to="/welcome">
						<div class="card column">
							<img src="@/assets/icon-replay-intro.svg" alt="Replay Intro" />
							<h4>Replay Introduction</h4>
						</div>
					</router-link>
					<a href="https://help.tekcloud.com/hc/en-us" target="_blank">
						<div class="card column">
							<img src="@/assets/icon-support.svg" alt="TekDrive Support" />
							<h4>TekDrive Support</h4>
						</div>
					</a>
					<a href="https://docs.drive.tekcloud.com/" target="_blank">
						<div class="card column">
							<img src="@/assets/icon-api-docs.svg" alt="API Documentation" />
							<h4>API Documentation</h4>
						</div>
					</a>
				</div>
			</div>
			<SettingsMenu :section="section" />
		</div>
	</div>
</template>

<script>
import UserMenu from '@/components/UserMenu.vue';
import SettingsMenu from '@/components/settings/SettingsMenu.vue';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';

export default {
	name: 'AccessKeyListView',
	props: {
		section: String,
		q: String
	},
	components:{
		UserMenu,
		SettingsMenu
	},

	data () {
		return {
			highlightId: null,
			bannerIsHidden: false
		};
	},

	computed: {
		...mapState(useRootStore, {
			claimList: (store) => store.accessKeyClaimOptions || [],
		}),
		newWebhookSuccess() {
			return !!(this.section === 'webhooks' && this.q === 'success');
		},
		updateWebhookSuccess() {
			return !!(this.section === 'webhooks' && this.q === 'update');
		}
	},

	methods: {
		...mapActions(useRootStore, {
			UPDATE_ACCESS_KEYS: 'UPDATE_ACCESS_KEYS',

		}),
		onInit(err, highlightId) {
			this.loadError = err;
			this.highlightId = (typeof highlightId === 'string' && highlightId) ? highlightId : null;
		},
		hideBanner(toggle) {
			this.bannerIsHidden = true;
		},

	},

	//GET Access Key list
	beforeRouteEnter (to, from, next) {
		//remove highlightId
		var highlightId = to.query && to.query.created;

		const store = useRootStore();

		store.UPDATE_ACCESS_KEYS()
			.then((list) => {
				next((vm) => {
					vm.onInit(null, highlightId);
				});
			})
			.catch((err) => {
				next((vm) => {
					vm.onInit(err, null);
				});
			});
	},

	beforeRouteUpdate (to, from, next) {
		this.list = [];
		this.loadError = null;

		var highlightId = to.query && to.query.created;

		this.UPDATE_ACCESS_KEYS()
			.then((list) => {
				this.onInit(null, highlightId);
				next();
			})
			.catch((err) => {
				this.onInit(err, null);
				next();
			});
	}

};
</script>
