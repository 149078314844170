import DriveItem from '@/modules/models/drive-item';

class DriveFile extends DriveItem {
	type = 'FILE';
	fileType = ''; // System recognized file type
	bytes = 0;
	uploadState = 'PENDING'; // PENDING, SUCCESS, ERROR
	hasFileTypeViewer = false;
	downloadUrl = '';
};

export default DriveFile;