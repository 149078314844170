
const autofocus = {

	mounted: function(el, binding, b, c) {
		function tryFocus() {
			try {
				el.focus();
			} catch (e) {/* fail silently */}
		}
		if (binding && binding.instance && typeof binding.instance.$nextTick === 'function') {
			binding.instance.$nextTick(() => {
				tryFocus();
			});
		} else {
			tryFocus();
		}
	}

};


export { autofocus };
