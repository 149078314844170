function tryCopySelection() {
	try {
		return document.execCommand('copy');
	} catch (err) {
		return false;
	}
}

function copyText(text) {
	if (typeof text !== 'string') {
		return false;
	}

	//Checking for browser support
	if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
		navigator.clipboard.writeText(text);
		return true;
	} else {
		//Newer browser versions may not support document.execCommand, but leaving in for older versions
		//https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#return_value
		const el = document.createElement('input');
		el.setAttribute('type', 'text');
		el.style.display = 'fixed';
		el.style.visibility = 'hidden';
		el.value = text;
		document.body.appendChild(el);
		el.select();

		const textCopied = tryCopySelection();
		/* unselect the range */
		el.setAttribute('type', 'hidden');
		document.body.removeChild(el);
		window.getSelection().removeAllRanges();

		return textCopied;
	}

}

export default {
	copyText
};
