<style scoped>
</style>

<template>
	<button type="button" class="file-input-button" @click="showFilePrompt()"><slot></slot></button>
</template>

<script>

export default {
	name: 'PickFileButton',

	props: [
		'multiple'
	],

	data () {
		return {

		};
	},

	methods: {

		showFilePrompt: function () {
			// TODO: abstract this to a service
			var el = document.createElement('input');
			el.setAttribute('type', 'file');
			el.setAttribute('name', 'upload-file');
			if (this.multiple) {
				el.setAttribute('multiple', 'multiple');
			}
			el.style.display = 'none';
			document.body.appendChild(el);

			var vm = this;
			el.addEventListener('change', function () {
				vm.$emit('select', el.files);
			});
			el.click();
			el.remove();
		}

	}

};
</script>
