<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 400px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

h3 {
	margin-top: 0;
}

.modal-footer {
	margin-top: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}
.action-ok {
	background-color: #2E88D6;
	border-color: #2E88D6;
	color: #fff;
	cursor: pointer;
}
.action-ok:focus {
	border-color: #153f63;
}

</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">
			<form class="modal-container launch-confirm-mdal" @submit.prevent="continueLaunch(item)">
				<div>
					<h3>Opening A Large File</h3>
					<p><em class="file-name">{{ item.name || 'This file' }} is {{ formatBytes(item.bytes) || 'very large' }}</em> in size and may result in a poor experience.</p>
					<p>Do you wish to continue?</p>
				</div>
				<footer class="modal-footer">
					<button type="button" class="button action-cancel" @click="cancel()" :disabled="updating">Cancel</button>
					<span class="space"></span>
					<button type="button" class="button action-download" @click="download()" :disabled="updating">Download</button>
					<span class="gap"></span>
					<button type="submit" class="button action-ok" :disabled="updating">Continue</button>
				</footer>
			</form>
		</div>
	</transition>
</template>

<script>
import { byteFormatFilter } from '@/modules/filters.js';
import driveService from '@/modules/drive-service';
import { createModalMask, removeModalMask } from '@/modules/modalMask';

export default {
	name: 'LaunchConfirmModal',

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& value.item != null
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	directives: {

		modalMask: {

			bind: function (el, binding, node, oldNode) {
				function onPress(event) {
					if (event.target === el) {
						node.context.$emit('directclick', event);
					}
				}
				el.addEventListener('click', onPress, false);
			}

		}

	},

	data () {
		return {
			updating: false,
			item: this.context.item || [],

			resolvable: this.context.resolvable || null
		};
	},

	methods: {

		close () {
			this.resolvable.reject(this.item);
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		cancel () {
			this.close();
		},

		download () {
			const file = this.context.item;
			driveService.downloadFile(file);
			this.close();
		},

		continueLaunch (item) {
			console.log('continue launch...');
			this.resolvable.resolve(item);
		},

		formatBytes (byteSize) {
			if (typeof byteSize !== 'number' || !isFinite(byteSize)) {
				return '';
			}
			return byteFormatFilter(byteSize);
		},

		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},

	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}


};
</script>
