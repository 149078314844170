<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.empty-state {
	margin: 3em 0;
	text-align: center;
	color: #bbb;
	color: var(--empty-state-color);
	padding: 2em;
}



.content-header {
	flex: 0 0 auto;
}
.content-toolbar {
	min-height: 40px;
	flex: 0 0 auto;
	padding: 0 20px;
	font-size: 0.9em;
	margin-bottom: 4px;
	display: flex;
	justify-content: space-between;
}


.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
}

.menu-list {
	list-style: none;
	padding: 4px 0;
	margin: 0;
	cursor: default;
	user-select: none;
}
.menu-list li {
	margin: 0;
	padding: 0;
}
.menu-action,
.menu-parent {
	padding: 0.3em 0.4em;
	display: flex;
	flex-direction: row;
	overflow: hidden;
	text-overflow: ellipsis;
}
.menu-parent::after {
	content: '\25B6';
	display: inline-block;
	flex: 0 0 auto;
}
.menu-icon {
	flex: 0 0 auto;
	width: 2em;
	display: inline-block;
}
.menu-label {
	transition: none;
	flex: 1 1 auto;
}
.menu-separator {
	opacity: 0.3;
}
.menu-submenu {
	background: #fafafa;
	border: 1px solid #bdbdbd;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 4px 0;
	width: 250px;
	z-index: 99999;

	display: none;
	position: absolute;
	top: 0;

	right: -100%;
}
.menu-right .menu-submenu {
	right: auto;
	left: -100%;
}
.menu-list.left {
	right: auto;
	left: -100%;
}
.menu-group {
	position: relative;
}
.menu-group:hover > .menu-submenu {
	display: block;
}
.menu-action:hover,
.menu-group:hover .menu-parent {
	background: #1e88e5;
	color: #fafafa;
}
.menu-placeholder {
	opacity: 0.5;
	padding: 0.5em 0;
}
.menu-action {
	cursor: pointer;
}
.menu-option > .menu-icon {
	visibility: hidden;
}
.menu-option.active > .menu-icon {
	visibility: visible;
}

</style>

<template>
	<div v-if="loadError">
		<div class="folder-missing">
			Folder Not Found.
		</div>
	</div>
	<div class="drive-personal-view" v-else-if="searchResult">


		<header class="content-header">
			<div class="location-bar">
				<div class="breadcrumbs">
					<span v-if="returnPath">
						<router-link :to="returnPath"><i class="fa fa-arrow-circle-left"></i> Leave Search</router-link>
					</span>
				</div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">

				<div class="location-title">Search Results</div>

				<SearchBar class="location-search"
					:text="searchParams.name"
					:folderId="searchParams.folderId"
					:type="searchParams.type"
					:depth="searchParams.depth"
					:orderBy="searchParams.orderBy"
					:uploadState="searchParams.uploadState" @searchcleared="onSearchCleared" />

			</div>
		</header>
		<div class="content-section">
			<div class="content-toolbar">
				<span>
					<button v-if="canDownload" class="toolbar-button" type="button" @click="downloadFiles(selection)"><img src="@/assets/icon-download-file.svg" class="icon-download-file" /> Download</button>
					<button v-if="selection.length === 1" class="toolbar-button" type="button" @click="startItemRename(selection[0])"><img src="@/assets/icon-rename.svg" class="icon-rename" /> Rename</button>
					<button v-if="selection.length === 1" class="toolbar-button" type="button" @click="editItemMembers(selection[0])"><img src="@/assets/icon-share.svg" class="icon-share" /> Share</button>
					<button v-if="selection.length > 0 && !areAllItemsInShareSilo(selection) && canEditItems(selection)" class="toolbar-button" type="button" @click="deleteItems(selection)"><img src="@/assets/icon-delete-file.svg" class="icon-delete-file" /> Remove</button>
					<button v-if="selection.length > 0 && areAllItemsInShareSilo(selection)" class="toolbar-button" type="button" @click="removeMember(selection)"><img src="@/assets/icon-delete-file.svg" class="icon-delete-file" /> Remove Share</button>
				</span>

				<div></div>

				<div>
					<ToggleViewButton />
				</div>

			</div>
			<div class="content-view">
				<div v-if="!searchList || !searchList.length" class="empty-state">
					<i class="fa fa-user-astronaut"></i> Empty File Space
				</div>
				<TileView TileView v-else-if="childViewStyle === 'tile'" columns="size,updated" ref="listView" :list="searchList" v-model:selection="selection" />
				<ListView v-else :list="searchList" columns="size,updated" ref="listView" v-model:selection="selection" />
			</div>
		</div>

	</div>
	<div v-else>
		<p class="loading-message">
			Loading&hellip;
		</p>
	</div>

</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ListView from '@/components/ListView.vue';
import TileView from '@/components/TileView.vue';
import ToggleViewButton from '@/components/ToggleViewButton.vue';
import UserMenu from '@/components/UserMenu.vue';
import driveService from '@/modules/drive-service';
import { mapActions, mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';

function paramsToSearch(param) {
	if (param == null) {
		return null;
	}
	const search = {
		depth: 50,
		limit: 1000
	};
	if (typeof param.folderId === 'string' && param.folderId !== '') {
		search.folderId = param.folderId;
	}
	if (typeof param.type === 'string' && param.type !== '') {
		search.type = param.type;
	}
	if (typeof param.name === 'string' && param.name !== '') {
		search.name = param.name;
	}
	if (param.depth != null && isFinite(+param.depth)) {
		search.depth = +param.depth;
	}
	if (typeof param.orderBy === 'string' && param.orderBy !== '') {
		search.orderBy = param.orderBy.split(',').filter(Boolean);
	}
	if (typeof param.uploadState === 'string' && param.uploadState !== '') {
		search.uploadState = param.uploadState;
	}
	return search;
}

export default {
	name: 'SearchResultView',

	props: {
		search: String
	},

	components: {
		SearchBar,
		ListView,
		TileView,
		ToggleViewButton,
		UserMenu,
	},

	data () {
		return {
			searchResult: null,
			loadError: null,

			searchParams: {},

			returnOverride: '',

			editContext: null,
			lastSelected: null,
			selection: []
		};
	},

	computed: {

		...mapState(useRootStore, {
			childViewStyle: 'childViewStyle',

			userId: (store) => store && store.userInfo && store.userInfo.id,
			sharesSiloId: (store) => {
				if (!store.silos.SHARES.id) {
					return null;
				}
				return store.silos.SHARES.id;
			},
			list: 'list'
		}),

		searchList() {
			const searchResult = this.searchResult && this.searchResult.list || [];
			const folderId = this.searchParams && this.searchParams.folderId || '';
			const searchIds = searchResult.map(item => item.id);
			const resultList = this.list.filter(item => searchIds.includes(item.id));
			return (folderId) ? resultList.filter((item) => {
				return item != null
						&& item.id !== folderId;
			}) : resultList;

		},

		isOwnedFolder () {
			const owner = (this.folder != null && this.folder.owner != null) ? this.folder.owner : null;
			if (!owner || !this.userId) {
				return false;
			}
			return owner.id === this.userId;
		},

		returnPath () {
			if (typeof this.returnOverride === 'string' && this.returnOverride !== '') {
				return this.returnOverride;
			}
			const folderId = this.searchParams && this.searchParams.folderId || '';
			if (typeof folderId !== 'string' || !folderId) {
				return '/';
			} else if (folderId.toUpperCase() === 'PERSONAL') {
				return '/personal';
			} else if (folderId.toUpperCase() === 'SHARES') {
				return '/shares';
			} else {
				return '/d/' + encodeURIComponent(folderId);
			}
		},
		canDownload() {
			const selection = this.selection;
			return (selection.length === 1 && selection[0].type === 'FILE');
		},

	},

	methods: {
		...mapActions(useRootStore, {
			SET_CHILD_VIEW_STYLE: 'SET_CHILD_VIEW_STYLE',
			CONFIRM_DELETE_ITEMS: 'CONFIRM_DELETE_ITEMS',
			CONFIRM_REMOVE_SHARE: 'CONFIRM_REMOVE_SHARE',
			EDIT_SHARE_MEMBERS: 'EDIT_SHARE_MEMBERS',
			GET_SEARCH_RESULTS: 'GET_SEARCH_RESULTS'
		}),

		isItemChildOfShares(item) {
			if (item == null) {
				return false;
			}
			return this.sharesSiloId === item.parentFolderId;
		},

		areAllItemsInShareSilo(items) {
			if (!Array.isArray(items)) {
				return false;
			}
			return items.every((item) => {
				return this.isItemChildOfShares(item);
			});
		},

		setSearchResult(err, searchResult) {
			this.loadError = err;
			this.searchResult = searchResult;
		},

		canDownloadItems(items) {
			return Array.isArray(items)
				&& items.some((item) => item != null && item.type === 'FILE');
		},

		downloadFiles (items) {
			const files = (!Array.isArray(items) || !items.length) ? []:
				items.filter((item) => item.type === 'FILE');
			if (!files.length) {
				return;
			}

			files.forEach((file)=>{
				driveService.downloadFile(file.id);
			});

		},

		openItem(item) {
			if (!item || !item.id) {
				return;
			}
			if (item.type === 'FOLDER') {
				this.$router.push('/d/' + encodeURIComponent(item.id));
			} else {
				this.$router.push('/f/' + encodeURIComponent(item.id));
			}
		},

		canEdit(item) {
			if (!item || !item.id) {
				return false;
			}
			return item.permissions.edit;
		},

		canEditItems(items) {
			return Array.isArray(items)
				&& items.every((item) => item.permissions.edit);
		},

		deleteItems (items) {
			if (!Array.isArray(items) || !items.length) {
				return;
			}
			this.CONFIRM_DELETE_ITEMS(items)
				.then(() => {
					// reset selection and edit context
					this.selection = [];
					this.editContext = null;
				})
				.catch((reason) => {
					console.log('Deletion Cancelled');
				});

		},

		removeMember (item) {
			this.CONFIRM_REMOVE_SHARE(item)
				.then(() => {
					this.itemSelection([]);
					this.editContext = null;
				})
				.catch((reason) => {
					console.log('Share Removal Cancelled');
				});
		},

		startItemRename: function (item) {
			return this.$refs.listView.startItemRename(item);
		},

		onContextMenu(event) {
			if (!event) {
				return;
			}
			this.$refs.selectionContextMenu.open(event, {
				data: this.selection,
				close: () => {
					this.$refs.selectionContextMenu.close();
				}
			});
		},

		editItemMembers (item) {
			this.EDIT_SHARE_MEMBERS(item);
		},

		onSearchCleared () {
			if (this.returnPath) {
				this.$router.push(this.returnPath);
			}
		}

	},

	beforeRouteEnter (to, from, next) {

		// TODO: if folderId/Silo is defined, provide "clear search" (or on text cleared) that returns to the folder view

		const search = paramsToSearch(to.query);
		const returnOverride = (typeof to.query.r === 'string') ? to.query.r : '';
		const store = useRootStore();

		store.GET_SEARCH_RESULTS(search)
			.then((result) => {
				next((vm) => {
					vm.searchParams = search || {};
					vm.returnOverride = returnOverride || '';
					vm.setSearchResult(null, result);
				});
			})
			.catch((err) => {
				next((vm) => {
					vm.searchParams = search || {};
					vm.returnOverride = returnOverride || '';
					vm.setSearchResult(err, null);
				});
			});
	},

	beforeRouteUpdate (to, from, next) {
		this.searchResult = null;
		this.loadError = null;

		this.searchParams = {};

		this.editContext = null;
		this.lastSelected = null;
		this.selection = [];

		const search = paramsToSearch(to.query);
		const returnOverride = (typeof to.query.r === 'string') ? to.query.r : '';

		this.GET_SEARCH_RESULTS(search)
			.then((result) => {
				this.searchParams = search || {};
				this.returnOverride = returnOverride || '';
				this.setSearchResult(null, result);
				next();
			})
			.catch((err) => {
				this.searchParams = search || {};
				this.returnOverride = returnOverride || '';
				this.setSearchResult(err, null);
				next();
			});
	},

	mounted() {

	}

};
</script>
