var dragEnterCount = 0;

//add protections to make sure fns is actually an object of functions

function hasFiles(event) {
	var transfer = event && event.dataTransfer;
	return transfer &&
		!!(	transfer.files && transfer.files.length > 0 ||
			transfer.types && Array.prototype.slice.call(transfer.types).indexOf('Files') >= 0);
}

function handleErrors(element, fns) {
	if (!element) {
		throw new Error('element cannot be null or undefined');
	}
	if (fns && typeof fns.onFileEnter !== 'function') {
		throw new Error('onFileEnter must be a function');
	}
	if (fns && typeof fns.onFileLeave !== 'function') {
		throw new Error('onFileLeave must be a function');
	}
	if (fns && typeof fns.onFileDrop !== 'function') {
		throw new Error('onFileDrop must be a function');
	}
}

function onDragEnter(event, fns) {
	if (hasFiles(event)) {
		event.preventDefault();
		event.stopPropagation();
		//Pass event?
		fns.onFileEnter();
		dragEnterCount++;
	}
};

function onDragLeave(event, fns) {
	if (hasFiles(event)) {
		event.preventDefault();
		event.stopPropagation();
		//BUG: When moving mouse around erratically Doesn't always match up to amount of increments and can get stuck above 0
		dragEnterCount--;
		if (dragEnterCount <= 0) {
			fns.onFileLeave();
		}
	}
}

function onDragOver(event) {
	if (hasFiles(event)) {
		event.dataTransfer.dropEffect =  'copy';
		event.preventDefault();
		event.stopPropagation();
	}
}

function onDragDrop(event, fns) {
	if (hasFiles(event)) {
		event.preventDefault();
		event.stopPropagation();
		dragEnterCount = 0;
		const transfer = event.dataTransfer;

		if (!transfer.items || !transfer.items.length) {
			return;
		}

		if (transfer.items.length > 0) {
			//read files before directories
			Array.from(transfer.items).forEach(item => {
				const entry = item.webkitGetAsEntry();
				if (!entry) {
					// References to files on a users icloud account look similar to original files, but doesn't contain file data.
					//TODO: Error handling paths for all upload errors
					const file = item.getAsFile();
					fns.onFileDrop([file]);
					console.error(`File data is not available. It may be a reference to your data elsewhere. File size ${file.size}`);
					return;
				}
				readEntryContent(entry)
					.then((entryContent) => {
						if (entryContent.length > 0) {
							fns.onFileDrop(entryContent);
						}
					}, (reason) => {
						//TODO: handle reason
						fns.onFileDrop(entry);
					});
			});
		}}
}

function readEntryContent(entry) {
	return new Promise((resolve, reject) => {
		const contents = [];
		let reading = 0;
		function readEntry(entry) {
			if (entry && entry.isFile) {
				reading++;
				entry.file(file => {
					reading--;
					contents.push(file);
					if (reading === 0) {
						resolve(contents);
					}
				});

			} else if (entry && entry.isDirectory) {
				throw new Error('DIRECTORY_UPLOAD_NOT_SUPPORTED');
			} else {
				reject(new Error('File type or size not supported'));
			}
		}
		readEntry(entry, null);
	});
}


function createFileDropZone(element, fns) {
	handleErrors(element, fns);
	element.addEventListener('dragenter', (event) => onDragEnter(event, fns), true);
	element.addEventListener('dragover', (event) => onDragOver(event), true);
	element.addEventListener('dragleave', (event) => onDragLeave(event, fns), true);
	element.addEventListener('drop', (event) => onDragDrop(event,fns), true);
}

function removeFileDropZone(element, fns) {
	handleErrors(element);
	element.removeEventListener('dragenter', (event) => onDragEnter(event, fns), true);
	element.removeEventListener('dragleave', (event) => onDragOver(event), true);
	element.removeEventListener('drop', (event) => onDragDrop(event,fns), true);
}

export { createFileDropZone, removeFileDropZone };
