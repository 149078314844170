import { defineStore } from 'pinia';
import driveService from '@/modules/drive-service';
import { promiser } from '@/modules/promise-util';
import fileWatchService from '@/modules/file-watch-service';

export const useRootStore = defineStore('rootStore', {
	state: () => ({
		childViewStyle: localStorage.getItem('childViewStyle') || 'list',

		query: {
			sortOrder: [],
			parentFolderId: 0 / 0,
			searchDepth: 1,
			searchName: ''
		},

		memberEditContext: null,
		deleteConfirmContext: null,
		removeShareConfirmContext: null,
		permanentlyDeleteConfirmContext: null,
		emptyTrashConfirmContext: null,
		launchConfirmContext: null,
		uploadLimitContext: null,
		uploadRefusedContext: null,
		createFolderRefusedContext: null,

		updating: false,

		// All files/folders, no particular order
		list: [],

		trashList: [],

		webhookList: [],

		silos: Object.create(null),

		userInfo: null,

		userStats: {
			totalBytesOwned: 0,
			filesOwnedCount: 0,
			totalBytesCreated: 0,
			filesCreatedCount: 0,
			storageSizeLimit: 0,
			percentSizeLimit: 0
		},

		noticeFlags: {
			overStorageNotice: true,
			overStorageWarning: true
		},

		accessKeys: [],

		// TODO: move this to a module (constant)
		accessKeyClaimOptions: [
			{ claim: 'drive:api:directory:create', description: 'Create files and folders. Upload file contents.' },
			{ claim: 'drive:api:directory:read', description: 'Get file and folder details. Download file contents. Search and browse the directory tree.' },
			{ claim: 'drive:api:directory:update', description: 'Update file and folder details and contents.' },
			{ claim: 'drive:api:directory:delete', description: 'Delete files and folders.' },
			{ claim: 'drive:api:user:details:self', description: 'View your account details.' },
			{ claim: 'drive:api:user:analytics:self', description: 'View storage and other usage data for your account.' }
		]
	}),
	getters: {
		folders: state => state.list.filter(folder => folder.type === 'FOLDER'),
	},
	actions: {
		UPDATE_USER_INFO() {
			return driveService.getUserInfo()
				.then((info) => {
					this.userInfo = info || {};
					return info;
				});
		},

		UPDATE_USER_STATS() {
			return driveService.getUserStats()
				.then((stats) => {
					this.SET_USER_STATS(stats);
					return stats;
				});
		},

		START_FILE_WATCHER () {
			return Promise.resolve(fileWatchService.start({ folderId: '*', events: '*' }));
		},

		SCAN_UPDATE_USER_STATS() {
			// NOTE: THIS IS A TEMP FIX FOR STORAGE USAGE

			const list = this.list;
			const prevStats = this.userStats || {};
			const stats = {
				totalBytesOwned: 0,
				filesOwnedCount: 0,
				totalBytesCreated: 0,
				filesCreatedCount: 0,
				storageSizeLimit: prevStats.storageSizeLimit || 0
			};
			const userId = this.userInfo && this.userInfo.id || '';
			if (Array.isArray(list)) {
				list.reduce((stats, item) => {
					if (item && item.type === 'FILE' && isFinite(item.bytes)) {
						// Append owned item
						if (item.owner && item.owner.id === userId) {
							stats.totalBytesOwned += item.bytes;
							stats.filesOwnedCount += 1;
						}
						// Append created item
						if (item.creator && item.creator.id === userId) {
							stats.totalBytesCreated += item.bytes;
							stats.filesCreatedCount += 1;
						}
					}
					return stats;
				}, stats);

				this.SET_USER_STATS(stats);
			}
			return Promise.resolve(stats);;
		},

		UPDATE_ACCESS_KEYS() {
			return driveService.listAccessKeys()
				.then((result) => {
					if (Array.isArray(result)) {
						this.accessKeys = result;
					}
					return result;
				});

		},

		CREATE_ACCESS_KEY(config) {
			if (!config) {
				return Promise.reject(new Error('Invalid Argument'));
			}
			return driveService.createAccessKey(config.name, config.claims)
				.then((result) => {
					this.ADD_ACCESS_KEY(result.accessKey);
					// NOTE: Response includes generated key
					return result;
				});

		},

		GET_ACCESS_KEY(accessKeyId) {
			const list = this.accessKeys;
			const accessKey = list.find((item) => {
				return item && item.id === accessKeyId;
			});
			if (accessKey) {
				// Found in Memory
				return Promise.resolve(accessKey);
			}
			// get it from the API
			return this.UPDATE_ACCESS_KEYS()
				.then(() => {
					const list = this.accessKeys;
					const accessKey = list.find((item) => {
						return item && item.id === accessKeyId;
					});
					if (accessKey) {
						return accessKey;
					}
					return Promise.reject(new Error('Not Found'));
				});
		},

		REVOKE_ACCESS_KEY(accessKeyId) {
			if (!accessKeyId) {
				return Promise.reject('Invalid Access Key');
			}
			return driveService.deleteAccessKey(accessKeyId)
				.then(() => {
					this.REMOVE_ACCESS_KEY_BY_ID(accessKeyId);
				});
		},

		REMOVE_ACCESS_KEY_BY_ID(id) {
			const idx = this.accessKeys.findIndex((item) => {
				return item != null && item.id === id;
			});
			if (idx >= 0) {
				this.accessKeys.splice(idx, 1);
			}
		},

		GET_SEARCH_RESULTS(searchParams) {
			return driveService.search(searchParams)
				.then((result) => {
					// remove silos from search results
					const list = result.list.filter((item) => {
						return item != null &&
							item.parentFolderId;
					});

					return {
						list: list,
						page: result.page || 0,
						limit: result.limit || list.length || 0,
						offset: result.offset || 0
					};;
				});
		},

		GET_INITIAL_DIRECTORY() {
			// GET Silos and recent files
			return driveService.search({orderBy: ['-updatedAt']})
				.then(async (result) => {
					this.SET_DIRECTORY_LIST(result.list);
					this.GET_SILO_MAP();
					return result.list;
				});
		},

		GET_SILO_MAP() {
			return driveService.silos()
				.then((siloMap) => {
					this.silos = siloMap || Object.create();
				});
		},

		SET_DIRECTORY_LIST(list) {
			if (Array.isArray(list)) {
				this.list = list;
			}
		},

		SET_ITEM_NAME({ item, name }) {
			if (item && typeof name === 'string' && item.name !== name) {
				this.SET_ITEM_NAME_STATE({ item, name });

				const job = (item.type === 'FOLDER') ?
					driveService.updateFolder(item.id, { name }) :
					driveService.updateFile(item.id, { name });

				return job.then((response) => {
					// commit response to update meta in state (i.e. updatedAt)
					if (response.type === 'FILE') {
						this.ADD_FILE(response);
					}
					return Promise.resolve(item);
				});
			}
		},

		SET_ITEM_NAME_STATE({ item, name }) {
			if (item && typeof name === 'string') {
				// TODO: If the item.name changes, should the item.type be updated as well?
				item.name = name;
			}
		},


		GET_TREE_PATH(item) {
			return driveService.treePath(item)
				.then((hierarchy) => {
					this.SET_TREE_PATH({ item, hierarchy });
					return hierarchy;
				});
		},

		SET_TREE_PATH({ item, hierarchy }) {
			item.hierarchy = hierarchy;
		},

		GET_FILE(fileId) {
			return driveService.getFile(fileId)
				.then((file) => {
					return driveService.getFileDownloadUrl(file)
						.then((url) => {
							file.downloadUrl = url;
							this.ADD_FILE(file);
							return Promise.resolve(file);
						});
				});
		},

		GET_FOLDER_TREE(folderId) {
			return driveService.tree(folderId);
		},

		GET_FOLDER_NODE(folderId) {
			// TODO: ensure folder meta & children in memory
			// NOTE: This may always query for meta or child changes
			// TODO: update folder meta, review folder children (check for complete list and update/replace current list)
			// NOTE: updating list may reveal removed children (e.g., if mem contains a child not included in API's child list response)

			return driveService.tree(folderId, 1)
				.then((meta) => {
					this.ADD_FOLDER(meta);
					// TODO: get cached folder instead of meta object

					// ensure all immediate children
					const children = meta.children;
					if (Array.isArray(children)) {
						children.forEach((child) => {
							if (child.type === 'FOLDER') {
								this.ADD_FOLDER(child);
							} else {
								this.ADD_FILE(child);
							}
						});
					}

					return meta;
				});
		},

		CREATE_FOLDER(config) {
			// TODO: Create partial before submitting
			return driveService.createFolder(config)
				.then((folder) => {
					this.ADD_FOLDER(folder);
					return folder;
				})
				.catch((reason) => {
					const errorCode = reason && reason.response && reason.response.data && reason.response.data.errorCode || '';
					const resolvable = promiser();

					this.SET_CREATE_FOLDER_REFUSED_CONTEXT({ reason: errorCode, resolvable });

					// return a promise for the completion of the popover
					return resolvable.promise
						.then(() => {
							// clear the context and reject
							this.SET_CREATE_FOLDER_REFUSED_CONTEXT(null);
							return Promise.reject(reason);
						}, () => {
							// clear the context and reject
							this.SET_CREATE_FOLDER_REFUSED_CONTEXT(null);
							return Promise.reject(reason);
						});
				});
		},

		DELETE_FILE(file) {
			const id = (typeof file === 'string') ? file : file && typeof file.id === 'string' ? file.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.deleteFile(id, false)
				.then(() => {
					this.REMOVE_ITEM_BY_ID(id);
				})
				.catch((reason) => {
					console.log('Delete Failed:', id);
					throw reason;
				});

		},

		DELETE_FAILED_UPLOAD(file) {
			const id = (typeof file === 'string') ? file : file && typeof file.meta.id === 'string' ? file.meta.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.deleteFile(id, false)
				.then(() => {
					this.REMOVE_ITEM_BY_ID(id);
				})
				.catch((reason) => {
					console.log('Delete Failed:', id);
					throw reason;
				});
		},

		DELETE_FOLDER(folder) {
			const id = (typeof folder === 'string') ? folder : folder && typeof folder.id === 'string' ? folder.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.deleteFolder(id, false)
				.then(() => {
					this.REMOVE_ITEM_BY_ID(id);
				})
				.catch((reason) => {
					console.log('Delete Failed:', id);
					throw reason;
				});
		},

		LIST_WEBHOOKS() {
			return driveService.listWebhooks()
				.then((list) => {
					this.SET_WEBHOOK_LIST(list);
				});
		},

		DELETE_WEBHOOK(webhook) {
			const id = webhook.id;
			return driveService.deleteWebhook(id)
				.then(() => {
					this.REMOVE_WEBHOOK_FROM_LIST(id);
				});
		},

		CONFIRM_DELETE_ITEMS(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_DELETE_CONTEXT({ items, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_DELETE_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_DELETE_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		CONFIRM_REMOVE_SHARE(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_REMOVE_SHARE_CONTEXT({ items, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_REMOVE_SHARE_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_REMOVE_SHARE_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		DELETE_ITEMS(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			function isWebhook(item) {
				return item && !item.type && item.httpMethod && item.url;
			}
			// Delete all items
			//resolving here because vuex action dispatch resolved promise and pinia doesn't
			return Promise.resolve(items.map((item) => {
				if (item && item.type === 'FOLDER') {
					return this.DELETE_FOLDER(item);
				} else if (item && item.type === 'FILE') {
					return this.DELETE_FILE(item);
				} else if (isWebhook(item)) {
					return this.DELETE_WEBHOOK(item);
				} else if (item && item.type === 'UPLOAD') {
					this.DELETE_FAILED_UPLOAD(item);
				}
				return null;
			}));
		},

		CONFIRM_LAUNCH_ITEM(file) {
			if (file == null) {
				return Promise.reject(new Error('Invalid Launch Item'));
			}

			// TODO: Move this to a static location
			const BYTESIZE_CONFIRM_THRESHOLD = 1e8; // 100 MB
			const byteSize = +file.bytes;
			// This is a very simple assumption that a large file will have excessive data
			if (byteSize < BYTESIZE_CONFIRM_THRESHOLD) {
				// This file is small enough to just launch....
				return Promise.resolve(file);
			}

			// The file is pretty large, let's warn the user...

			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_LAUNCH_CONTEXT({ item: file, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_LAUNCH_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_LAUNCH_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		EDIT_SHARE_MEMBERS(item) {
			if (item == null || typeof item.id !== 'string' || typeof item.type !== 'string') {
				return Promise.reject(new Error('Invalid Directory Item'));
			}
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_MEMBER_CONTEXT({ item, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_MEMBER_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_MEMBER_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		MOVE_ITEMS({ items, parentFolderId }) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Items List'));
			}
			if (typeof parentFolderId !== 'string') {
				return Promise.reject(new Error('Invalid Parent Folder ID'));
			}
			return Promise.all(items.map((item) => {
				this.MOVE_ITEM({ item, parentFolderId });
				return (item.type === 'FOLDER') ?
					driveService.updateFolder(item.id, { parentFolderId }) :
					driveService.updateFile(item.id, { parentFolderId });
			}));
		},

		MOVE_ITEMS_TO_PERSONAL(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Items List'));
			}
			const pFolder = this.list.find((item) => item.folderType === 'PERSONAL');
			const job = (pFolder == null) ? driveService.tree('PERSONAL', 1) : Promise.resolve(pFolder);

			return job.then((personalFolder) => {
				var parentFolderId = personalFolder.id;

				return Promise.all(items.map((item) => {
					this.MOVE_ITEM({ item, parentFolderId });
					return (item.type === 'FOLDER') ?
						driveService.updateFolder(item.id, { parentFolderId }) :
						driveService.updateFile(item.id, { parentFolderId });
				}));
			});
		},

		GET_SHARE_MEMBERS(item) {
			const type = item && item.type || '';
			if (type === 'FOLDER') {
				return driveService.getFolderMembers(item.id);
			} else if (type) {
				return driveService.getFileMembers(item.id);
			} else {
				return Promise.reject(new Error('Unexpected Item Type'));
			}

		},

		SET_MEMBERS({ item, members }) {
			const type = item && item.type || '';
			if (item == null || !Array.isArray(members)) {
				return Promise.reject(new Error('Invalid Drive Item'));
			}
			if (!Array.isArray(members)) {
				return Promise.reject(new Error('Invalid Members'));
			}
			if (type === 'FOLDER') {
				return driveService.setFolderMembers(item.id, members);
			} else if (type === 'FILE') {
				return driveService.setFileMembers(item.id, members);
			} else {
				return Promise.reject(new Error('Invalid Type'));
			}
		},

		REMOVE_MEMBER(items) {
			// const type = item && item.type || '';
			const userId = this.userInfo && this.userInfo.id || '';
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}

			// Remove all shared items
			return Promise.all(items.map((item) => {
				if (item && item.type === 'FOLDER') {
					return driveService.deleteFolderMember(item.id, userId)
						.then(() => {
							this.REMOVE_ITEM_BY_ID(item.id);
						});
				} else if (item && item.type === 'FILE') {
					return driveService.deleteFileMember(item.id, userId)
						.then(() => {
							this.REMOVE_ITEM_BY_ID(item.id);
						});
				}
			}));
		},

		DISMISS_STORAGE_EXCEEDED_NOTICE() {
			this.DISMISS_STORAGE_EXCEEDED_NOTICE;
		},

		DISMISS_STORAGE_EXCEEDED_WARNING() {
			this.DISMISS_STORAGE_EXCEEDED_WARNING;
		},

		GET_TRASH_LIST(orderBy) {
			return driveService.listTrash(orderBy)
				.then((resp) => {
					this.SET_TRASH_LIST(resp);
					return Promise.resolve(resp);
				});
		},

		RESTORE_ITEMS(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			// Permanently Delete all items
			return Promise.all(items.map((item) => {
				if (item && item.type === 'FOLDER') {
					return this.RESTORE_FOLDER(item);
				} else if (item && item.type === 'FILE') {
					return this.RESTORE_FILE(item);
				}
				return null;
			}));
		},

		RESTORE_FILE(file) {
			const id = (typeof file === 'string') ? file : file && typeof file.id === 'string' ? file.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.restoreFile(id)
				.then(() => {
					this.REMOVE_ITEM_FROM_TRASH(id);
					//this.dispatch('SCAN_UPDATE_USER_STATS');
					return file;
				})
				.catch((reason) => {
					console.error('Restore Failed:', id, reason);
					throw reason;
				});
		},

		RESTORE_FOLDER(folder) {
			const id = (typeof folder === 'string') ? folder : folder && typeof folder.id === 'string' ? folder.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.restoreFolder(id)
				.then(() => {
					//this.RESTORE_ITEM_BY_ID(id);
					//this.dispatch('SCAN_UPDATE_USER_STATS');
					return folder;
				})
				.catch((reason) => {
					console.error('Restore Failed:', id, reason);
					throw reason;
				});
		},

		CONFIRM_EMPTY_TRASH() {
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_EMPTY_TRASH_CONTEXT({ resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_EMPTY_TRASH_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_EMPTY_TRASH_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		EMPTY_TRASH() {
			return driveService.emptyTrash()
				.then(() => {
					this.EMPTY_TRASH_LIST();
				}, (reason) => {
					return Promise.reject(reason);
				});
		},

		CONFIRM_PERMANENTLY_DELETE_ITEMS(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			this.SET_PERMANENTLY_DELETE_CONTEXT({ items, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					this.SET_PERMANENTLY_DELETE_CONTEXT(null);
					return value;
				}, (reason) => {
					// clear the context
					this.SET_PERMANENTLY_DELETE_CONTEXT(null);
					return Promise.reject(reason);
				});
		},

		PERMANENTLY_DELETE_ITEMS(items) {
			if (!Array.isArray(items)) {
				return Promise.reject(new Error('Invalid Directory Items'));
			}
			// Permanently Delete all items
			return Promise.all(items.map((item) => {
				if (item && item.type === 'FOLDER') {
					return this.PERMANENTLY_DELETE_FOLDER(item);
				} else if (item && item.type === 'FILE') {
					return this.PERMANENTLY_DELETE_FILE(item);
				}
				return null;
			}));
		},

		PERMANENTLY_DELETE_FILE(file) {
			const id = (typeof file === 'string') ? file : file && typeof file.id === 'string' ? file.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.deleteFile(id, true)
				.then(() => {
					this.REMOVE_TRASH_ITEM_BY_ID(id);
					this.SCAN_UPDATE_USER_STATS();
				})
				.catch((reason) => {
					console.error('Delete Failed:', id, reason);
					throw reason;
				});
		},

		PERMANENTLY_DELETE_FOLDER(folder) {
			const id = (typeof folder === 'string') ? folder : folder && typeof folder.id === 'string' ? folder.id : '';
			if (!id) {
				return Promise.reject(new Error('Invalid ID'));
			}

			return driveService.deleteFolder(id, true)
				.then(() => {
					this.REMOVE_TRASH_ITEM_BY_ID(id);
					this.SCAN_UPDATE_USER_STATS();
				})
				.catch((reason) => {
					console.error('Delete Failed:', id, reason);
					throw reason;
				});
		},

		//mutations

		SET_WEBHOOK_LIST(webhooks) {
			this.webhookList = webhooks.sort((a,b) => b.updatedAt - a.updatedAt);
		},

		REMOVE_WEBHOOK_FROM_LIST(webhookId) {
			this.webhookList = this.webhookList.filter(webhook => webhook.id !== webhookId);
		},

		SET_CHILD_VIEW_STYLE(style) {
			if (style) {
				this.childViewStyle = style;
				localStorage.setItem('childViewStyle', style);
			}
		},

		SET_USER_STATS(stats) {
			if (stats == null) {
				stats = {};
			}
			const storageSizeLimit = +stats.storageSizeLimit || 0;
			const totalBytesOwned = +stats.totalBytesOwned || 0;

			var percentSizeLimit = 0;
			if (storageSizeLimit === 0 && totalBytesOwned > 0) {
				// Over on zero byte limit
				percentSizeLimit = Infinity;

			} else if (storageSizeLimit > 0 && totalBytesOwned >= 0 && isFinite(storageSizeLimit) && isFinite(totalBytesOwned)) {
				// Over on (positive) byte limit
				percentSizeLimit = totalBytesOwned / storageSizeLimit;

			}

			//If stats set to {} then values will be NaN without the OR check
			this.userStats = {
				totalBytesOwned: totalBytesOwned,
				filesOwnedCount: +stats.filesOwnedCount || 0,

				storageSizeLimit: storageSizeLimit,
				percentSizeLimit: percentSizeLimit,

				totalBytesCreated: +stats.totalBytesCreated || 0,
				filesCreatedCount: +stats.filesCreatedCount || 0
			};
		},

		ADD_ACCESS_KEY(accessKey) {
			if (!accessKey || !accessKey.id) {
				return;
			}

			const accessKeys = this.accessKeys || [];

			const id = accessKey && accessKey.id;
			const item = accessKeys.find((item) => {
				return item != null && item.id === id;
			});

			if (item) {
				item.ownerId = accessKey.ownerId || '';
				item.keyType = accessKey.keyType || '';
				item.name = accessKey.name || '';
				item.createdAt = accessKey.createdAt || null;
				item.updatedAt = accessKey.updatedAt || null;
				item.claims = Array.isArray(accessKey.claims) ? accessKey.claims : [];

			} else {
				// APPEND
				accessKeys.push(accessKey);
			}
		},

		ADD_FILE(file) {
			if (!file) {
				return null;
			}
			// TODO: ensure file id is not already in list
			const fileId = file && file.id;
			const item = this.list.find((item) => {
				return item != null && item.id === fileId;
			});

			if (item) {
				// UPDATE

				// TODO: validate input file
				item.name = file.name;
				item.parentFolderId = file.parentFolderId;

				item.uploadState = file.uploadState;
				item.bytes = file.bytes;

				item.createdAt = file.createdAt;
				item.modifiedAt = file.modifiedAt;
				item.updatedAt = file.updatedAt;
				item.sharedAt = file.sharedAt;
				item.downloadUrl = item.downloadUrl || file.downloadUrl;
				return item;

			}

			// Append
			this.list.push(file);

			return file;
		},

		SET_TRASH_LIST(payload) {
			function trashItemToViewItem(trashItem) {
				if (trashItem == null) {
					return null;
				}
				const item = trashItem.item;
				if (item == null) {
					return null;
				}
				return {
					id: trashItem.itemId,
					name: item.name,
					type: item.type,
					shareCount: trashItem.itemShareCount | 0,
					bytes: trashItem.totalBytes | 0,
					owner: item.owner,
					trashedDirectly: trashItem.trashedDirectly === true,
					folderType: item.folderType,
					fileType: item.fileType,
					trashedAt: item.trashedAt,
					trashItem: trashItem,
					thumbnails: item.thumbnails || {}
				};
			}
			const list = (payload && Array.isArray(payload.list)) ? payload.list : [];
			this.trashList = list.map(trashItemToViewItem).filter(Boolean);
		},

		ADD_THUMBNAIL(file) {
			const fileId = file.fileId;
			const fileIndex = this.list.findIndex((obj) => obj.id === fileId);
			const size = file.size;
			this.list[fileIndex].thumbnails[size]={ id: file.thumbnail.id, url: file.thumbnail.url };
		},

		UPDATE_FILE(file) {
			const item = this.list.find((item) => {
				return item != null && item.id === file.id;
			});
			if (item) {
				item.name = file.name;
				item.uploadState = file.uploadState;
				item.bytes = file.bytes;

				item.createdAt = file.createdAt;
				item.modifiedAt = file.modifiedAt;
				item.sharedAt = file.sharedAt;
				item.updatedAt = file.updatedAt;

				return item;
			}

			return file;
		},

		UPDATE_FOLDER(folder) {
			const item = this.list.find((item) => {
				return item != null && item.id === folder.id;
			});
			if (item) {
				item.name = folder.name;

				item.createdAt = folder.createdAt;
				item.modifiedAt = folder.modifiedAt;
				item.sharedAt = folder.sharedAt;
				item.updatedAt = folder.updatedAt;

				return item;
			}

			return folder;
		},

		ADD_FOLDER(folder) {
			if (!folder) {
				return null;
			}
			// TODO: ensure file id is not already in list
			const id = folder && folder.id;
			const item = this.list.find((item) => {
				return item != null && item.id === id;
			});

			if (item) {
				// UPDATE

				// TODO: validate input file
				item.name = folder.name;
				item.parentFolderId = folder.parentFolderId;

				item.createdAt = folder.createdAt;
				item.modifiedAt = folder.modifiedAt;
				item.sharedAt = folder.sharedAt;
				item.updatedAt = folder.updatedAt;

				return item;
			}
			// APPEND
			this.list.push(folder);

			return folder;
		},

		REMOVE_TRASH_ITEM_BY_ID(id) {
			const idx = this.trashList.findIndex((item) => {
				return item != null && item.id === id;
			});
			if (idx >= 0) {
				this.trashList.splice(idx, 1);
			}
		},

		ADD_ITEM_TO_TRASH(id) {
			const file = this.list.filter((item) => item.id === id);
			if (file) {
				this.trashList = this.trashList.concat(file);
			}
		},

		REMOVE_ITEM_BY_ID(id) {
			// Remove item. If item is a folder, remove all descendents as well
			const idx = this.list.findIndex((item) => {
				return item != null && item.id === id;
			});
			if (idx >= 0) {
				this.list.splice(idx, 1);
			}

			function getDescendentIds(list, folderId) {
				if (!folderId || !Array.isArray(list)) {
					return [];
				}
				return list.reduce((idList, item) => {
					if (item && item.parentFolderId === folderId) {
						idList.push(item.id);
						if (item.type === 'FOLDER') {
							// TODO: prevent recursion
							idList = idList.concat(getDescendentIds(list, item.id));
						}
					}
					return idList;
				}, []);
			}

			const descendentIds = getDescendentIds(this.list, id);
			if (descendentIds.length > 0) {
				const idMap = descendentIds.reduce((map, id) => {
					map[id] = true;
					return map;
				}, {});
				// remove all mapped ids from list
				this.list = this.list.filter((item) => {
					return item != null && !idMap.hasOwnProperty(item.id);
				});
			}
		},

		REMOVE_ITEM_FROM_TRASH(id) {
			const idx = this.trashList.findIndex(item => item.id === id);
			this.trashList.splice(idx, 1);
		},

		SET_MEMBER_CONTEXT(context) {
			if (context == null || context.item == null || context.resolvable == null) {
				this.memberEditContext = null;
			} else {
				this.memberEditContext = {
					item: context.item,
					resolvable: context.resolvable
				};
			}
		},

		SET_REMOVE_SHARE_CONTEXT(context) {
			if (context == null || !Array.isArray(context.items) || context.resolvable == null) {
				this.removeShareConfirmContext = null;
			} else {
				this.removeShareConfirmContext = {
					items: context.items,
					resolvable: context.resolvable
				};
			}
		},

		SET_DELETE_CONTEXT(context) {
			if (context == null || !Array.isArray(context.items) || context.resolvable == null) {
				this.deleteConfirmContext = null;
			} else {
				this.deleteConfirmContext = {
					items: context.items,
					resolvable: context.resolvable
				};
			}
		},

		SET_EMPTY_TRASH_CONTEXT(context) {
			if (context == null || context.resolvable == null) {
				this.emptyTrashConfirmContext = null;
			} else {
				this.emptyTrashConfirmContext = {
					resolvable: context.resolvable
				};
			}
		},

		EMPTY_TRASH_LIST() {
			this.trashList = [];
		},

		SET_PERMANENTLY_DELETE_CONTEXT(context) {
			if (context == null || !Array.isArray(context.items) || context.resolvable == null) {
				this.permanentlyDeleteConfirmContext = null;
			} else {
				this.permanentlyDeleteConfirmContext = {
					items: context.items,
					resolvable: context.resolvable
				};
			}
		},

		SET_LAUNCH_CONTEXT(context) {
			if (context == null || context.item == null || context.resolvable == null) {
				this.launchConfirmContext = null;
			} else {
				this.launchConfirmContext = {
					item: context.item,
					resolvable: context.resolvable
				};
			}
		},

		SET_UPLOAD_LIMIT_CONTEXT(context) {
			if (context == null || !Array.isArray(context.items) || context.resolvable == null) {
				this.uploadLimitContext = null;
			} else {
				this.uploadLimitContext = {
					items: context.items,
					resolvable: context.resolvable
				};
			}
		},

		SET_CREATE_FOLDER_REFUSED_CONTEXT(context) {
			if (context == null || context.resolvable == null) {
				this.createFolderRefusedContext = null;
			} else {
				this.createFolderRefusedContext = {
					reason: (typeof context.reason === 'string') ? context.reason : '',
					resolvable: context.resolvable
				};
			}
		},

		MOVE_ITEM({ item, parentFolderId }) {
			if (item != null && typeof parentFolderId === 'string') {
				item.parentFolderId = parentFolderId;
			}
		},

		DISMISS_STORAGE_EXCEEDED_NOTICE() {
			this.noticeFlags.overStorageNotice = false;
		},

		DISMISS_STORAGE_EXCEEDED_WARNING() {
			this.noticeFlags.overStorageWarning = false;
		},

	},

});
