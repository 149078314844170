// NOTE: The message is only displayed in legacy browsers
var LEGACY_MESSAGE = 'Changes you made may not be saved. Are you sure you want to leave?';

function onBeforeUnload(event) {
	if (event) {
		event.preventDefault();
		event.returnValue = LEGACY_MESSAGE;
	}
	return LEGACY_MESSAGE;
}

const confirmUnload = {

	updated(el, binding, vnode) {
		window.removeEventListener('beforeunload', onBeforeUnload);
		if (binding.value) {
			window.addEventListener('beforeunload', onBeforeUnload);
		}
	},

	unmounted() {
		window.removeEventListener('beforeunload', onBeforeUnload);
	}

};

export { confirmUnload };
