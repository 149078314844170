import topicService from '@/modules/topic-service';
import { driveItemFromDto } from '@/modules/drive-dto';
import driveService from '@/modules/drive-service';
import ENV_VARS from '@/modules/env-vars';
import { useRootStore } from '../store/rootStore';
import { useAuthStore } from '../store/auth';


let $store = null;

function start(topicFilter) {
	$store = useRootStore();
	const authStore = useAuthStore();
	// this should use topicService init to start socket and kick off first topic
	return new Promise ((resolve, reject) => {
		let refreshToken = authStore.refreshToken;
		topicService.init(refreshToken, ENV_VARS.WEBSOCKET_URL, topicFilter)
			.then(onTopicCreate)
			.catch(reason => {
				return reject(reason);
			});
	});
}

function addTopic(topicFilter) {
	return topicService.createTopic(topicFilter)
		.then(onTopicCreate);
}

function onTopicCreate (topic) {
	function findCacheItem(id) {
		const files = $store.list || [];
		return files.find(item => {
			return item != null &&
				(item.id === id);
		});
	}

	function findTrashCacheItem(id) {
		const files = $store.trashList || [];
		return files.find(item => {
			return item != null &&
                (item.id === id);
		});
	}

	function cacheHasLatest(payload, fileCache) {
		if (fileCache == null) {
			return new Error('No File Cache');
		}
		const diff = payload.diff,
			file = driveItemFromDto(payload.file || payload.folder);
		const changedKeysList = Object.keys(diff).filter(key => {
			// cache and payload are different
			return fileCache[key] !== file[key];
		});
		// updated keys are same as cache
		return changedKeysList < 1;
	}


	function onFileCreated (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const file = findCacheItem(payload.file.id);
		if (file) {
			// return out item already exists
			return;
		}
		// do a GET to ensure parentFolderId is on model
		driveService.getFile(payload.file.id)
			.then(meta => {
				$store.ADD_FILE(driveItemFromDto(meta));
				$store.SCAN_UPDATE_USER_STATS();
			});
	}

	function onFileMetaUpdated (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const file = driveItemFromDto(payload.file);
		const fileCache = findCacheItem(payload.file.id);

		if (cacheHasLatest(payload, fileCache)) {
			// return out item already updated
			return;
		}

		if (file) {
			$store.UPDATE_FILE(file);
		}
		$store.SCAN_UPDATE_USER_STATS();
	}

	function onFileTrashed (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const file = driveItemFromDto(payload.file);
		const fileCache = findCacheItem(payload.file.id);
		const fileTrashCache = findTrashCacheItem(payload.file.id);

		if (!fileTrashCache) {
			$store.ADD_ITEM_TO_TRASH(file.id);
		}

		if (fileCache) {
			$store.REMOVE_ITEM_BY_ID(file.id);
		}

	}
	function onFileDeleted (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}

		const file = driveItemFromDto(payload.file);
		const fileTrashCache = findTrashCacheItem(payload.file.id);

		if (fileTrashCache) {
			$store.REMOVE_ITEM_FROM_TRASH(file.id);
		}
		$store.SCAN_UPDATE_USER_STATS();
	}


	function onFileRestored (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const file = driveItemFromDto(payload.file);
		const fileCache = findCacheItem(payload.file.id);
		const fileTrashCache = findTrashCacheItem(payload.file.id);

		if (!fileCache) {
			driveService.getFile(file.id, {thumbnailSizes: '64x64,256x256'})
				.then(meta => {
					$store.ADD_FILE(meta);
				});
		}

		if (fileTrashCache) {
			$store.REMOVE_ITEM_FROM_TRASH(file.id);
		}

	}

	function onFolderCreated(msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const folder = findCacheItem(payload.folder.id);
		if (folder) {
			// return out item already exists
			return;
		}
		// do a GET to ensure parentFolderId is on model
		driveService.getFolder(payload.folder.id)
			.then(meta => {
				$store.ADD_FOLDER(driveItemFromDto(meta));
			});
	}

	function onFolderMetaUpdated (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const folder = driveItemFromDto(payload.folder);
		const folderCache = findCacheItem(payload.folder.id);

		if (cacheHasLatest(payload, folderCache)) {
			// return out item already updated
			return;
		}

		if (folder) {
			$store.UPDATE_FOLDER(folder);
		}
		$store.SCAN_UPDATE_USER_STATS();
	}

	function onFolderDeleted (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}

		const folder = driveItemFromDto(payload.folder);
		const folderTrashCache = findTrashCacheItem(payload.folder.id);

		if (folderTrashCache) {
			$store.REMOVE_ITEM_FROM_TRASH(folder.id);
		}
		$store.SCAN_UPDATE_USER_STATS();
	}

	function onFolderTrashed (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const folder = driveItemFromDto(payload.folder);
		const folderCache = findCacheItem(payload.folder.id);
		const folderTrashCache = findTrashCacheItem(payload.folder.id);

		// if trash doesn't include file add it now
		if (!folderTrashCache) {
			$store.ADD_ITEM_TO_TRASH(folder.id);
		}

		if (folderCache) {
			$store.REMOVE_ITEM_BY_ID(folder.id);
		}
	}


	function onFolderRestored (msg) {
		const payload = msg.payload;
		if (payload == null) {
			return new Error('Invalid Payload');
		}
		const folder = driveItemFromDto(payload.folder);
		const folderCache = findCacheItem(payload.folder.id);
		const folderTrashCache = findTrashCacheItem(payload.folder.id);

		if (!folderCache) {
			driveService.getFolder(folder.id)
				.then(meta => {
					$store.ADD_FOLDER(meta);
				});
		}
		// if trash includes file remove it
		if (folderTrashCache) {
			$store.REMOVE_ITEM_FROM_TRASH(folder.id);
		}
	}

	function onThumbnailCreated (msg) {
		const file = findCacheItem(msg.payload.fileId);
		if (file) {
			$store.ADD_THUMBNAIL(msg.payload);
		}
	}

	topic.on('FileCreated', onFileCreated);
	topic.on('FileMetaUpdated', onFileMetaUpdated);
	topic.on('FileTrashed', onFileTrashed);
	topic.on('FileRestored', onFileRestored);
	topic.on('FileDeleted', onFileDeleted);

	topic.on('FolderCreated', onFolderCreated);
	topic.on('FolderMetaUpdated', onFolderMetaUpdated);
	topic.on('FolderDeleted', onFolderDeleted);
	topic.on('FolderTrashed', onFolderTrashed);
	topic.on('FolderRestored', onFolderRestored);

	topic.on('ThumbnailCreated', onThumbnailCreated);

	return topic;
}

export default {
	start,
	addTopic
};
