<style scoped>
.loading-message {
	margin: 3em;
	text-align: center;
	font-size: 2em;
	color: #ccc;
	color: var(--loading-message-color);
}
.empty-state {
	margin: 3em 0;
	text-align: center;
	color: #bbb;
	color: var(--empty-state-color);
	padding: 2em;
}
.empty-state-header {
	font-size: 2em;
}

.content-header {
  flex: 0 0 auto;
}

.notice-bar {
  width: 100%;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.success {
  background: #ebfff7;
  background: var(--success-background-color);
  border: 1px solid #cdfeec;
  border: 1px solid var(--success-border-color);
  border-radius: 3px;
  color: #0B754E;
  color: var(--success-color);
}
.warning {
	color: #75550B;
	background: #FFF9EB;
	border: 1px solid #FEEFCD;
	border-radius: 3px;
	background-color: var(--warning-background-color);
		border: 1px solid var(--warning-border-color);
		color: var(--warning-color);
}

.error {
	color: #750E0B;
	background: #FFECEB;
	border: 1px solid #FCD1CF;
	border-radius: 3px;
	background-color: var(--error-background-color);
	border: 1px solid var(--error-border-color);
	color: var(--error-color);
}

.notice-bar .button {
	background: var(--notice-button-background-color);
	border-color: var(--notice-button-border-color);
}

.notice-bar.success a:not(.button) {
  color: var(--success-link-color);
}

/* .skipped-intro {
  border: 1px solid #feefcd;
  background: #fff9eb;
}

.skipped-intro {
  color: #75550B;
} */

.exit-banner {
	cursor: pointer;
	padding: 0 2em 0 1em;
}

.error .exit-banner {
	color:#ec5b7a;
	color: var(--error-color)
}

.banner-content {
	padding: 0.25em 2em;
}

.banner-content p {
	padding-left: 1em;
}

.banner-action {
	background: #fcfcfc;
	border: 1px solid #cccccc;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
	border-radius: 3px;
	/* color: #75550b; */
	text-align: center;
	padding: 0.5em 0.75em;
	min-width: 140px;
}

.hidebanner {
	display: none;
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}
.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
}
.breadcrumbs a {
	color: #777;
	color: var(--breadcrumbs-color);
}
.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}
.title-bar {
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 8px;
}
.location-title {
	font-size: 28px;
	flex: 1 1 auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

.location-search {
	font-size: 16px;
	width: 330px;
	max-width: 40%;
}

.content-toolbar {
	min-height: 40px;
}
.content-toolbar-layout {
	display: flex;
	justify-content: space-between;
}
.content-section {
	width: 100%;
	flex: 1 1 0;
	display: flex;
	justify-content: flex-end;
}
.content-toolbar {
	flex: 0 0 auto;
	padding: 0 20px;
	font-size: 0.9em;
	margin-bottom: 4px;
}

@media only screen and (max-width: 550px) {
.banner-actions {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.banner-action {
	display: none;
}
.banner-content {
	padding-right: 0;
}
.exit-banner {
	padding-left: 0;
}
}

@media only screen and (max-width: 500px) {
.hide-500 {
	display: none;
}
}
</style>

<template>
	<div class="drive-home-view">
		<div
			v-if="intro === 'complete'"
			:class="{ hidebanner: bannerIsHidden }"
			class="notice-bar success"
		>
			<div class="banner-content row centering">
				<i class="fas fa-check-circle fa-lg"></i>
				<p>
					Congrats! Now that you know what's possible, try adding a file to get
					started.
				</p>
			</div>
			<a class="exit-banner fa fa-times" @click="hideBanner('on')"></a>
		</div>

		<div
			v-else-if="intro === 'contributor'"
			:class="{ hidebanner: bannerIsHidden }"
			class="notice-bar success row centering">
			<div class="banner-content row centering">
				<i class="fas fa-check-circle fa-lg"></i>
				<p>
					Congrats! Now that you know what's possible, get someone to share a
					file with you or upgrade your account!
				</p>
			</div>
			<a class="exit-banner fa fa-times" @click="hideBanner('on')"></a>
		</div>

		<div
			v-else-if="intro === 'skipped'"
			:class="{ hidebanner: bannerIsHidden }"
			class="skipped-intro notice-bar warning">
			<div class="banner-content row centering">
				<!-- <img src="@/assets/icon-exclamation.svg" alt="exclamation point" /> -->
				<i class="fa fa-exclamation-circle fa-lg"></i>
				<p>
					You can access the TekDrive Introduction at anytime in your account
					settings
				</p>
			</div>
			<div class="banner-actions row centering">
				<router-link to="/settings" class="banner-action button"
				>Go to Settings</router-link
				>
				<a class="exit-banner fa fa-times" @click="hideBanner('on')"></a>
			</div>
		</div>

		<div
			v-if="failedUploadBanner"
			class="notice-bar error">
			<div class="banner-content row centering">
				<i class="fas fa-exclamation-circle fa-lg"></i>
				<!-- Test text wrapping -->
				<p>
					There was an error uploading <span v-if="failedUploads.length > 1">these files:</span> <span v-else>this file:</span> <span v-for="file,index in failedUploads" :key="index">{{ file.name }}<span v-if="failedUploads.length > 1 && file !== failedUploads.at(-1)">, </span></span>.
					You can delete it, or leave the corrupted file in TekDrive.
				</p>
			</div>
			<div class="banner-actions row centering">
				<button to="/settings" class="banner-action button" @click="DELETE_FAILED_UPLOADS">Delete File<span v-if="failedUploads.length > 1">s</span></button>
				<a class="exit-banner fa fa-times" @click="clearFailedUploads()"></a>
			</div>
		</div>

		<header class="content-header">
			<StorageNoticeBar />
			<div class="location-bar">
				<div class="breadcrumbs"></div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
			<div class="title-bar">
				<NavButton />
				<div class="location-title">
					Recent <span class="hide-500">Activity</span>
				</div>

				<SearchBar class="location-search" />
			</div>

			<div class="content-section">
				<div class="content-toolbar content-toolbar-layout">
					<ToggleViewButton />
				</div>
			</div>
		</header>

		<section class="recent-items">
			<div
				v-if="!recentlyUpdated || !recentlyUpdated.length"
				class="empty-state"
			>
				<div v-if="hasPersonalStorage">
					<p class="empty-state-header">Welcome to TekDrive</p>
					<p class="empty-state-tip">
						Add some files to get started! Just
						<span>drop a file here or</span> press "Add Files".
					</p>
					<p class="empty-state-tip">
						You'll find the most recent files added or shared with you right
						here.
					</p>
				</div>
				<div v-else>
					<p class="empty-state-header">Welcome to TekDrive</p>
					<p class="empty-state-tip">
						Ask someone to
						<a
							href="https://help.tekcloud.com/hc/en-us/articles/360049545491-Sharing-Files"
							target="_blank"
						>share files</a
						>
						with you! You'll find the most recent files from your team right
						here.
					</p>
				</div>
			</div>
			<ListView
				v-else-if="childViewStyle === 'list' && recentlyUpdated.length > 0"
				:list="recentlyUpdated"
				columns="name,updated"
				:selectable="false"
				:allow-drag="false"
			/>
			<TileView
				v-else-if="childViewStyle === 'tile' && recentlyUpdated.length > 0"
				:list="recentlyUpdated"
				columns="name,updated"
				:selectable="false"
				:allow-drag="false"
			/>
		</section>
	</div>
</template>

<script>
import SearchBar from "@/components/SearchBar.vue";
import StorageNoticeBar from "@/components/StorageNoticeBar.vue";
import ListView from "@/components/ListView.vue";
import TileView from "@/components/TileView.vue";
import ToggleViewButton from "@/components/ToggleViewButton.vue";
import UserMenu from "@/components/UserMenu.vue";
import NavButton from "@/components/NavButton.vue";
import { useRootStore } from '../store/rootStore';
import { mapState, mapActions } from "pinia";
import { useUploadStore } from "@/store/uploadStore";
import { useAuthStore } from "../store/auth";

export default {
	name: "HomeView",

	components: {
		SearchBar,
		StorageNoticeBar,
		ListView,
		TileView,
		ToggleViewButton,
		UserMenu,
		NavButton,
	},

	data: () => {
		return {
			bannerIsHidden: false,
		};
	},
	props: {
		intro: String,
	},
	computed: {
		...mapState(useRootStore, {
			childViewStyle: 'childViewStyle',
			hasPersonalStorage: (store) => store.userStats != null && store.userStats.storageSizeLimit > 0,
			GET_FOLDER_TREE: 'GET_FOLDER_TREE',
			recentlyUpdated: (store) => {
				const list = store.list.filter((item) => {
					return (
						item != null &&
					item.folderType !== "PERSONAL" &&
					item.folderType !== "SHARES"
					);
				});

				return list
					.sort((a, b) => {

						if (!a || !b) {
							return a == null && b == null ? 0 : !b ? 1 : -1;
						}
						if (+a.updatedAt === +b.updatedAt) {
						// TODO: sort emoji to top?
							var aname =
						typeof a.name === "string" ? a.name.toUpperCase() : "";
							var bname =
						typeof b.name === "string" ? b.name.toUpperCase() : "";
							return aname === bname ? 0 : aname < bname ? -1 : 1;
						}
						return a.updatedAt > b.updatedAt ? -1 : 1;
					})
					.slice(0, 10); // Limit to 10
			}

		}),



		// newList() {
		// 	return this.recentlyUpdated.filter((item, index, array) => {
		// 		const nextItem = array[index + 1];
		// 		const related = item.id === nextItem?.parentFolderId || item.parentFolderId === nextItem?.id;
		// 		if (related) {
		// 			var lessThanFiveSeconds = (item.createdAt.getTime() - nextItem.createdAt.getTime()) < 5000;
		// 		}
		// 		if (related && lessThanFiveSeconds) {
		// 			return item.parentFolderId.length >= 0;
		// 		}
		// 	});
		// },

		...mapState(useUploadStore, ['failedUploads', 'DELETE_FAILED_UPLOADS', 'failedUploadBanner']),


		...mapState(useAuthStore, {
			userName: (store) => {
				const user = store.user;
				return (user != null && user.usr) || "";
			},
		})

	},

	methods: {
		...mapActions(useUploadStore, ['CLEAR_FAILED_UPLOADS']),
		hideBanner(toggle) {
			if (toggle === "on") {
				this.bannerIsHidden = true;
			} else {
				this.bannerIsHidden = false;
			}
		},
		clearFailedUploads() {
			this.CLEAR_FAILED_UPLOADS();
		},
	},
};
</script>
