<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 400px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

h3 {
	margin-top: 0;
}

.modal-footer {
	margin-top: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}

.uploading-files {
	margin: 36px 20px;
	text-align: center;
	color: #777;
}
</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">
			<form class="modal-container" @submit.prevent="uploadFiles(items)">
				<h3>Storage Limit Reached</h3>
				<div v-if="updating">
					<p class="uploading-files">
						Uploading Files&hellip;
					</p>
				</div>
				<div v-else-if="uploadFailed">
					<p>
						You are unable to upload any more files. Please increase your storage limit and before trying again.
					</p>
				</div>
				<div v-else>
					<p>
						You have exceeded your account's storage limit.
						Continuing to upload files may result in loss of data.
					</p>

					<p>
						Do you want to upload anyway?
					</p>
				</div>
				<footer class="modal-footer">
					<a :href="subscriptionsUrl" target="tekdcloudaccount">Increase Storage Limit</a>
					<button type="button" class="button action-cancel" @click="cancel()" :disabled="updating">Cancel</button>
					<button type="submit" class="button action-upload" :disabled="updating">Upload </button>
				</footer>
			</form>
		</div>
	</transition>
</template>

<script>
import ENV_VAR from '@/modules/env-vars';
import { mapActions, mapState } from 'pinia';
import { useUploadStore } from '@/store/uploadStore';
import { useAuthStore } from '@/store/auth';
import { createModalMask, removeModalMask } from '@/modules/modalMask';

export default {
	name: 'UploadLimitModal',

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& Array.isArray(value.items)
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	data () {
		return {
			updating: false,
			uploadFailed: false,
			items: this.context?.items || [],

			resolvable: this.context?.resolvable || null
		};
	},

	computed: {
		...mapState(useAuthStore, {
			subscriptionsUrl: (store) => {
				const token = store.refreshToken || '';
				const subUrl = (ENV_VAR.ACCOUNT_URL || '') + '#/signin/subscriptions%2Fdrive';
				return subUrl + (token ? '?token=' + encodeURIComponent(token) : '');
			}

		})
	},

	methods: {
		...mapActions(useUploadStore, {
			FORCE_UPLOAD_FILES: 'FORCE_UPLOAD_FILES'
		}),

		close () {
			this.resolvable.reject('CANCELLED');
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		cancel () {
			this.close();
		},

		uploadFiles (items) {
			this.updating = true;
			this.uploadFailed = false;
			this.FORCE_UPLOAD_FILES(items)
				.then((result) => {
					//this.updating = false;
					this.resolvable.resolve(items);
				})
				.catch((reason) => {
					this.updating = false;
					this.uploadFailed = true;
				});
		},
		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},

	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}


};
</script>
