<style scoped>


.pair-code-verify-view {
	padding: 1em 2em;
	overflow: auto;
}


.context-header {
	display: flex;
	align-items: baseline;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 40px;
}
.app-brand {
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	flex: 1 1 auto;
}
.home-link {
	color: inherit;
}
.tekcloud-logo {
	vertical-align: -12px;
	margin-right: 6px;
	height: 50px;
	width: 80px;
}





h1 {
	margin-bottom: 0.5em;
}
.header-description {
	margin-bottom: 2em;
	color: #777;
}


.enter-code-view,
.verify-code-view {
	width: 360px;
	max-width: 100%;
	margin: auto;
}
.field-label {
	display: block;
}
.field-tip {
	float: right;
	line-height: 1.6em;
	color: #999;
}
.code-input {
	font-size: 3em;
	width: 100%;
	text-align: center;
	padding: 1em 0.7em;
	margin: 0.2em 0;
	background-color: #f8f8f8;
	border: 1px solid #ddd;
	border-radius: 3px;
	letter-spacing: 6px;
}
.code-input::placeholder {
	letter-spacing: normal;
	font-size: 0.9em;
}
.form-actions {
	margin: 1em 0;
}
.verify-code {
	width: 100%;
	box-sizing: border-box;
	font-size: 2em;
	background-color: #2E88D6;
	color: #fff;
	border-radius: 3px;
	border: 0 none;
	padding: 18px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
.verify-code:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	transform: translate(0, 2px);
	outline: 0 none;
}
.error-message {
	color: #c00;
}

.requester-name {
	margin-top: 1em;
	margin-bottom: 1.4em;
}
.key-name {
	font-size: 1.8em;
}
.app-name {
	font-size: 1em;
	color: #999;
	font-weight: normal;
}

.claim-group {
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
}
.claim-group-icon {
	font-size: 3em;
	margin-right: 12px;
	width: 50px;
	text-align: center;
}
.claim-group-title {
	font-size: 1.6em;
}

.access-security {
	margin: 2em 0 3em 0.5em;
	color: #777;
}
.action-buttons {
	margin: 2em 0;
	display: flex;
	flex-direction: row;
}
button.allow-access,
button.deny-access {
	font-size: 1.2em;
	padding: 1em 1.4em;
	flex: 1 1 0;
	border-radius: 3px;
	border: 0 none;
	color: #fff;
	padding: 18px;
	box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
button.allow-access:active,
button.deny-access:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.2);
	transform: translate(0, 2px);
	outline: 0 none;
}
button.deny-access {
	background-color: #c00;
}
.allow-access {
	margin-left: 1em;
	background-color: #2E88D6;
}
.access-security-header{
	font-weight: bold;
}
.access-security-item {
	margin: .5em 0;
}
.claim-group-list {
	color: #777;
}
.action-result {
	margin: 2em 0;
}
.access-allowed {
	color: #090;
}
.access-denied {
	color: #c00;
}
.access-invalid {
	color: #c00;
}
.complete-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 2em 0;
}
.action-done {
	font-size: 1.2em;
	padding: 1em 1.4em;
}
</style>

<template>

	<div class="pair-code-verify-view">

		<div class="context-header">
			<div class="app-brand">
				<router-link to="/" class="home-link">
					<img src="@/assets/tekcloud-nav-logo.svg" alt="TekDrive" class="tekcloud-logo" />
					<span class="tekdrive-font">TekDrive</span>
				</router-link>
			</div>
			<div class="user-menu">
				<UserMenu />
			</div>
		</div>

		<div v-if="context" class="verify-code-view">

			<header class="requester-name">

				<div class="key-name" v-if="context.name">{{ context.name }}</div>
				<div class="key-name" v-else>Application generated key</div>

				<div v-if="context.app" class="app-name">{{ context.app.name }}</div>

			</header>


			<p class="header-description">Would like access to:</p>

			<div class="claim-groups">

				<div class="claim-group files" v-if="context.fileClaims.length > 0">
					<div class="claim-group-icon">
						<i class="fa fa-folder"></i>
					</div>
					<div class="claim-group-info">
						<div class="claim-group-title">Files</div>
						<div class="claim-group-list">{{ formatFileClaims(context.fileClaims) }} Files and Folders</div>
					</div>
				</div>

				<div class="claim-group account" v-if="context.accountClaims.length > 0">
					<div class="claim-group-icon">
						<i class="fa fa-user"></i>
					</div>
					<div class="claim-group-info">
						<div class="claim-group-title">Account</div>
						<div class="claim-group-list">View {{ formatFileClaims(context.accountClaims) }} Info</div>
					</div>
				</div>

			</div>

			<div class="access-security" v-if="context.hasIpRestriction || context.expiresAt">
				<div class="access-security-header">Restrictions</div>
				<div class="access-security-item ip-restriction" v-if="context.hasIpRestriction"><i class="fa fa-lock"></i> Access will be restricted by IP address.</div>
				<div class="access-security-item expiry" v-if="context.expiresAt"><i class="fa fa-clock"></i> Access will expire on {{ formatDate(context.expiresAt, 'datetime') }}</div>
			</div>

			<div>
				<p>Do you allow <em>{{ context.app.name }}</em> this access?</p>
			</div>

			<div class="action-buttons" v-if="!context.closed">

				<button class="deny-access" type="button" @click="rejectShortcode(context.code)" :disabled="waiting">Deny</button>
				<button class="allow-access" type="button" @click="acceptShortcode(context.code)" :disabled="waiting">Allow</button>

			</div>
			<div class="action-result" v-else>

				<div v-if="context.allowed">
					<div class="access-allowed">
						<span>
							<i class="fa fa-check"></i>
							Access has been granted.
						</span>
					</div>
				</div>
				<div v-else-if="context.denied">
					<div class="access-denied">
						<i class="fa fa-times"></i>
						Access has been denied.
					</div>
				</div>
				<div v-else class="access-invalid">
					<div class="access-denied">
						This code is no longer valid.
					</div>
				</div>

				<div class="complete-actions">
					<button type="button" class="link" @click="reset()">Enter Another Code</button>
					<router-link to="/" class="button action-done">Done</router-link>
				</div>
			</div>

		</div>
		<div v-else class="enter-code-view">

			<h1>Activate Device</h1>

			<p class="header-description">Enter the code provided by your device.</p>

			<form @submit.prevent="reviewShortcode(shortcode)" novalidate="true">
				<div class="code-field">
					<label class="field">
						<span class="field-label">
							Code
							<small class="field-tip">This code is case-sensitive.</small>
						</span>
						<input type="text" class="code-input" v-model="shortcode" required="required" placeholder="Enter code here" />
					</label>
				</div>
				<div class="form-actions">
					<button type="submit" class="action verify-code" :disabled="waiting">Next</button>
				</div>
			</form>

			<div class="error-message" v-if="codeError && codeError.errorCode === 'minLength'">The code must be at least 6 characters.</div>
			<div class="error-message" v-else-if="codeError">This code is not valid. Check your device for a new code.</div>

		</div>

	</div>

</template>

<script>
import driveService from '@/modules/drive-service';
import { dateFormatFilter } from '@/modules/filters';
import UserMenu from '@/components/UserMenu.vue';

/*
Phases
	/activate(?claims=abc123)
*/

const claimModels = {
	'drive:api:directory:read':      { label:'read', desc:'Create files and folders. Upload file contents.', cls:'claim-read', key:'drive:api:directory:read' },
	'drive:api:directory:create':    { label:'create', desc:'Get file and folder details. Download file contents. Search and browse the directory tree.', cls:'claim-create', key:'drive:api:directory:create' },
	'drive:api:directory:update':    { label:'update', desc:'Update file and folder details and contents.', cls:'claim-update', key:'drive:api:directory:update' },
	'drive:api:directory:delete':    { label:'delete', desc:'Delete files and folders.', cls:'claim-delete', key:'drive:api:directory:delete' },
	'drive:api:user:details:self':   { label:'account', desc:'View your account details.', cls:'claim-account', key:'drive:api:user:details:self' },
	'drive:api:user:analytics:self': { label:'storage', desc:'View storage and other usage data for your account.', cls:'claim-storage', key:'drive:api:user:analytics:self' }
};
function toClaimViewModel(claim) {
	if (typeof claim !== 'string') {
		return null;
	}
	if (claimModels.hasOwnProperty(claim)) {
		return claimModels[claim];
	}
	return {
		claim: claim,
		label: claim,
		desc: '',
		cls: 'claim-other'
	};
}

export default {
	name: 'PairCodeVerifyView',

	components: {
		UserMenu
	},

	props: {
		code: String
	},

	data: () => {
		return {

			codeError: null,
			shortcode: '',
			context: null,
			waiting: false

		};
	},


	methods: {
		formatDate(value, format) {
			return dateFormatFilter(value, format);
		},
		claimDescription (value) {
			return value;
		},

		formatFileClaims(claims) {
			var value = '';
			if (Array.isArray(claims)) {
				const claimNames = claims.map((claim) => {
					return	(typeof claim === 'string') ? claim:
						(claim && typeof claim.label === 'string') ? claim.label : '';
				}).filter(Boolean).map((value) => {
					return (value && value.length > 1) ? value[0].toUpperCase() + value.slice(1) : value;
				});

				if (claimNames.length > 2) {
					value = claimNames.slice(0, -1).join(', ') + ', and ' + claimNames[claimNames.length - 1];
				} else {
					value = claimNames.join(' and ');
				}

			}
			return value;
		},

		reset () {
			this.codeError = null;
			this.shortcode = '';
			this.context = null;
			this.waiting = false;
		},

		validateShortcode(shortcode) {
			if (typeof shortcode !== 'string' || shortcode.length < 6) {
				return {
					errorCode: 'minLength'
				};
			}
			return null;
		},

		reviewShortcode(shortcode) {
			this.codeError = null;

			const valErr = this.validateShortcode(shortcode);
			if (valErr) {
				this.codeError = valErr;
				return;
			}

			this.waiting = true;
			return driveService.getShortcodeContext(shortcode)
				.then((context) => { // { code, app:{ id, name, email, createdAt, updatedAt, }, claims, name, expiresAt, hasIpRestriction }
					//this.context = context;
					this.context = {
						code: context.code,
						app: context.app,
						claims: context.claims,
						fileClaims: context.claims.filter((claim) => (typeof claim === 'string' && claim.indexOf('drive:api:directory') === 0)).map(toClaimViewModel),
						accountClaims: context.claims.filter((claim) => (typeof claim === 'string' && claim.indexOf('drive:api:user') === 0)).map(toClaimViewModel),
						name: context.name,
						expiresAt: context.expiresAt,
						hasIpRestriction: (context.hasIpRestriction === true),

						closed: false,
						allowed: false,
						denied: false
					};
				}, (reason) => {
					// 404: shortcode invalid/expired/spent
					this.codeError = reason || new Error('Unknown Error');
				})
				.then(() => {
					this.waiting = false;
				});
		},

		acceptShortcode(shortcode) {
			this.codeError = null;

			const valErr = this.validateShortcode(shortcode);
			if (valErr) {
				this.codeError = valErr;
				return;
			}

			this.waiting = true;
			return driveService.acceptShortcode(shortcode)
				.then(() => {
					// accepted
					this.context.closed = true;
					this.context.allowed = true;

				}, (reason) => {
					// 404: shortcode invalid/expired/spent
					this.codeError = reason || new Error('Unknown Error');
					this.context.closed = true;
				})

				.then(() => {
					this.waiting = false;
				});
		},

		rejectShortcode(shortcode) {
			this.codeError = null;

			const valErr = this.validateShortcode(shortcode);
			if (valErr) {
				this.codeError = valErr;
				return;
			}

			this.waiting = true;
			return driveService.rejectShortcode(shortcode)
				.then(() => {
					// accepted
					this.context.closed = true;
					this.context.denied = true;

				}, (reason) => {
					// 404: shortcode invalid/expired/spent
					this.codeError = reason || new Error('Unknown Error');
					this.context.closed = true;
				})
				.then(() => {
					this.waiting = false;
				});
		}

	},


	beforeRouteEnter (to, from, next) {
		next(vm => vm.shortcode = (vm.code || ''));
	},

	beforeRouteUpdate (to, from, next) {
		this.shortcode = (this.code || '');
		next();
	},

};
</script>
