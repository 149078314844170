<style scoped>
	table, td, th {
		border: 1px solid #dddddd;
		border: 1px solid var(--table-border-color);
		padding: 2px 6px;
		height: 18px;
	}
	td, th {
		max-width: 1200px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	table {
		margin-top: -1px;
		background-color: white;
		background-color: var(--table-background-color);
		width: fit-content;
	}
	.table-bg {
		background-color: #ededf0;
		background-color: var(--table-bg-backgroud-color);
		width: 100%;
		position: relative;
	}
	.table-scroll {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		overflow: auto;
		white-space: nowrap;
		font-size: 13px;
	}
	.loading-message {
		margin: 3em;
		text-align: center;
		font-size: 2em;
		color: #ccc;
		color: var(--loading-message-color);
	}
	.notice {
		padding: .25em;
		color: #666;
	}

	@media only screen and (max-width : 600px) {
		td, th {
			max-width: 100vw;
		}
	}

</style>

<template>
	<div class="table-bg">
		<div class="table-scroll">
			<table v-if="csvData">
				<tr v-for="(tableRow, index) in csvData" :key="index">
					<td v-for="(tableData, index) in tableRow" :key="index">{{tableData}}</td>
				</tr>
				<div v-if="truncated" class="notice">
					<i class="fas fa-info-circle"></i>
					Displaying first 500 rows.
				</div>
			</table>
			<div v-else class="loading-message">
				Loading&hellip;
			</div>

		</div>
	</div>
</template>

<script>
// import Papa from '/lib/papaparse/papaparse.min.js';

export default {
	name: 'CsvViewer',
	props: {
		fileName: String
	},
	data() {
		return {
			csvData: null
		};
	},
	computed: {
		truncated() {
			return this.csvData && this.csvData.length === 500;
		}
	},
	mounted() {
		const vm = this;
		window.Papa.parse(this.fileName, {
			download: true,
			preview: 500,
			complete: function(results) {
				vm.csvData = results.data;
			}
		});
	}
};
</script>
