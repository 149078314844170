import axios from 'axios';
import authReq from '@/modules/auth-request';
import { driveItemFromDto } from '@/modules/drive-dto';


function parseDate(value) {
	if (isNaN(Date.parse(value))) {
		return null;
	}
	return new Date(value);
}

function dtoToAccessKey(dto) {
	if (dto == null || !dto.id) {
		return null;
	}
	const app = dto.app || {};
	return {
		id: dto.id || '',
		ownerId: dto.ownerId || '',
		keyType: dto.keyType || '',
		name: dto.name || '',
		createdAt: parseDate(dto.createdAt),
		updatedAt: parseDate(dto.updatedAt),
		claims: Array.isArray(dto.claims) ? dto.claims.map(String) : [],
		app: {
			id: app.id,
			createdAt: parseDate(app.createdAt),
			updatedAt: parseDate(app.updatedAt),
			name: app.name,
			email: app.email
		}
	};
}


function dtoToApp(dto) {
	if (dto == null) {
		return null;
	}
	return {
		id: (typeof dto.id === 'string') ? dto.id : '',
		name: (typeof dto.id === 'string') ? dto.name : '',
		email: (typeof dto.id === 'string') ? dto.email : '',
		createdAt: parseDate(dto.createdAt),
		updatedAt: parseDate(dto.updatedAt)
	};
}

function dtoToMember(dto) {
	if (dto == null || dto.permissions == null || typeof dto.username !== 'string') {
		return null;
	}
	const perms = dto.permissions;
	return {
		id: dto.id,
		username: dto.username,
		permissions: {
			owner: perms.owner === true,
			creator: perms.creator === true,
			read: perms.read === true,
			edit: perms.edit === true
		}
	};
}

function dtoToArtifacts(dto) {
	if (dto == null || !Array.isArray(dto) || dto.length === 0) {
		return null;
	}
	let artifacts = dto.reduce((previousValue, currentValue, currentIndex, array) => {
		if (currentValue.contextType === 'SCREEN_CAPTURE') {
			previousValue.screenCapture = currentValue;
		} else if (currentValue.contextType === 'SETTINGS_ZIP') {
			previousValue.settingsZip = currentValue;
		} else if (currentValue.contextType === 'CHANNEL') {
			previousValue.channels.push(currentValue);
		}
		return previousValue;
	}, {
		screenCapture: null,
		settingsZip: null,
		channels: []
	});

	if (artifacts.channels.length) {
		artifacts.channels.sort((a, b) => (a.name > b.name) ? 1 : -1).map((channel, idx) => channel.displayName = `Channel ${idx + 1}`);
	}

	return artifacts;
}

function dtoToTrashItem(dto) {
	if (dto == null) {
		return null;
	}
	const item = driveItemFromDto(dto.item);
	if (item == null) {
		return null;
	}
	const trasher = dto.trasher || {};
	return {
		itemId: item.id,
		trasher: {
			id: (typeof trasher.id === 'string') ? trasher.id : '',
			username: (typeof trasher.username === 'string') ? trasher.username : ''
		},
		trashedAt: parseDate(dto.trashedAt),
		trashedDirectly: dto.trashedDirectly === true,
		totalBytes: +dto.totalBytes || 0,
		itemShareCount: +dto.itemShareCount || 0,
		item: item,
		thumbnails: item.thumbnails
	};
}

function dtoToWebhook(dto) {
	if (dto == null) {
		return null;
	}
	return {
		config: {
			location: dto.config.folderId === '*' ? 'all' : dto.config.fileId ? 'file' : 'folder',
			depth: dto.config.depth,
			events: webhookEventsFormatter(dto.config.events),
			folderId: dto.config.folderId === '*' ? '' : dto.config.folderId,
			fileId: dto.config.fileId
		},
		createdAt: new Date(dto.createdAt),
		hasSecret: dto.hasSecret,
		httpMethod: dto.httpMethod,
		id: dto.id,
		name: dto.name,
		ownerId: dto.ownerId,
		updatedAt: new Date(dto.updatedAt),
		url: dto.url,
	};
}

function webhookEventsFormatter(events) {
	if (Array.isArray(events)) {
		return events;
	}
	if (typeof events === 'string' && events.includes("*")) {
		return ["FileCreated","FileTrashed","FileDeleted","FileRestored","FolderTrashed","FolderCreated","ThumbnailCreated","FileMetaUpdated","FolderDeleted","FolderRestored","FolderMetaUpdated"];
	}
	return events;
}

function silos() {
	return authReq.get('DRIVE_API://v1/silos')
		.then((resp) => {
			const siloMap = resp.data.silos.reduce((map, item) => {
				if (item && item.type === 'FOLDER' && !item.parentFolderId && typeof item.folderType === 'string') {
					map[item.folderType] = item;
				}
				return map;
			}, Object.create(null));
			return siloMap;
		});
}


function search(options) {
	if (options == null) {
		options = {};
	}
	// options: {folderId:'', silo:PERSONAL|SHARES, name:'', depth:0, fileType:[''], type:[FILE|FOLDER], uploadState:[PENDING|SUCCESS|ERROR], orderBy:[/-?*/]}

	// Note: silo is a relative shortcode to user root folders
	const params = { thumbnailSizes: '64x64,256x256' }; // {folderId:'', silo:PERSONAL|SHARES, depth:1}

	if (typeof options.limit === 'number' && options.limit >= 0) {
		// This option only makes sense when paired with folderId or silo
		params.limit = options.limit;
	}

	if (typeof options.folderId === 'string') {
		if (options.folderId.toUpperCase() === 'PERSONAL') {
			params.silo = 'PERSONAL';
		} else if (options.folderId.toUpperCase() === 'SHARES') {
			params.silo = 'SHARES';
		} else if (options.folderId !== '') {
			params.folderId = options.folderId;
		}
	}

	if (typeof options.silo === 'string' && options.silo !== '') {
		// PERSONAL|SHARES
		params.silo = options.silo.toUpperCase();
	}

	if (typeof options.name === 'string' && options.name !== '') {
		// case-insensitive search
		params.name = options.name;
	}

	if (typeof options.depth === 'number' && options.depth >= 0) {
		// This option only makes sense when paired with folderId or silo
		params.depth = options.depth;
	}

	// Accept FILE|FOLDER (type) from fileType param
	if (typeof options.fileType === 'string' && options.fileType.toUpperCase() === 'FOLDER') {
		params.type = 'FOLDER';
	} else if (typeof options.fileType === 'string' && options.fileType.toUpperCase() === 'FILE') {
		params.type = 'FILE';
	} else if (typeof options.fileType === 'string' && options.fileType !== '') {
		params.fileType = options.fileType;
	}

	if (typeof options.type === 'string' && options.type !== '') {
		// FILE|FOLDER
		params.type = options.type.toUpperCase();
	}

	if (typeof options.uploadState === 'string' && options.uploadState !== '') {
		// PENDING|SUCCESS|ERROR
		params.uploadState = options.uploadState.toUpperCase();
	}

	if (options.includeTrashed === true) {
		params.includeTrashed = true;
	}

	// valid orderBy options: name, bytes, createdAt, updatedAt, fileType, depth
	if (typeof options.orderBy === 'string' && options.orderBy !== '') {
		// single orderby value
		params.orderBy = options.orderBy;

	} else if (Array.isArray(options.orderBy) && options.orderBy.length > 0) {
		// Multisort order
		params.orderBy = options.orderBy.map(String).filter(Boolean).join(',');
	}

	if (typeof options.thumbnailSizes === 'string' && options.thumbnailSizes !== '') {
		// 64x64|128x128|256x256
		params.uploadState = options.uploadState.toUpperCase();
	}

	return authReq.get('DRIVE_API://v1/search', {
		params: params
	})
		.then((response) => {
			/*{
				"results":[],
				"meta":{
					"page":1,
					"limit":100,
					"offset":0
				}
			}*/
			const data = response.data || {};
			const list = data.results;
			if (!Array.isArray(list)) {
				throw new Error('Unexpected List Response');
			}
			const meta = data.meta || {};
			return {
				list: data.results.map(driveItemFromDto),
				page: meta.page || 0,
				limit: meta.limit || list.length,
				offset: meta.offset || 0,
				next: () => {
					console.error('List.next() not yet implemented');
				}
			};
		});
}

function tree(folderId, depth) {
	if (typeof folderId !== 'string') {
		folderId = '';
	}
	// Note: silo is a relative shortcode to user root folders
	const params = {}; // {folderId:'', silo:PERSONAL|SHARES, depth:1}
	if (folderId.toUpperCase() === 'PERSONAL') {
		params.silo = 'PERSONAL';
	} else if (folderId.toUpperCase() === 'SHARES') {
		params.silo = 'SHARES';
	} else if (folderId !== '') {
		params.folderId = folderId;
	}
	if (depth != null && depth >= 0) {
		params.depth = depth;
	}

	return authReq.get('DRIVE_API://v1/tree', {
		params: params
	})
		.then((response) => {
			const tree = response && response.data && response.data.tree || null;
			return driveItemFromDto(tree);
		});
}

function treePath(item) {
	const params = {};

	if (item.type.toUpperCase() === 'FOLDER') {
		params.folderId = item.id;
	} else if (item.type.toUpperCase() === 'FILE') {
		params.fileId = item.id;
	}

	return authReq.get('DRIVE_API://v1/tree/path', {
		params
	})
		.then((response) => {
			const treePath = response && response.data && response.data.treePath || null;
			// trim current folder/file
			treePath.pop();
			return treePath.map(driveItemFromDto);
		});
}

function listTrash(orderBy) {
	const params = { thumbnailSizes: '64x64,256x256' };
	if (typeof orderBy === 'string') {
		// orderBy: createdAt, updatedAt, trashedAt, totalBytes
		params.orderBy = orderBy;
	}
	return authReq.get('DRIVE_API://v1/trash', {
		params: params
	})
		.then((response) => {
			// TODO: map trash model {"trasher": { "id": String, "username": String }, "trashedAt": DateTime, "trashedDirectly": Boolean, "totalBytes": String, "itemShareCount": Number, "item":DItem}
			var list = response && response.data && response.data.trash || [];
			const meta = response && response.data && response.data.meta || {};
			return {
				list: Array.isArray(list) ? list.map(dtoToTrashItem).filter(Boolean) : [],
				meta: {
					page: meta.page | 0,
					limit: meta.limit | 0,
					offset: meta.offset | 0
				}
			};
		});
}

function emptyTrash() {
	return authReq.delete('DRIVE_API://v1/trash')
		.then((response) => {
			const tree = response && response.data && response.data.tree || null;
			return driveItemFromDto(tree);
		});
}

function uploadFilePart(url, blob, progressHandler) {
	if (typeof progressHandler !== 'function') {
		progressHandler = null;
	}
	// Do not use auth for a presigned URL
	return axios.put(url, blob, {
		headers: {
			'content-type': blob && blob.type || 'application/octet-stream'
		},
		onUploadProgress: progressHandler
	});
}

function createFileMeta(config) {
	const data = {
		name: (typeof config === 'string') ? config :
			(config && typeof config.name === 'string') ? config.name : ''
	};

	if (!data.name) {
		return Promise.reject('Invalid Name');
	}

	const parentFolderId = (config && typeof config.parentFolderId === 'string') ? config.parentFolderId : '';
	if (parentFolderId) {
		data.parentFolderId = parentFolderId;
	}
	// TODO: validate params
	return authReq.post('DRIVE_API://v1/file', data)
		.then(function(response) {
			const fileMeta = response.data.file;
			const uploadUrl = response.data.uploadUrl;
			const storageLimitExceeded = (response.data.storageLimitExceeded === true);

			if (!response.data.uploadUrl) {
				throw new Error('Multipart file uploads not yet supported');
			}
			return {
				meta: driveItemFromDto(fileMeta),
				storageLimitExceeded: storageLimitExceeded,
				upload: (file, progressHandler) => {
					return uploadFilePart(uploadUrl, file, progressHandler)
						.then(() => {
							// file upload is complete
							return driveItemFromDto(fileMeta);
						});
				}
			};
		});
}

function getFileMeta(fileId, params) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.get('DRIVE_API://v1/file/' + encodeURIComponent(fileId), {
		//params may be necessary to update thumbnails in the view
		params: params
	})
		.then((response) => {
			return driveItemFromDto(response.data);
		});
	// 404 - FILE_NOT_FOUND
	// 410 - FILE_GONE
}

function restoreFile(fileId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.post('DRIVE_API://v1/file/' + encodeURIComponent(fileId))
		.then((response) => {
			return driveItemFromDto(response.data);
		});
	// 403 - FORBIDDEN
	// 404 - FILE_NOT_FOUND
}

function getFileDownloadInfo(fileId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.get('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/contents?attachment=true')
		.then((response) => {
			var info = response.data || {};
			return {
				bytes: +info.bytes || 0,
				fileType: info.fileType || '',
				url: info.downloadUrl || ''
			};
		})
		.catch((reason) => {
			var error = reason.response;
			return Promise.reject(error.data || error);
		});
}

function getFileArtifactDownloadInfo(fileId, artifactId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid File ID'));
	}
	if (typeof artifactId !== 'string' || artifactId === '') {
		return Promise.reject(new Error('Invalid Artifact ID'));
	}
	return authReq.get('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/artifacts/' + encodeURIComponent(artifactId) + '/contents?attachment=true')
		.then((response) => {
			var info = response.data || {};
			return {
				bytes: +info.bytes || 0,
				fileType: info.fileType || '',
				url: info.downloadUrl || ''
			};
		});
}

function getFileArtifacts(fileId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.get('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/artifacts')
		.then((response) => {
			var data = response.data || {};
			return Array.isArray(data.artifacts) ? dtoToArtifacts(data.artifacts) : [];
		});
}

function getFileMembers(fileId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.get('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/members')
		.then((response) => {
			var data = response.data || {};
			return Array.isArray(data.members) ? data.members.map(dtoToMember).filter(Boolean) : [];
		});
}

function deleteFileMember(fileId, memberId) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid File ID'));
	}
	if (typeof memberId !== 'string' || memberId === '') {
		return Promise.reject(new Error('Invalid Member ID'));
	}

	return authReq.delete('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/members/' + encodeURIComponent(memberId))
		.then((response) => {
			// Expect: 204 response
			return response.data;
		});
};

function deleteFolderMember(folderId, memberId) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid Folder ID'));
	}
	if (typeof memberId !== 'string' || memberId === '') {
		return Promise.reject(new Error('Invalid Member ID'));
	}

	return authReq.delete('DRIVE_API://v1/folder/' + encodeURIComponent(folderId) + '/members/' + encodeURIComponent(memberId))
		.then((response) => {
			// Expect: 204 response
			return response.data;
		});


};

function setFileMembers(fileId, members) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	if (!Array.isArray(members)) {
		return Promise.reject(new Error('Invalid Members List'));
	}
	// minimal member model: { username:"a@a.com", permissions: { read:true, edit:true } }
	// if an ID is not provided, the system will look up the username (possibly create a user)
	// permissions.read defaults to true
	// permissions.edit defaults to false
	// owner must be present and unchanged (cannot reassign ownership)
	// NOTE: creator may be removed as a member (it is not a membership role)
	/*{
		"members": [
			{
				"id": "ea29f0df-ad46-4601-8110-0e926ce6c6e3",
				"username": "me@example.com",
				"permissions": {
					"owner": true,
					"creator": true,
					"read": true,
					"edit": true
				}
			},
			{
				"username": "new-user-to-invite@example.com",
				"permissions": {
					"read": true,
					"edit": false
				}
			}
		]
	}*/
	return authReq.put('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + '/members', { members })
		.then((response) => {
			var data = response.data || {};
			return Array.isArray(data.members) ? data.members.map(dtoToMember).filter(Boolean) : [];
		})
		.catch((reason) => {
			var error = reason.response;
			return Promise.reject(error.data || error);
		});
}

function updateFileMeta(fileId, props) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	// TODO: validate params
	return authReq.put('DRIVE_API://v1/file/' + encodeURIComponent(fileId), props)
		.then((response) => {
			return driveItemFromDto(response.data);
		});
}

function deleteFileMeta(fileId, hardDelete) {
	if (typeof fileId !== 'string' || fileId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	var query = '';
	if (hardDelete === true) {
		query = '?hardDelete=true';
	} else { // TODO: Remove this once API default is set to false
		query = '?hardDelete=false';
	}
	return authReq.delete('DRIVE_API://v1/file/' + encodeURIComponent(fileId) + query)
		.then((response) => {
			// Expect: 204 response
			return response.data;
		});
}

function createFolder(config) {
	// TODO: validate params
	const data = {
		name: (typeof config === 'string') ? config :
			(config && typeof config.name === 'string') ? config.name : ''
	};

	if (!data.name) {
		return Promise.reject('Invalid Name');
	}

	const parentFolderId = (config && typeof config.parentFolderId === 'string') ? config.parentFolderId : '';
	if (parentFolderId) {
		data.parentFolderId = parentFolderId;
	}

	return authReq.post('DRIVE_API://v1/folder', data)
		.then((response) => {
			return driveItemFromDto(response.data.folder);
		});
}

function getFolder(folderId) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.get('DRIVE_API://v1/folder/' + encodeURIComponent(folderId))
		.then((response) => {
			return driveItemFromDto(response.data);
		});
	// 404 - FILE_NOT_FOUND
	// 410 - FILE_GONE
}

function restoreFolder(folderId) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	return authReq.post('DRIVE_API://v1/folder/' + encodeURIComponent(folderId))
		.then((response) => {
			return driveItemFromDto(response.data);
		});
	// 403 - FORBIDDEN
	// 404 - FILE_NOT_FOUND
}

function getFolderMembers(folderId) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid Folder ID'));
	}
	return authReq.get('DRIVE_API://v1/folder/' + encodeURIComponent(folderId) + '/members')
		.then((response) => {
			var data = response.data || {};
			return Array.isArray(data.members) ? data.members.map(dtoToMember).filter(Boolean) : [];
		});
}

function setFolderMembers(folderId, members) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid Folder ID'));
	}
	if (!Array.isArray(members)) {
		return Promise.reject(new Error('Invalid Members List'));
	}
	/*{
		"members": [
			{
				"id": "ea29f0df-ad46-4601-8110-0e926ce6c6e3",
				"username": "me@example.com",
				"permissions": {
					"owner": true,
					"creator": true,
					"read": true,
					"edit": true
				}
			},
			{
				"username": "new-user-to-invite@example.com",
				"permissions": {
					"read": true,
					"edit": false
				}
			}
		]
	}*/
	return authReq.put('DRIVE_API://v1/folder/' + encodeURIComponent(folderId) + '/members', { members })
		.then((response) => {
			var data = response.data || {};
			return Array.isArray(data.members) ? data.members.map(dtoToMember).filter(Boolean) : [];
		})
		.catch((reason) => {
			var error = reason.response;
			return Promise.reject(error.data || error);
		});
}

function updateFolder(folderId, props) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	// TODO: validate params
	return authReq.put('DRIVE_API://v1/folder/' + encodeURIComponent(folderId), props)
		.then((response) => {
			return driveItemFromDto(response.data);
		});
}

function deleteFolder(folderId, hardDelete) {
	if (typeof folderId !== 'string' || folderId === '') {
		return Promise.reject(new Error('Invalid ID'));
	}
	var query = '';
	if (hardDelete === true) {
		query = '?hardDelete=true';
	} else { // TODO: Remove this once API default is set to false
		query = '?hardDelete=false';
	}
	return authReq.delete('DRIVE_API://v1/folder/' + encodeURIComponent(folderId) + query)
		.then((response) => {
			// Expect: 204 response
			return response.data;
		});
}

function getUserInfo() {
	return authReq.get('DRIVE_API://v1/user')
		.then((response) => {
			const info = response.data;
			if (!info || !info.plan || !info.plan.limits) {
				throw new Error('Unexpected User Info Response');
			}
			const plan = info.plan;
			const limits = plan.limits;
			return {
				id: info.id || '',
				username: info.username || '',
				accountId: info.accountId || '',
				ownerType: info.ownerType, // USER
				createdAt: parseDate(info.createdAt),
				updatedAt: parseDate(info.updatedAt),
				plan: {
					id: plan.id,
					name: plan.name,

					accessKeyLimit: +limits.accessKeyLimit || 0,
					sharingType: limits.sharingType || 'DISABLED', // UNLIMITED|PERMISSIONS_LIMITED|DISABLED

					// NOTE: This is a BigInt number converting this to Float64 limits the number to about 8 petabytes
					storageSizeLimit: +limits.storageSizeLimit || 0,

					// NOTE: This is a BigInt number converting this to Float64 limits the number to about 8 petabytes
					systemSizeLimit: +limits.systemSizeLimit || 0
				}
			};
		});
}

// function getUserClaims(userId) {
//     return authReq.get(`/admin/users/${userId}/claims`);
// }

function getUserStats() {
	return authReq.get('DRIVE_API://v1/user/usage')
		.then((response) => {
			const stats = response.data;
			if (!stats || !isFinite(stats.totalBytesOwned)) {
				throw new Error('Unexpected Stats Response');
			}
			return {
				// NOTE: This is a BigInt number converting this to Float64 limits the number to about 8 petabytes
				totalBytesOwned: +stats.totalBytesOwned,
				filesOwnedCount: +stats.filesOwnedCount || 0,

				// NOTE: This is a BigInt number converting this to Float64 limits the number to about 8 petabytes
				totalBytesCreated: +stats.totalBytesCreated,
				filesCreatedCount: +stats.filesCreatedCount || 0,

				// NOTE: This is a BigInt number converting this to Float64 limits the number to about 8 petabytes
				storageSizeLimit: +stats.storageSizeLimit || 0
			};
		});
}

function getAccessKeys() {
	return authReq.get('DRIVE_API://v1/access/key')
		.then((response) => {
			const data = response.data;
			const list = data && Array.isArray(data.accessKeys) ? data.accessKeys : [];
			// TODO: convert to model
			return list.map(dtoToAccessKey);
		});
}

function createAccessKey(name, claims, clientId) {
	if (typeof name !== 'string' || name === '') {
		return Promise.reject(new Error('Invalid Access Key Name'));
	}
	if (!Array.isArray(claims)) {
		return Promise.reject(new Error('Invalid Access Key Claims'));
	}
	if (typeof clientId !== 'string' || clientId === '') {
		return Promise.reject(new Error('Invalid Access Key Client ID'));
	}
	const data = { clientId, name, claims: claims.map(String) };
	return authReq.post('DRIVE_API://v1/access/key', data)
		.then((response) => {
			const accessKey = response.data;
			// TODO: convert to model
			// NOTE: this model contains a one-time "key" property.
			// TODO: Discard it after display or separate it from the (cached) model
			return {
				accessKey: dtoToAccessKey(accessKey),
				key: accessKey.key
			};
		});
}

function deleteAccessKey(accessKeyId) {
	if (typeof accessKeyId !== 'string' || accessKeyId === '') {
		return Promise.reject(new Error('Invalid Access Key ID'));
	}
	return authReq.delete('DRIVE_API://v1/access/key/' + encodeURIComponent(accessKeyId))
		.then(() => {
			// Expect: 204
			return null;
		}, (response) => {
			const error = response && response.data || null;
			if (!error) {
				// Not an expected response
				return Promise.reject(response);
			}
			if (error.errorCode === 'MALFORMED_INPUT' && typeof error.message === 'string' && error.message.indexOf('Invalid claims: ') === 0) {
				// Create list of rejected claims from error response
				return Promise.reject({
					errorCode: 'MALFORMED_INPUT',
					message: 'Invalid claims',
					claims: error.message.slice(16).split(' ')
				});
			}
			return Promise.reject(error);
		})
		.catch();
}

/*
GET /shortcode/:code/details
	200 OK
		{
			"app": {
				"id": "a53802c4-1920-4f0b-8076-2782dfb69c70",
				"createdAt": "2020-07-15T13:26:31.185Z",
				"updatedAt": "2020-07-15T13:26:31.185Z",
				"name": "App1",
				"email": "support@app1.com"
			},
			"requestedKeyClaims": [
				"drive:api:directory:read"
			],
			"requestedKeyExpiresAt": "2020-07-30T00:00:00.000Z",
			"requestedKeyIpRestriction": true
		}
	404 Not Found // Not found, Expired, Invalid State

POST /shortcode/:code/activate
	204 No Content
	404 Not Found // Not found, Expired, Invalid State

DELETE /shortcode/:code/activate
	204 No Content
	404 Not Found // Not found, Expired, Invalid State
*/

function getShortcodeContext(shortcode) {
	if (typeof shortcode !== 'string' || shortcode === '') {
		return Promise.reject(new Error('Invalid Shortcode'));
	}
	return authReq.get('DRIVE_API://v1/shortcode/' + encodeURIComponent(shortcode) + '/details')
		.then((response) => {
			const data = response && response.data || {};
			return {
				code: shortcode,
				app: dtoToApp(data.app),
				claims: (Array.isArray(data.requestedKeyClaims)) ? data.requestedKeyClaims.map(String) : [],
				name: (typeof data.requestedKeyName === 'string') ? data.requestedKeyName : null,
				expiresAt: parseDate(data.requestedKeyExpiresAt),
				hasIpRestriction: (data.requestedKeyIpRestriction === true)
			};
		});
}

function acceptShortcode(shortcode) {
	if (typeof shortcode !== 'string' || shortcode === '') {
		return Promise.reject(new Error('Invalid Shortcode'));
	}
	return authReq.post('DRIVE_API://v1/shortcode/' + encodeURIComponent(shortcode) + '/activate')
		.then((response) => {
			// 204: done
			return null;
		});
}

function rejectShortcode(shortcode) {
	if (typeof shortcode !== 'string' || shortcode === '') {
		return Promise.reject(new Error('Invalid Shortcode'));
	}
	return authReq.delete('DRIVE_API://v1/shortcode/' + encodeURIComponent(shortcode) + '/activate')
		.then((response) => {
			// 204: done
			return null;
		});
}

function createWebhook(webhook) {
	return authReq.post('DRIVE_API://v1/webhook', webhook)
		.then((resp) => {
			return resp;
		}, (reason) => {
			if (Array.isArray(reason.response.data.errors)) {
				const errorArray = reason.response.data.errors.map((err) => {
					return {
						status: reason.response.status,
						error: reason.response.data.errorCode,
						dataPath: err.dataPath
					};
				});
				return Promise.reject(errorArray);
			}
			return Promise.reject ({
				status: reason.response.status,
				error: reason.response.data.errorCode,
				message: reason.response.data.message
			});
		});
}

function listWebhooks() {
	return authReq.get('DRIVE_API://v1/webhook')
		.then((resp)=> {
			return Array.isArray(resp.data.webhooks) ? resp.data.webhooks.map(dtoToWebhook).filter(Boolean) : [];
		});
}

function updateWebhook(webhookId, webhook) {
	return authReq.put(`DRIVE_API://v1/webhook/${webhookId}`, webhook)
		.catch((reason) => {
			if (Array.isArray(reason.response.data.errors)) {
				const errorArray = reason.response.data.errors.map((err) => {
					return {
						status: reason.response.status,
						error: reason.response.data.errorCode,
						dataPath: err.dataPath
					};
				});

				return Promise.reject(errorArray);
			}
			return Promise.reject({
				status: reason.response.status,
				error: reason.response.data.errorCode,
				message: reason.response.data.message
			});
		});
}

function deleteWebhook(webhookId) {
	return authReq.delete('DRIVE_API://v1/webhook/' + webhookId);
};


export default {
	search,
	tree,
	treePath,
	silos,

	//listSilos,

	listTrash,
	emptyTrash,

	createFileMeta,
	//updateFileBlob,
	restoreFile,
	getFileMeta,
	getFileArtifacts,
	getFileArtifactDownloadInfo,
	getFileDownloadInfo,
	getFileMembers,
	setFileMembers,
	//addFileMember,
	//updateFileMember,
	deleteFileMember,
	updateFileMeta,
	deleteFileMeta,

	createFolder,
	restoreFolder,
	getFolder,
	getFolderMembers,
	setFolderMembers,
	//addFolderMember,
	//updateFolderMember,
	deleteFolderMember,
	updateFolder,
	deleteFolder,

	getUserInfo,
	getUserStats,
	// getUserClaims,

	getAccessKeys,
	createAccessKey,
	deleteAccessKey,

	getShortcodeContext,
	acceptShortcode,
	rejectShortcode,

	createWebhook,
	listWebhooks,
	updateWebhook,
	deleteWebhook


};
