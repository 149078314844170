<style scoped>
	article {
		width: 100%;
		margin-bottom: 1em;
	}
	article > section {
		padding: 0 30px;
		max-width: 100%;
		box-sizing: border-box;
	}
	article > header {
		width: 100%;
		overflow: hidden;
		position: relative;
		align-items: center;
		margin: 0;
		max-height: calc(100vh - 416px);
		min-height: 200px;
		height: calc((100vw - 250px) / 1.78);
	}
	header > .hero-img {
		height: 100%;
		max-width: 100%;
		z-index: 0;
	}
	header > .hero-img-bg {
		height: 100%;
		width: 100%;
		background-color: #2e2e2e;
		position: absolute;
		z-index: 0;
	}
	header > .hero-img-bg.loading,
	header > .hero-img-bg.loading img {
		background-color: #eee;
		background-color: var(--hero-img-bg-loading-background-color);
	}
	header > .hero-img.loading {
		background-color: #eee;
	}
	header > .hero-img-bg img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		filter: blur(10px) opacity(0.5);
	}

	header .download-button {
		display: none;
	}
	header:hover .download-button {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		padding: 1em;
		background: transparent;
		cursor: pointer;
		color: white;
		border: none;
	}
	header:hover .download-button i {
		box-shadow: rgba(0, 0, 0, 0.9) 0 0 10px;
		border-radius: .5em;
		opacity: .8;
		color: white;
	}
	header:hover .download-button:hover i {
		opacity: 1;
	}
	header > .hero-img-trigger {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		background: transparent;
		border-color: transparent;
		color: transparent;
		cursor: pointer;
	}
	h2 small {
		color: #777;
	}
	.badge {
		display: inline-block;
		padding: .25em .5em;
		color: white;
		font-weight: 700;

		line-height: 1;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: .25rem;
		background-color: #999;
	}
	h2 .badge {
		font-size: 70%;
	}
	.badge-muted {
		color: #555;
		background-color: #f2f2f2;
		color: var(--badge-muted-color);
		background-color: var(--badge-muted-background-color);
		font-weight: 500;
	}

	.img-window {
		position: fixed;
		background: rgba(0,0,0, 0.89);
		top:0;
		left: 0;
		bottom: 0;
		right: 0;
		padding: 0;
		height: auto;
		color: white;
		padding: 1em;
	}
	.img-window h2 {
		margin: 0;
	}
	.img-window .link {
		font-size: 1.5em;
		line-height: 1em;
		color: white;
	}
	.img-window .img-wrapper {
		position: relative;
	}
	.img-window img.preview {
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		max-width: 100%;
		max-height: 100%;
		box-shadow: rgba(0, 0, 0, 0.9) 0px 5px 15px;
	}
	.img-window h2 {
		margin: 0;
	}
	.img-window .toolbar-button {
		color: white;
	}
	.img-window header {
		margin: 0 0 1em 0;
	}
	.img-window .toolbar-button:hover {
		background: rgba(255, 255, 255, 0.25);
	}

	.right {
		align-items: flex-end;
		margin-left: auto;
		margin-right: 0;
	}
	.icon-download-file {
		vertical-align: -7px;
		user-select: none;
	}
	.details {
		color: #777;
	}
	header > h2 {
		margin: 0;
	}
	header {
		margin: 1em 0;
	}
	ul {
		padding-left: 0;
		margin: 0;
	}
	.jumbo {
		font-size: 1.5em;
		line-height: 1em;
	}

	.card {
		border-radius: .5em;
		width: 200px;
		box-sizing: border-box;
		padding: 0;
		margin-right: 1em;
		margin-bottom: 1em;
	}
	.card .icon.action {
		color: #BBB;
		color: var(--card-icon-color);
	}
	.card .content {
		padding: 2em;
	}
	.card h1 {
		margin-bottom: 0;
	}
	.card .toolbar-button:hover {
		background: #dedede;
	}
	.card .content img.icon {
		width: 3em;
		height: 3em;
		color: blue;
		user-select: none;
	}
	.card button {
		background: white;
		background: var(--card-button-background-color);
		border-radius: .5em;
		border: 1px solid #ddd;
		border: 1px solid var(--card-button-border-color);
		cursor: pointer;
	}
	.card button .action {
		display: none;
	}
	.card button:hover .icon.action {
		display: flex;
	}
	.card button:hover {
		border-color: #bbb;
		border-color: var(--card-button-hover-border-color);
	}
	.card header + h1 {
		margin-top: 2em;
	}
	.card header {
		margin: 0;
	}
	.card footer {
		padding: 1em 1.5em;
		background: #f2f2f2;
		border-top: 1px solid #ddd;
	}

	.empty {
		color: #777;
	}

	.loading-message {
		padding: 3em 0;
		text-align: center;
		font-size: 2em;
		color: #ccc;
		background: #eee;
		color: var(--loading-message-color);
		background: var(--loading-message-background-color);
		box-sizing: border-box;
		/* max-height: calc(100vh - 200px); */
	}
	.loading-message span {
		margin: 3em 0;
	}

	@media only screen and (max-width : 490px) {
		.card {
			width: 100%;
			margin-right: 0;
		}
	}

</style>

<template>
	<div class="row flexible">
		<article v-if="artifacts" class="column separated fill">
			<header class="column" v-if="previewPhotoUrl">
				<div class="hero-img-bg">
					<img v-if="previewPhotoUrl" :src="previewPhotoUrl" alt="Preview Image background">
				</div>
				<img v-if="previewPhotoUrl" class="hero-img flexible" :src="previewPhotoUrl" alt="Preview Image">
				<button title="View Preview Image" class="flexible hero-img-trigger" @click="previewPhoto = true">View Preview</button>
				<button title="Download Preview Image" class="download-button" @click="downloadFile(artifacts.screenCapture)">
					<i data-v-65853606="" class="jumbo icon action rigid fas fa-arrow-alt-circle-down"></i>
				</button>
			</header>
			<header v-else class="loading-message rigid">
				<span>Loading&hellip;</span>
			</header>

			<div tabindex="0" v-autofocus v-if="previewPhoto" @keydown="onKeydown" @click="previewPhoto = false" class="img-window column">
				<header class="row centering separated">
					<button title="Close" @click.self="previewPhoto = false" class="rigid toolbar-button"><i class="fas fa-arrow-left"></i></button>
					<h2 class="flexible">{{artifacts.screenCapture.name}}</h2>
					<div class="rigid row separated">
						<button class="toolbar-button" title="Download Preview Image" type="button" @click.prevent.stop="downloadFile(artifacts.screenCapture)"><img src="@/assets/icon-download-file-white.svg" class="icon icon-download-file"/>Download</button>
					</div>
				</header>
				<div class="flexible column centering img-wrapper">
					<img class="rigid preview" @click.prevent.stop :src="previewPhotoUrl" alt="Preview Image">
				</div>
			</div>
			<section class="column">
				<header class="row centering">
					<h2 class="flexible">Channels <small class="badge badge-muted">.wfm</small></h2>
					<button v-if="artifacts.settingsZip" title="Download Session Settings (.set)" class="rigid toolbar-button" @click="downloadFile(artifacts.settingsZip)" type="button"><img src="@/assets/icon-download-file.svg" class="icon icon-download-file"/> Session Settings</button>
				</header>
				<ul class="row flowing" id="artifact-channels" v-if="artifacts.channels.length">
					<li class="card column" v-for="(channel, index) in artifacts.channels" :key="index">
						<button class="content" :title="`Download ${channel.displayName}`" type="button" @click="downloadFile(channel)">
							<header class="row flexible">
								<div class="column flexible">
									<img src="@/assets/waveform-blue.svg" alt="Waveform Icon" class="rigid icon"/>
								</div>
								<div class="column rigid">
									<i class="jumbo icon action rigid fas fa-arrow-alt-circle-down"></i>
								</div>
							</header>
							<h1 class="row flexible">{{channel.displayName}}</h1>
						</button>
					</li>
				</ul>
				<div v-else class="empty">
					No channel information
				</div>
			</section>
		</article>
		<article v-else>
			<header class="loading-message rigid">
				<span v-if="getArtifactsError">TSS preview not available.</span>
				<span v-else>Loading&hellip;</span>
			</header>
		</article>
	</div>
</template>

<script>

import driveService from '@/modules/drive-service';

export default {
	name: 'TssViewer',
	props: {
		fileName: String,
		fileId: String
	},
	data() {
		return {
			tssData: null,
			artifacts: null,
			previewPhotoUrl: null,
			previewPhoto: false,
			getArtifactsError: null
		};
	},
	methods: {
		downloadFile(item) {
			if (!item || item.type !== 'ARTIFACT' || !item.fileId || !item.id) {
				return;
			}
			driveService.downloadFileArtifact(item);
		},
		onKeydown(e) {
			if (e.keyCode === 27) {
				this.previewPhoto = false;
			}
		}
	},
	mounted() {
		const vm = this;
		driveService.getFileArtifacts(this.fileId)
			.then(artifacts => {
				vm.artifacts = artifacts;

				driveService.getFileArtifactDownloadUrl(artifacts.screenCapture)
					.then(url => {
						vm.previewPhotoUrl = url;
					});
			})
			.catch(reason => {
				vm.getArtifactsError = true;
			});
	}
};
</script>
