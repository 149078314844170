import axios from 'axios';
import { getAccessToken } from '@/modules/token-refresher';

import ENV_VARS from '@/modules/env-vars';

const ACCESS_TOKEN_KEY = 'X-IS-AT';

var refreshToken = '';

const request = axios.create();

function schemaInterceptor(config) {
	var url = config && config.url;
	if (/^\w+:/.test(url)) {
		config.url = url.replace(/^(\w+):\/\//, function (match, schema) {
			schema = schema.toUpperCase();

			if (ENV_VARS.hasOwnProperty(schema) && /^https?:\/\//.test(ENV_VARS[schema])) {
				match = ENV_VARS[schema].replace(/\/$/, '') + '/';
			}

			return match;
		});
	}
	return config;
}
request.interceptors.request.use(schemaInterceptor);

function requestInterceptor(config) {
	if (!refreshToken) {
		return config;
	}
	if (!config) {
		config = {};
	}

	// TODO: add config flag to disable auth token

	return getAccessToken(refreshToken)
		.then((accessToken) => {
			if (config.headers == null) {
				config.headers = {};
			}
			if (!config.headers.hasOwnProperty(ACCESS_TOKEN_KEY)) {
				// Set access token
				config.headers[ACCESS_TOKEN_KEY] = accessToken;
			}
			return config;
		});
}

// TODO: consider intercepting 401 responses to redirect to sign in
request.interceptors.request.use(requestInterceptor);

function setRefreshToken(token) {
	if (typeof token === 'string') {
		refreshToken = token;
	}
}

export { setRefreshToken, request as default };
