import { messages, init as initSocket } from '@/modules/socket-connect';
import actionHelper from '@/modules/action-helper';
import topicHelper from '@/modules/topic-helper';

messages.addListener('message', onMessage);

let connected = false;
let unfinishedJobs = [];

function onMessage(evt) {
	var data = null;
	try {
		data = JSON.parse(evt.data);
	} catch (err) {
		console.error(err);
		return;
	}
	const type = data && data.type;
	const message = data && data.data;
	const event = message && message.event;
	const eventType = event && event.type;
	const topicId = message.topicId;

	if (type === 'RESPONSE') {
		const responseError = actionHelper.tryHandleResponse(data);
		if (responseError) {
			console.error(responseError);
		}
	} else if (type === 'EVENT_MESSAGE') {
		const emitter = topicHelper.getCurrentTopic(topicId);
		emitter.emit(eventType, event);
	} else {
		// Unhandled message
	}
}

function delayRequest(topicFilter) {
	var delayedPromise = {};
	const promise = new Promise((resolve, reject) => {
		delayedPromise.resolve = resolve;
		delayedPromise.reject = reject;
	});
	const callback = () => createTopic(topicFilter);

	unfinishedJobs.push({delayedPromise, callback});
	return promise;
}

function finishJobs() {
	unfinishedJobs.forEach(job => {
		return job.callback()
			.then(topic => {
				job.delayedPromise.resolve(topic);
			});
	});
}

function createTopic(topicFilter) {

	if (!connected) {
		console.log('not connected');
		// if socket's not connected delay the request
		return delayRequest(topicFilter);
	}

	return topicHelper.createTopic(topicFilter);
}

export default {
	init: function (refreshToken, url, topicFilter) {
		return new Promise ((resolve, reject) => {
			initSocket(refreshToken, url)
				.then(() => {
					connected = true;

					// finish any jobs that were created before socket was connected
					if (unfinishedJobs && unfinishedJobs.length > 0) {
						finishJobs();
					}

					return createTopic(topicFilter);
				})
				.then((topic) => {
					return resolve(topic);
				})
				.catch(reason => {
					return reject(reason);
				});
		});
	},
	createTopic
};
