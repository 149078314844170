import { defineStore } from 'pinia';
import driveService from '@/modules/drive-service';
import { promiser } from '@/modules/promise-util';
import { useRootStore } from './rootStore';
import { ref, watch, computed } from 'vue';

var groupIndex = 0;

function nextGroupId() {
	return ++groupIndex;
};

export const useUploadStore = defineStore('uploadStore', () => {

	const uploadList = ref([]);
	const isUploading = ref(false);
	const uploadError = ref(null);


	const failedUploads = computed(() => {
		return uploadList.value.filter(task => task.uploadError);
	});

	const failedUploadBanner = computed(() => {
		return failedUploads.value.length > 0;
	});

	const newFilePercent = computed(() => {
		uploadList.value.filter(file => file.uploadedBytes);
		const data = uploadList.value.reduce((info, task) => {
			if (!task.archived && !task.error) {
				info[0] += task.meta && task.meta.uploadedBytes || 0;
				info[1] += task.bytes;
			}
			return info;
		}, [0, 0]);
		return (data[1] > 0) ? Math.max(0, Math.min(1, data[0] / data[1])) : 0;
	});

	watch(uploadList, () => {
		//TODO: STOP WATCHER
		isUploading.value = uploadList.value.some(task => task.active);
	}, {
		//May cause performance issues in the future. Keep an eye on this for better solution.
		deep: true
	});

	function SET_FILE_SIZE({ file, size }) {
		if (file && isFinite(size) && size >= 0) {
			file.bytes = size;
		}
	}

	function SET_UPLOAD_PROGRESS({ file, uploadedBytes }) {
		// const item = uploadList.value.findIndex(item => item.meta.id === file.id);
		if (file && uploadedBytes >= 0) {
			file.uploadState = 'PENDING';
			file.meta.uploadState = 'PENDING';
			file.uploadedBytes = uploadedBytes;
			file.meta.uploadedBytes = uploadedBytes;
			file.uploadError = null;
		}
	}

	function TOUCH_TASK(task) {
		if (task) {
			task.lastTick = performance.now();
		}
	}

	function SET_UPLOAD_COMPLETE(file) {
		if (file) {
			const item = uploadList.value.findIndex(item => item.meta.id === file.id);
			uploadList.value[item].uploadedBytes = file.bytes;
			uploadList.value[item].meta.uploadedBytes = file.bytes;
			uploadList.value[item].uploadState = 'SUCCESS';
			uploadList.value[item].meta.uploadState = 'SUCCESS';
		}
	}

	function SET_UPLOAD_TASK_COMPLETE(task) {
		if (task) {
			task.lastTick = performance.now();
			task.active = false;
		}
		// scan upload task list. If there are no active tasks, archive all (except errors?)
		if (uploadList.value.every((item) => !item.active)) {
			uploadList.value.forEach((item) => {
				if (!item.error && !item.archived) {
					item.archived = true;
				}
			});
		}
	}

	function SET_UPLOAD_FAIL({ file, error }) {
		if (file) {
			file.uploadedBytes = 0;
			file.uploadState = 'ERROR';
			file.uploadError = error;
		}
	}

	function SET_UPLOAD_TASK_FAIL({ task, error }) {
		if (task) {
			task.lastTick = performance.now();
			task.error = error || null;
			task.active = false;
		}
	}

	function SET_UPLOAD_LIMIT_CONTEXT(context) {
		const rootStore = useRootStore();
		if (context == null || !Array.isArray(context.items) || context.resolvable == null) {
			rootStore.uploadLimitContext = null;
		} else {
			rootStore.uploadLimitContext = {
				items: context.items,
				resolvable: context.resolvable
			};
		}
	}

	function SET_UPLOAD_REFUSED_NOTICE(context) {
		const rootStore = useRootStore();
		if (context == null || context.resolvable == null) {
			rootStore.uploadRefusedContext = null;
		} else {
			rootStore.uploadRefusedContext = {
				items: Array.isArray(context.items) ? context.items : [],
				resolvable: context.resolvable
			};
		}
	}

	function UPLOAD_FILE(fileConfig) {
		const rootStore = useRootStore();
		if (!fileConfig || !(fileConfig.file instanceof Blob)) {
			return;
		}
		const name = fileConfig.name || fileConfig.file.name || '';
		if (!name) {
			return Promise.reject(new Error('Invalid Name'));
		}
		const parentFolderId = fileConfig.parentFolderId;
		const file = fileConfig.file;

		// TODO: add retry option on fail
		const uploadTask = {
			name: name,
			file: file,
			bytes: file && file.size || 0,
			type: 'UPLOAD',
			groupId: fileConfig.group || nextGroupId(),
			active: true,
			archived: false,
			meta: null, // this isn't available yet
			error: null,
			startDate: Date.now(),
			startTick: performance.now(),
			lastTick: 0 / 0
		};
		return driveService.uploadFile({
			parentFolderId: parentFolderId,
			name: name
		})
			.then((result) => {
				// meta is a File model (EventEmitter)
				const meta = result.meta;
				SET_FILE_SIZE({ file: meta, size: file.size });


				if (uploadTask) {
					uploadTask.meta = meta || null;
				}

				SET_UPLOAD_PROGRESS({ file: uploadTask, uploadedBytes: 0 });


				const uploadListLength = uploadList.value.push(uploadTask);

				// upload file and update model when updated
				result.upload(file, (progressEvent) => {
					const progress = progressEvent.loaded || progressEvent.progress || 0;
					SET_UPLOAD_PROGRESS({ file: uploadList.value[uploadListLength - 1], uploadedBytes: progress });
					TOUCH_TASK(uploadTask);
				})
					.then(() => {
						SET_UPLOAD_COMPLETE(meta);
						driveService.getFileDownloadUrlOnUploadComplete(meta)
							.then(url => {
								meta.downloadUrl = url;
								rootStore.ADD_FILE(meta);
								SET_UPLOAD_TASK_COMPLETE(uploadTask);
							});

					})
					.catch((reason) => {
						SET_UPLOAD_FAIL({ file: uploadList.value[uploadListLength - 1], error: reason });
						// Keep file ref incase we need to try again
						SET_UPLOAD_TASK_FAIL({ task: uploadList.value[uploadListLength - 1], error: reason });
					});

				rootStore.ADD_FILE(meta);
				rootStore.SCAN_UPDATE_USER_STATS;

				return meta;

			}, (reason) => {
				SET_UPLOAD_TASK_FAIL({ task: uploadTask, error: reason });
				return Promise.reject(reason);
			});
	}

	function UPLOAD_FILES(fileConfigs) {
		const rootStore = useRootStore();
		if (!Array.isArray(fileConfigs)) {
			return Promise.reject(new Error('Invalid Directory Items'));
		}
		const userStats = rootStore.userStats || {
			totalBytesOwned: 0,
			storageSizeLimit: Infinity
		};
		// TODO: evaluate parentFolderId to ensure that this is a personal folder

		if (userStats.storageSizeLimit > 0 && userStats.totalBytesOwned >= userStats.storageSizeLimit) {
			// At/over limit, show warning before uploading
			// provide a resolvable in the context so the popover can close itself when complete
			const resolvable = promiser();
			SET_UPLOAD_LIMIT_CONTEXT({ items: fileConfigs, resolvable });

			// return a promise for the completion of the popover
			return resolvable.promise
				.then((value) => {
					// clear the context
					SET_UPLOAD_LIMIT_CONTEXT(null);
					return value;
				}, (reason) => {
					console.error('failed to upload', reason);
					// clear the context
					SET_UPLOAD_LIMIT_CONTEXT(null);
					return Promise.reject(reason);
				});
		}

		// Under limit, go ahead and upload
		return FORCE_UPLOAD_FILES(fileConfigs)
			.catch((reason) => {
				if (reason && reason.response && reason.response.status === 403) {
					// Refusal reasons: lack of claim, no personal space (0 sys limit), exceeded system limit, does not have edit permission to folder, folder not found
					const resolvable = promiser();

					SET_UPLOAD_REFUSED_NOTICE({ items: fileConfigs, resolvable: resolvable });
					return resolvable.promise
						.then((value) => {
							SET_UPLOAD_REFUSED_NOTICE(null);
							return value;
						})
						.catch((reason) => {
							SET_UPLOAD_REFUSED_NOTICE(null);
							return Promise.reject(reason);
						});
				}
				return Promise.reject(reason);
			});
	}

	function FORCE_UPLOAD_FILES(fileConfigs) {
		if (!Array.isArray(fileConfigs)) {
			return Promise.reject(new Error('Invalid Directory Items'));
		}
		const eventGroup = nextGroupId();
		const jobs = fileConfigs.map((config) => {
			const altConfig = Object.create(config);
			altConfig.group = eventGroup;
			return UPLOAD_FILE(config);
		});
		return Promise.all(jobs)
			.catch((reason) => {
				throw reason;
			});
	}

	function CLEAR_FAILED_UPLOADS() {
		uploadList.value = uploadList.value.filter(task => !task.uploadError);
	}

	function DELETE_FAILED_UPLOADS() {
		const rootStore = useRootStore();
		rootStore.DELETE_ITEMS(failedUploads.value);
		CLEAR_FAILED_UPLOADS();
	}

	return {
		failedUploadBanner,
		newFilePercent,
		UPLOAD_FILES,
		uploadList,
		isUploading,
		uploadError,
		failedUploads,
		DELETE_FAILED_UPLOADS,
		CLEAR_FAILED_UPLOADS,
		FORCE_UPLOAD_FILES,

		//exported for testing
		SET_FILE_SIZE,
		SET_UPLOAD_PROGRESS,
		TOUCH_TASK,
		SET_UPLOAD_COMPLETE,
		SET_UPLOAD_FAIL,
		SET_UPLOAD_LIMIT_CONTEXT,
		SET_UPLOAD_REFUSED_NOTICE,

	};
});
