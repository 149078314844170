<style scoped>
.content-view {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 12px 30px;
}

.content-view.no-padding {
	padding: 0;
}

.preview-image-container {
	max-width: 100vw;
	max-height: 600px;
}

.content-view.no-padding .preview-image-container {
    max-height: 100%;
}

.preview-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
    object-position: left;
    filter: var(--image-brightness);
}
</style>

<template>
	<div class="row content-view-wrapper">
		<div class="content-view" :class="{'no-padding':file.fileType === 'PDF' || file.fileType === 'CSV' || file.fileType === 'TSS'}">
			<div v-if="previewUrl" class="preview-image-container flexible row" >
				<PdfViewer v-if="file.fileType === 'PDF'" :fileName="previewUrl"/>
				<CsvViewer v-else-if="file.fileType === 'CSV'" :fileName="previewUrl"/>
				<TssViewer v-else-if="file.fileType === 'TSS'" :fileId="file.id"/>
				<img v-else class="preview-image" :src="previewUrl" :alt="(file.name)">
			</div>
		</div>
	</div>
</template>

<script>
import PdfViewer from '@/components/PdfViewer.vue';
import CsvViewer from '@/components/CsvViewer.vue';
import TssViewer from '@/components/TssViewer.vue';

export default {
	props: {
		file: Object,
		previewUrl: String
	},
	components:{
		PdfViewer,
		CsvViewer,
		TssViewer
	},
	data () {
		return {

		};
	},
};
</script>
