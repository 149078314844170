<style scoped>
p {
  max-width: 65ch;
  text-align: center;
  margin: 1em;
}

.intro-step {
  height: 100vh;
  justify-content: center;
}

.img-container {
  max-width: 600px;
  height: 300px;
  display: flex;
  align-items: flex-end;
  margin: 1em;
}

.img-container img {
    max-width: 100%;
    flex: 0 1 auto;
}

.intro-button {
  background: #2e88d6;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  height: 50px;
  width: 215px;
  border: none;
  margin: 1em;
}

.progress-container {
  max-height: 150px;
  justify-content: flex-start;
}

.text-container {
  height: 200px;
  justify-content: center;
}

.text-container h1 {
  margin: 0.64em;
}

.progress-bar {
  margin: 1em;
}

.skip-intro {
  position: absolute;
  bottom: 1em;
  right: 1em;
  padding-right: 1.5em;
}

.skip-intro a {
  color: #b0b0b0;
}

.breadcrumbs {
	flex: 1 1 auto;
	text-overflow: ellipsis;
	padding: 2px 12px;
	font-size: 0.9em;
	align-self: flex-end;
	width: 0;
}

.user-menu {
	flex: 0 0 auto;
	color: #777;
	color: var(--menu-color);
}

.location-bar {
	display: flex;
	flex-direction: row;
	align-content: flex-end;
	padding: 8px 20px 0;
}

@media only screen and (max-width: 500px) {
    .skip-intro {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        bottom: 0;
        right: 0;
    }
}
</style>

<template>
	<div class="drive-intro-view">
		<header class="content-header">
			<StorageNoticeBar />
			<div class="location-bar">
				<div class="breadcrumbs"></div>
				<div class="user-menu">
					<UserMenu />
				</div>
			</div>
		</header>

		<section
			class="intro-step column centering"
			v-if="introStep === 1 && hasPersonalStorage"
		>
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-hero.svg"
					alt="TekDrive: Cloud Storage for Test and Measurement data."
				/>
			</div>
			<div class="text-container column centering">
				<h1>Welcome to TekDrive!</h1>
				<p>
					With TekDrive you can save and recall data directly on
					instrumentation, upload, download, manage and organize files, analyze
					waveforms in your web browser in an interactive viewer, and share data
					with your colleagues for easier collaboration.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-0.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Learn About TekDrive
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>

		<section
			class="intro-step column centering"
			v-if="introStep === 2 && hasPersonalStorage"
		>
			<div class="img-container">
				<img src="/images/tekdrive-intro-upload.svg" alt="Folder" />
			</div>
			<div class="text-container column centering">
				<h1>Uploading Files is Easy!</h1>
				<p>
					Add files directly from your oscilloscope with the same ease of local
					data storage. You can also drag and drop files from any computer or
					click the add files button.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-1.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Next: Share Files
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>

		<section
			class="intro-step column centering"
			v-if="introStep === 3 && hasPersonalStorage"
		>
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-share.svg"
					alt="Share data from oscilloscope to your computer"
				/>
			</div>
			<div class="text-container column centering">
				<h1>Share files securely for better collaboration</h1>
				<p>
					Collaborate with your co-workers without worry using industry-leading
					infrastructure that prioritizes security, integrity, and availability
					of our systems and your data.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-2.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Next: Analyze Files
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>

		<section class="intro-step column centering" v-if="introStep === 4 && hasPersonalStorage">
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-analyze.svg"
					alt="Share data from oscilloscope to your computer"
				/>
			</div>
			<div class="text-container column centering">
				<h1>Analyze your data in your web browser</h1>
				<p>
					Not near your scope, but want to show off something you found in your
					last test? No problem. Just open up Waves or Lines and dive deep into
					your data on any device with a browser - including tablets and smart
					phones.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-3.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<router-link :to="{ path: 'recent', query: { intro: 'complete' } }"
				><button type="button" class="intro-button">
					Start Using TekDrive
				</button></router-link
				>
			</div>
		</section>


		<!-- No personal storage -->

		<section
			class="intro-step column centering"
			v-else-if="introStep === 1 && !hasPersonalStorage"
		>
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-hero.svg"
					alt="TekDrive: Cloud Storage for Test and Measurement data."
				/>
			</div>
			<div class="text-container column centering">
				<h1>Welcome to TekDrive!</h1>
				<p>
					With TekDrive you can save and recall data directly on
					instrumentation, upload, download, manage and organize files, analyze
					waveforms in your web browser in an interactive viewer, and share data
					with your colleagues for easier collaboration.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-0.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Learn About TekDrive
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>


		<section
			class="intro-step column centering"
			v-else-if="introStep === 2 && !hasPersonalStorage"
		>
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-share.svg"
					alt="Share data from oscilloscope to your computer"
				/>
			</div>
			<div class="text-container column centering">
				<h1>Share files securely for better collaboration</h1>
				<p>
					Collaborate with your co-workers without worry using industry-leading
					infrastructure that prioritizes security, integrity, and availability
					of our systems and your data.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-1.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Next: Upload Files
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>


		<section
			class="intro-step column centering"
			v-if="introStep === 3 && !hasPersonalStorage"
		>
			<div class="img-container">
				<img src="/images/tekdrive-intro-upload.svg" alt="Folder" />
			</div>
			<div class="text-container column centering">
				<h1>Uploading Files is Easy!</h1>
				<p>
					Add files directly from your oscilloscope to folders shared with you by
					your organization. You can also drag and drop files from any computer or
					click the add files button.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-2.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<button type="button" class="intro-button" @click="nextStep()">
					Next: Share Files
				</button>
			</div>
			<div class="skip-intro">
				<router-link :to="{ path: 'recent', query: { intro: 'skipped' } }"
				><a>Skip Introduction</a></router-link
				>
			</div>
		</section>

		<section class="intro-step column centering" v-if="introStep === 4 && !hasPersonalStorage">
			<div class="img-container">
				<img
					src="/images/tekdrive-intro-analyze.svg"
					alt="Share data from oscilloscope to your computer"
				/>
			</div>
			<div class="text-container column centering">
				<h1>Analyze your data in your web browser</h1>
				<p>
					Not near your scope, but want to show off something you found in your
					last test? No problem. Just open up Waves or Lines and dive deep into
					your data on any device with a browser - including tablets and smart
					phones.
				</p>
			</div>
			<div class="progress-container column centering">
				<img
					src="/images/progress-bar-3.svg"
					alt="progress bar"
					class="progress-bar"
				/>
				<router-link :to="{ path: 'recent', query: { intro: 'contributor' } }"
				><button type="button" class="intro-button">
					Start Using TekDrive
				</button></router-link
				>
			</div>
		</section>



	</div>
</template>



<script>
import { mapState } from 'pinia';
import { useRootStore } from '../store/rootStore';
import StorageNoticeBar from '@/components/StorageNoticeBar.vue';
import UserMenu from '@/components/UserMenu.vue';

export default {
	name: "IntroView",
	components: {
		StorageNoticeBar,
		UserMenu
	},

	data: () => {
		return {
			introStep: 1,
		};
	},
	computed: {
		...mapState(useRootStore, {
			hasPersonalStorage(store) {
				return store.userStats != null && store.userStats.storageSizeLimit > 0;
			},
		}),
	},

	methods: {
		nextStep() {
			this.introStep += 1;
		},
	},
};
</script>
