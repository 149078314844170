<style scoped>
.modal-mask {
	position: fixed;
	z-index: 8000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-container {
	width: 400px;
	margin: 0px auto;
	padding: 30px;
	background-color: #fff;
	background-color: var(--modal-container-background-color);
	border-radius: 2px;
	transition: all 0.3s ease;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	max-height: 100%;
	overflow: auto;
	flex: 0 0 auto;
}

h3 {
	margin-top: 0;
	text-transform: capitalize;
}

.modal-footer {
	margin-top: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	transform: scale(1.1);
}
.action-delete {
	background-color: #c00;
	border-color: #c00;
	color: #fff;
	cursor: pointer;
}
.action-delete:focus {
	border-color: #700;
}

</style>

<template>
	<transition name="modal">
		<div class="modal-mask" ref="modalMask">
			<form class="modal-container" @submit.prevent="emptyTrash()">
				<div>
					<h3>Empty Trash</h3>
					<p>Are you sure you want to permanently delete all items in your trash bin?</p>
				</div>
				<footer class="modal-footer">
					<button type="button" class="button action-cancel" @click="cancel()" :disabled="updating">Cancel</button>
					<button type="submit" class="button action-delete" :disabled="updating">Delete</button>
				</footer>
			</form>
		</div>
	</transition>
</template>

<script>
import { mapActions } from 'pinia';
import { useRootStore } from '@/store/rootStore';
import { createModalMask, removeModalMask } from '@/modules/modalMask';

export default {
	name: 'EmptyTrashConfirmModal',

	props: {
		context: {
			validator: function (value) {
				// TODO: provide better validation to ensure context
				return value != null
					&& value.resolvable != null
					&& typeof value.resolvable.resolve === 'function'
					&& typeof value.resolvable.reject === 'function';
			}
		}
	},

	data () {
		return {
			updating: false,
			items: this.context.items || [],

			resolvable: this.context.resolvable || null
		};
	},

	computed: {
		multipleItemsText() {
			const hasFolders = this.context.items.find(item => item.type === 'FOLDER'),
				hasFiles = this.context.items.find(item => item.type === 'FILE');

			if (hasFolders && !hasFiles) {
				return 'folders';
			} else if (hasFiles && !hasFolders) {
				return 'files';
			} else {
				return 'items';
			}
		},
		singleItemText() {
			const type = this.context.items[0].type;

			switch(type) {
			case 'FILE':
				return 'file';
			case 'FOLDER':
				return 'folder';
			default:
				return 'item';
			};
		}
	},

	methods: {
		...mapActions(useRootStore, {
			EMPTY_TRASH: 'EMPTY_TRASH'
		}),

		close () {
			this.resolvable.reject(this.items);
			this.$emit('close');
		},

		modelClose () {
			this.close();
		},

		cancel () {
			this.close();
		},

		emptyTrash () {
			this.updating = true;
			this.EMPTY_TRASH()
				.then((result) => {
					//this.updating = false;
					this.resolvable.resolve();
				})
				.catch((reason) => {
					this.updating = false;
				});
		},
		initModalMask(element, fn) {
			return createModalMask(element, fn);
		},

		destroyModalMask(element, fn) {
			return removeModalMask(element, fn);
		}

	},

	mounted () {
		this.initModalMask(this.$refs.modalMask, this.modelClose);
	},

	beforeUnmount() {
		this.destroyModalMask(this.$refs.modalMask, this.modelClose);
	}


};
</script>
