import  { createRouter, createWebHashHistory } from 'vue-router';
import AppLayoutView from '@/views/AppLayoutView.vue';
import AccountErrorView from '@/views/AccountErrorView.vue';
import HomeView from '@/views/HomeView.vue';
import FileView from '@/views/FileView.vue';
import FolderView from '@/views/FolderView.vue';
import PersonalStorageView from '@/views/PersonalStorageView.vue';
import SharesView from '@/views/SharesView.vue';
import TrashBinView from '@/views/TrashBinView.vue';
import SearchResultView from '@/views/SearchResultView.vue';
import AccessKeyListView from '@/views/AccessKeyListView.vue';
import AccessKeyCreateView from '@/views/AccessKeyCreateView.vue';
import IntroView from '@/views/IntroView.vue';
import PairCodeVerifyView from '@/views/PairCodeVerifyView.vue';
import UnknownRouteView from '@/views/UnknownRouteView.vue';
import WebhookCreateView from '@/views/WebhookCreateView.vue';
import WebhookEditView from '@/views/WebhookEditView.vue';
import ENV_VARS from '@/modules/env-vars';
import { useRootStore } from '../store/rootStore';
import { useAuthStore } from '../store/auth';
import AuthView from '../views/AuthView.vue';

const router = createRouter({
	history: createWebHashHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/signin',
			alias: '/login',
			name: 'signin',
			component: AuthView,
			beforeEnter (to) {
				const authStore = useAuthStore();
				var dest = (typeof to.query.to === 'string') ? to.query.to : '/';
				if (dest && dest.indexOf('?to=') >= 0) {
					// Note: this is workaround for an issue in auth's handling of client URL param
					dest = dest.slice(0, dest.indexOf('?to='));
				}
				// TODO: Display sign-in animation
				if (to.query.token) {
					// if a token is provided, re-route to home
					authStore.SET_REFRESH_TOKEN(to.query.token)
						.then(() => {
							// Authenticated, redirect to home
							if (to.query.r === 'newuser') {
								router.replace('/welcome');
							} else {
								router.replace(dest);
							}
						})
						.catch((reason) => {
							// failed to refresh token, log out
							router.replace('/signout' + (dest ? '?to=' + dest : ''));
						});

				} else {
					// otherwise route to login screen
					router.replace('/signout' + (dest ? '?to=' + dest : ''));
				}
			}
		},
		{
			path: '/signout',
			alias: '/logout',
			name: 'signout',
			component: AuthView,
			beforeEnter (to) {
				const authStore = useAuthStore();
				var qparams = '?';
				if (to.query.reason) {
					qparams += 'reason=' + encodeURIComponent(to.query.reason) + '&';
				}
				const dest = (to.query && typeof to.query.to === 'string') ? to.query.to : '';
				qparams += 'client=' + encodeURIComponent((window.location.origin || '') + '#/signin' + (dest ? '?to=' + dest : ''));
				const authUrl = (ENV_VARS.AUTH_URL || '#/404') + qparams;
				authStore.LOGOUT(authStore.refreshTokenJTI())
					.then(() => {
						// redirect to auth service
						window.location.href = authUrl;
					});
			}
		},
		{
			path: '/activate',
			name: 'Activate Access Key Shortcode',
			component: PairCodeVerifyView,
			props: (route) => ({
				code: route.query.code
			}),
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/account-error',
			name: 'Account Error',
			component: AccountErrorView,
			meta: {
				requiresAuth: false
			}
		},
		{
			path: '/',
			component: AppLayoutView,
			beforeEnter: (to, from, next) => {
				const store = useRootStore();
				Promise.all([
					store.UPDATE_USER_STATS(),
					store.GET_INITIAL_DIRECTORY()
				])
					.then(() => {
						next();
					})
					.catch((err) => {
						// TODO: Show catastrophic error message
						console.log('Error:', err);
						next('/account-error');
					});
			},
			children: [
				{
					path: '/welcome',
					name: 'Introduction',
					component: IntroView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/accesskeys/new',
					name: 'Generate Access Key',
					component: AccessKeyCreateView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/webhooks/new',
					name: 'Create a Webhook',
					component: WebhookCreateView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/webhooks/edit',
					name: 'Edit Webhook',
					component: WebhookEditView,
					props:(route) => ({
						id: route.query.id,
					}),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/settings/:section?',
					alias: ['/accesskeys', '/webhooks'],
					name: 'Access Key List',
					component: AccessKeyListView,
					props: (route) => ({
						section: route.params.section,
						q: route.query.q
					}),
					meta: {
						requiresAuth: true
					},
				},
				{
					path: '/search',
					name: 'Search',
					component: SearchResultView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/shares',
					name: 'Shared With Me',
					component: SharesView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/trash',
					name: 'Trash Bin',
					component: TrashBinView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/f/:fileId',
					name: 'File',
					component: FileView,
					props: true,
					meta: {
						// TODO: allow access to a public file
						requiresAuth: true
					},
					// beforeEnter: (to, from, next) => {
					// 	const store = useRootStore();
					// 	const fileId = to.params.fileId;
					// 	debugger;
					// 	store.GET_FILE(fileId)
					// 		.then(() => {
					// 			next();
					// 		})
					// 		.catch((err) => {
					// 			next((vm) => vm.setLoadError(err));
					// 		});
					// }
				},
				{
					path: '/d/:folderId',
					name: 'Folder',
					component: FolderView,
					props: true,
					meta: {
						// TODO: allow access to a public folder
						requiresAuth: true
					},
					beforeEnter: (to, from, next) => {
						const store = useRootStore();
						// NOTE: this component may not be created, either defer this.$store reference or use global reference (i.e., import)
						// TODO: ensure this doesn't circumvent route auth verification
						var folderId = to.params.folderId;
						if (typeof folderId !== 'string' || folderId === '') {
							// TODO: service should look up the personal root ID
							folderId = 'PERSONAL';
						}

						store.GET_FOLDER_NODE(folderId)
							.then((meta) => {
								next();
							})
							.catch((err) => {
								next((vm) => vm.setLoadError(err));
							});
					}
				},
				{
					path: '/personal',
					name: 'Personal Folder',
					component: PersonalStorageView,
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/recent',
					name: 'Recent Activity',
					component: HomeView,
					props:(route) => ({
						intro: route.query.intro,
					}),
					meta: {
						requiresAuth: true
					}
				},
				{
					path: '/',
					redirect: () => '/recent'
				}
			],
		},
		{
			path: '/:pathMatch(.*)*',
			alias: '/404',
			name: 'Path Not Found',
			component: UnknownRouteView,
			meta: {
				requiresAuth: false
			},
		}
	]
});

router.beforeEach((to, from, next) => {
	const authStore = useAuthStore();
	router.routing = true;
	document.documentElement.classList.add('navigating');
	if (!to.meta.requiresAuth || authStore.isAuthenticated) {
		// continune on your way
		next();
		return;
	} else {
		// No entry!
		const dest = to.fullPath || '/';
		next('/signout?reason=restricted&to=' + encodeURIComponent(dest));
	}
});

// finish animation after moving router-view
router.afterEach((to, from) => {
	router.routing = false;
	// stop the navigating animation
	document.documentElement.classList.remove('navigating');
});

export default router;
